import React, { Component } from 'react';
import * as d3 from 'd3';
import { config } from '../app_config';

export default class env_diagram extends Component {
    constructor(props) {
        super(props);
        this.envDiagramRef = React.createRef();
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate() {
        this.drawChart();
    }

    drawChart() {
        // set the dimensions and margins of the graph
        let margin = { top: 0, right: 0, bottom: 0, left: 0 },
            width = 350 - margin.left - margin.right,
            height = 350 - margin.top - margin.bottom,
            innerRadius = 40,
            outerRadius = Math.min(width, height) / 2; // the outerRadius goes from the middle of the SVG area to the border

        d3.select(this.envDiagramRef.current).select('*').remove();

        const data = [
            { action: 'top' },
            { action: 'bottom' },
            { action: 'left' },
            { action: 'right' },
            { action: 'none' },
        ];

        const svg = d3
            .select(this.envDiagramRef.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + (width / 2 + margin.left) + ',' + (height / 2 + margin.top) + ')');

        // Scales
        let x = d3
            .scaleBand()
            .range([0, 2 * Math.PI]) // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
            .align(0) // This does nothing
            .domain(
                data.map(function (d) {
                    return d.action;
                })
            ); // The domain of the X axis is the list of states.
        let y = d3
            .scaleRadial()
            .range([innerRadius, outerRadius]) // Domain will be define later.
            .domain([0, 1.5]); // Domain of Y is from 0 to the max seen in the data

        svg.append('circle')
            .attr('class', 'inner-circle')
            .attr('r', innerRadius - 10)
            .style('fill', '#69b3a2');

        // Add the bars
        let datagroup = svg
            .append('g')
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('id', function (d) {
                return 'bar-' + d.action;
            })
            .attr('transform', function (d) {
                return (
                    'rotate(' +
                    (((x(d.action) + x.bandwidth() / 2) * 180) / Math.PI - 90) +
                    ')' +
                    'translate(' +
                    y(0.95) +
                    ',0)'
                );
            })
            .call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended));

        let bars = svg
            .append('g')
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .append('path')
            .attr('fill', '#69b3a2')
            .attr('id', function (d) {
                return 'segment-' + d.action;
            })
            .call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended))
            .attr(
                'd',
                d3
                    .arc() // imagine your doing a part of a donut plot
                    .innerRadius(innerRadius)
                    .outerRadius(function (d) {
                        return y(0.9);
                    })
                    .startAngle(function (d) {
                        return x(d.action) + x.bandwidth() * 0.4;
                    })
                    .endAngle(function (d) {
                        return x(d.action) + x.bandwidth() * 0.6;
                    })
                    .padAngle(0.0)
                    .padRadius(innerRadius)
            );

        datagroup
            .append('path')
            .attr('fill', '#69b3a2')
            .attr('d', function (d) {
                return 'M 0 0 L 0 10 L 10 0 L 0 -10 L 0 0';
            });

        // Add the labels
        datagroup
            .attr('text-anchor', function (d) {
                return (x(d.action) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? 'end' : 'start';
            })
            .attr('transform', function (d) {
                return (
                    'rotate(' +
                    (((x(d.action) + x.bandwidth() / 2) * 180) / Math.PI - 90) +
                    ')' +
                    'translate(' +
                    (y(1) + 12) +
                    ',0)'
                );
            })
            .append('text')
            .attr('id', function (d) {
                return 'label-' + d.action;
            })
            .text(function (d) {
                return d.action;
            })
            .attr('transform', function (d) {
                return (x(d.action) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI
                    ? 'rotate(180)translate(12,0)'
                    : 'rotate(0)translate(12,0)';
            })
            .style('font-size', '11px')
            .attr('alignment-baseline', 'middle');

        function dragstarted() {
            d3.select(this).attr('stroke', 'black');
        }

        function dragged(event, d) {
            d.x = event.x;
            d.y = event.y;
            let raw_angle = Math.atan2(-event.y, event.x);
            let transformed_angle = raw_angle > 0 ? raw_angle : 2 * Math.PI + raw_angle;
            let true_angle = -transformed_angle + Math.PI / 2;
            d3.select('#bar-' + d.action)
                .raise()
                .attr('transform', function (d) {
                    return (
                        'rotate(-' +
                        ((0.5 * transformed_angle) / Math.PI) * 360 +
                        ')' +
                        'translate(' +
                        (y(1) + 12) +
                        ',0)'
                    );
                });
            d3.select('#label-' + d.action).attr('text-anchor', function (d) {
                return true_angle < Math.PI ? 'end' : 'start';
            });
            d3.select('#segment-' + d.action)
                .raise()
                .attr(
                    'd',
                    d3
                        .arc() // imagine your doing a part of a donut plot
                        .innerRadius(innerRadius)
                        .outerRadius(function (d) {
                            return y(0.9);
                        })
                        .startAngle(function (d) {
                            return true_angle - x.bandwidth() * 0.1;
                        })
                        .endAngle(function (d) {
                            return true_angle + x.bandwidth() * 0.1;
                        })
                        .padAngle(0.0)
                        .padRadius(innerRadius)
                );

            if (Math.abs(event.x) < innerRadius && Math.abs(event.y) < innerRadius) {
                d3.select('#bar-' + d.action).attr('display', 'none');
                d3.select('#label-' + d.action).attr('display', 'none');
                d3.select('#segment-' + d.action).attr('display', 'none');
            }
        }

        function dragended() {
            d3.select(this).attr('stroke', null);
        }
    }

    render() {
        return <div ref={this.envDiagramRef}></div>;
    }
}
