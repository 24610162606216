/* eslint-disable prettier/prettier */
import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
// import thumbs up and down icons from fa-svg
import { Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import '../css/Segment_View.module.css';

const SegmentChart = ({ Data, data_type }) => {
    // State (Current Node)
    const [currentNode, setCurrentNode] = useState(null);

    // Element References
    const svgRef = useRef(null);
    const tooltipRef = useRef(null);

    const tooltip = (ref) => {
        // Return Up and Down Thumb Icons + an optinal text input field (make sure the textbox is in the same row as the thumbs up and down icons). Make tooltip invisible on blick
        return (
            <div
                style={{
                    opacity: 0,
                    position: 'absolute',
                    backgroundColor: 'white',
                    border: 'border',
                    borderWidth: '2px',
                    borderRadius: '5px',
                    padding: '5px',
                }}
                ref={ref}
            >
                <div className="tooltip-body">
                    <Row>
                        <Col xs={2}>
                            <Button variant="success" /* onClick */ onClick={(event) => processFeedback(event)}>
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <Button variant="danger">
                                <FontAwesomeIcon icon={faThumbsDown} onClick={(event) => processFeedback(event)} />
                            </Button>
                        </Col>
                        <Col xs={8}>
                            <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Text Feedback"
                                onChange={(event) => processFeedback(event)}
                            >
                                <Form.Control as="textarea" />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    // Process Feedback
    const processFeedback = (feedback) => {
        console.log(feedback);
        console.log(`Feedback for node ${currentNode} is ${feedback}`);
    };

    useEffect(() => {
        // Dimensions
        const dimensions = {
            width: svgRef.current.parentElement.clientWidth - 50 * 2,
            height: svgRef.current.parentElement.clientHeight,
            verticalMargin: 50,
            horizontalMargin: 100,
        };

        // Clear the canvas
        d3.select(svgRef.current).selectAll('*').remove();

        // Array with category name (Episode 1,2, etc.), the x start and end position (between 0 and 100)
        const categories = [
            ['Episode 1', 30, 80],
            ['Episode 2', 10, 60],
            ['Episode 3', 20, 70],
            ['Episode 4', 40, 90],
            ['Episode 5', 50, 100],
            ['Episode 6', 0, 100],
        ];

        // Selections
        const svg = d3
            .select(svgRef.current)
            .classed('segment-chart-svg', true)
            .attr('width', dimensions.width + dimensions.horizontalMargin * 2)
            .attr('height', dimensions.height + dimensions.verticalMargin * 2);

        // Create a color scale using these means.
        const myColor = d3.scaleSequential().domain([0, 100]).interpolator(d3.interpolateViridis);

        // Add X axis
        const x = d3.scaleLinear().domain([0, 100]).range([0, dimensions.width]);
        svg.append('g')
            .attr('class', 'xAxis')
            .attr('transform', 'translate(0,' + dimensions.height + ')')
            .call(d3.axisBottom(x).tickValues([0, 25, 50, 75, 100]).tickSize(-dimensions.height))
            .select('.domain')
            .remove();

        // Add X axis label:
        svg.append('text')
            .attr('text-anchor', 'end')
            .attr('x', dimensions.width)
            .attr('y', dimensions.height + 40)
            .text('Steps');

        // Create the Y axis for names
        var yName = d3
            .scaleBand()
            .domain(categories.map((d) => d[0]))
            .range([0, dimensions.height]);
        svg.append('g')
            .attr('transform', 'translate(' + dimensions.width + ', 0)')
            .call(d3.axisRight(yName));

        // Add horizontal gridlines for each yName category
        for (let i = 0; i < categories.length; i++) {
            svg.append('line')
                .attr('x1', 0)
                .attr('x2', dimensions.width)
                .attr('y1', yName(categories[i][0]) + yName.bandwidth())
                .attr('y2', yName(categories[i][0]) + yName.bandwidth())
                .attr('stroke', '#000')
                .attr('stroke-width', 0.1)
                .attr('stroke-dasharray', '2,2');
        }

        // Add group for each category
        const rects_g = svg.selectAll('rects_g').data(categories).enter().append('g');

        const rects = rects_g
            .append('rect')
            .attr('transform', function (d) {
                return `translate(0, ${yName(d[0])})`;
            })
            .attr('fill', function (d, i) {
                return myColor(i * 20);
            })
            .attr('opacity', 0.7)
            .attr('stroke', '#000')
            .attr('stroke-width', 0.1)
            .attr('x', function (d) {
                return x(d[1]);
            })
            .attr('width', function (d) {
                return x(d[2] - d[1]);
            })
            .attr('height', yName.bandwidth());

        rects_g
            .append('g')
            .attr('class', 'brush')
            .attr('transform', function (d) {
                return `translate(0, ${yName(d[0])})`;
            })
            .call(
                d3
                    .brushX()
                    .extent((d) => {
                        return [
                            [x(d[1]), 0],
                            [x(d[2]), yName.bandwidth()],
                        ];
                    })
                    .on('brush', null)
                    .on('end', (event, d) => {
                        // Show the tooltip with options to annotate the segment
                        d3.select(tooltipRef.current)
                            .style('opacity', 1)
                            .style('left', event.sourceEvent.pageX + 10 + 'px')
                            .style('top', event.sourceEvent.pageY + 10 + 'px');
                        setCurrentNode(d);
                    })
            );

        // Add the highlighting functionality
        rects
            .on('mouseover', function (event, d) {
                // Highlight the nodes: every node is green except of him
                rects.style('opacity', 0.2);
                d3.select(this).style('opacity', 1);
            })
            .on('mouseout', (d) => {
                rects.style('opacity', 1);
            });

        let dragStartIndex = 0;

        // In front of each line, add a faGripVertical icon o indicate the the order can be changed. The faIcons have a IconPathData attribute that can be used to draw them. Make sure scale the icon to the correct size.
        yName.domain().forEach((d, i) =>
            svg
                .append('path')
                .attr('class', 'drag-handle')
                .attr('d', faGripVertical.icon[4])
                .attr('transform', `translate(25, ${yName(d) + yName.bandwidth() / 2}) scale(0.05)`)
                .attr('fill', '#000')
                .attr('opacity', 0.5)
                .attr('cursor', 'move')
                .call(
                    d3
                        .drag()
                        .on('start', (event, d) => {
                            // Store the original position of the dragged element
                            dragStartIndex = i;
                        })
                        .on('drag', (event, d) => {
                            // Get the new position of the dragged element
                            const dragEndIndex = Math.min(
                                categories.length - 1,
                                Math.max(0, Math.floor((event.y - dimensions.verticalMargin) / yName.bandwidth()))
                            );

                            // If the position has changed, move the element
                            if (dragEndIndex !== dragStartIndex) {
                                const dragStart = categories[dragStartIndex];
                                categories.splice(dragStartIndex, 1);
                                categories.splice(dragEndIndex, 0, dragStart);
                                dragStartIndex = dragEndIndex;
                                rects_g.data(categories);
                            }
                        })
                )
        );
    }, [Data, data_type]);

    return (
        <div className="segment-chart">
            <svg ref={svgRef} />
            {tooltip(tooltipRef)}
        </div>
    );
};

export default SegmentChart;
