/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import axios from 'axios';
import { Form, FormControl, Row, Col, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Model_Tracker from '../Common/Model_Tracker';
import setupstyle from '../css/Setup.module.css';
import AllReactSelect from '../Common/AllReactSelect';

export default class evaluation_sidebar extends Component {
    expert_study_mode = false;

    constructor(props) {
        super(props);
        this.expSelectRef = React.createRef();
        this.projectSelectRef = React.createRef();
        this.checkpointSelectRef = React.createRef();
        this.envSelectRef = React.createRef();

        this.state = {
            show_section: {
                validation: false,
                benchmark: false,
                embedding_controls: false,
                embedding_detail_settings: false,
                action_angle_controls: false,
            },
        };
    }

    componentDidMount() {
        axios.get('/get_all?model_name=environment').then((res) => {
            const envs = res.data;
            this.props.setSidebarProp({ environments: envs });
        });

        axios.get('/get_all?model_name=experiment').then((res) => {
            const exps = res.data ?? [];
            this.props.setSidebarProp({ experiments: exps });
        });

        axios.get('/get_all?model_name=dataset').then((res) => {
            const datasets = res.data;
            this.props.setSidebarProp({ datasets: datasets });
        });
    }

    toggleSection(section_name) {
        const show_section = this.state.show_section;
        show_section[section_name] = !this.state.show_section[section_name];
        this.setState({ show_section: show_section });
    }

    setProject(project) {
        this.props.setSidebarProp({
            selected_project: this.props.projects.has(project.key)
                ? this.props.projects.get(project.key)
                : undefined,
        });
    }

    getExperimentOptions() {
        if (this.props.experiments.length == 0) return [];

        const return_array = [];
        console.log(this.props.experiments);
        this.props.experiments
            .filter((e) => this.props.selected_project?.project_experiments?.includes(e.exp_name))
            .forEach((experiment, i) => return_array.push({ label: experiment.exp_name, value: experiment.id }));

        return return_array;
    }

    getDatasetOptions() {
        if (this.props.datasets.length == 0) return [];

        console.log(this.props.datasets, this.props.selected_project?.project_datasets);

        const return_array = [];
        this.props.datasets
            .filter((d) => this.props.selected_project?.project_datasets?.includes(d.dataset_name))
            .forEach((dataset, i) => return_array.push({ label: dataset.dataset_name, value: dataset.id }));

        return return_array;
    }

    selectExperiment(event) {
        const selected_experiment =
            event.length > 0 ? this.props.experiments.find((exp) => exp.id === event[0].value) : undefined;
        const selected_env =
            event.length > 0
                ? this.props.environments.find((env) => env.id === selected_experiment?.env_id)
                : undefined;
        this.props.setSidebarProp(
            {
                selected_experiment: selected_experiment,
                primary_env: selected_env,
                selected_checkpoints: selected_experiment?.checkpoint_list.slice(-1) ?? [],
            },
            function () {
                this.envSelectRef.current.setValue({ label: selected_env?.env_name, value: selected_env?.id });
            }
        );
    }

    selectDataset(event) {
        const selected_dataset =
            event.length > 0 ? this.props.datasets.find((dataset) => dataset.id === event[0].value) : undefined;
        this.props.setSidebarProp({ selected_dataset: selected_dataset });
    }

    getEnvironmentOptions() {
        if (this.props.experiments.length == 0 || this.props.environments.length == 0) return [];

        const return_array = [];
        this.props.environments.forEach((env, i) => return_array.push({ label: env.env_name, value: env.id }));

        return return_array;
    }

    getEmbeddingOptions() {
        if (this.props.embeddingMethods.length == 0) return [];
        return this.props.embeddingMethods?.map((option) => ({ label: option, value: option })) ?? [];
    }

    setEmbeddingOption(event) {
        const chosen_method = event.value;
        axios.get(`/embedding/get_embedding_method_params?embedding_method=${chosen_method}`).then((res) => {
            const params = res.data;
            this.props.setSidebarProp({ embedding_settings: params, embedding_method: chosen_method });
        });
    }

    setEmbeddingAxisOptions(event) {
        const chosen_option = event.value;
        this.props.setSidebarProp({ embedding_axis_option: chosen_option });
    }

    selectEvalEnvs(env_type, event) {
        const selected_env = this.props.environments.find((env) => env.id === event.value);
        if (env_type === 'primary') {
            this.props.setSidebarProp({ primary_env: selected_env });
        } else if (env_type === 'secondary') {
            this.props.setSidebarProp({ secondary_env: selected_env });
        }
    }

    selectCheckpointTimestep(event) {
        this.props.setSidebarProp({ selected_checkpoints: event.map((e) => e.value) });
    }

    createInputList(onChange) {
        if (Object.keys(this.props.embedding_settings).length === 0) return;
        const input_list = [];
        const embedding_settings = this.props.embedding_settings;
        const algo_keys = Object.keys(embedding_settings);
        for (const prop_key in algo_keys) {
            if (typeof embedding_settings[algo_keys[prop_key]] == 'boolean') {
                input_list.push(
                    <React.Fragment key={prop_key + 'fragment'}>
                        <Col sm="7">
                            <Form.Label>{algo_keys[prop_key]}</Form.Label>
                        </Col>
                        <Col sm="5">
                            <Form.Control
                                onChange={(event) => onChange(algo_keys[prop_key], event)}
                                as="select"
                                key={prop_key}
                                defaultValue={embedding_settings[algo_keys[prop_key]]}
                            >
                                <option key={prop_key + '_true'} value="true">
                                    true
                                </option>
                                <option key={prop_key + '_false'} value="false">
                                    false
                                </option>
                            </Form.Control>
                        </Col>
                    </React.Fragment>
                );
            } else {
                input_list.push(
                    <React.Fragment key={prop_key + 'fragment'}>
                        <Col sm="7">
                            <Form.Label>{algo_keys[prop_key]}</Form.Label>
                        </Col>
                        <Col sm="5">
                            <Form.Control
                                onChange={(event) => onChange(algo_keys[prop_key], event)}
                                key={prop_key}
                                placeholder={embedding_settings[algo_keys[prop_key]]}
                            />
                        </Col>
                    </React.Fragment>
                );
            }
        }
        return (
            <Form.Group controlId="form_control" as={Row}>
                {input_list}
            </Form.Group>
        );
    }

    setAlgorithmParameter(param, extra) {
        const current_config = this.props.embedding_settings;
        if (!isNaN(extra.target.value)) current_config[param] = Number(extra.target.value);
        else current_config[param] = extra.target.value;
        this.setState({ embedding_settings: current_config });
    }

    render() {
        // Hude column when collapsed
        return (
            <Col
                className={setupstyle.sidebar_column}
                md={2}
                style={{ display: this.props.sidebar_collapsed ? 'none' : 'block' }}
            >
                <Form className={setupstyle.form_elements}>
                    <Select
                        options={Array.from(this.props.projects.values()).map((m) => {
                            return { key: m.id, label: m.project_name };
                        })}
                        placeholder="Select Project..."
                        innerRef={this.projectSelectRef}
                        onChange={this.setProject.bind(this)}
                    />
                    <Form.Text className="text-muted">
                        The project is preselected.You can change it at any time.
                    </Form.Text>
                    <hr className={setupstyle.filter_header_hr} style={{ marginTop: 0 }} />
                    {this.props.selected_project?.project_experiments?.length > 0 ? (
                        <>
                            <Form.Label>Experiment</Form.Label>
                            <div>
                                <AllReactSelect
                                    options={this.getExperimentOptions()}
                                    innerRef={this.expSelectRef}
                                    placeholder="Select Experiment..."
                                    isMulti={true}
                                    allowSelectAll={true}
                                    onChange={this.selectExperiment.bind(this)}
                                />
                                <p className={setupstyle.grey_text}>Select an experiment to investigate</p>
                            </div>
                            <div>
                                <div>
                                    <AllReactSelect
                                        isDisabled={this.props.selected_experiment === undefined}
                                        onChange={this.selectCheckpointTimestep.bind(this)}
                                        allowSelectAll={true}
                                        isMulti={true}
                                        options={
                                            this.props.selected_experiment?.checkpoint_list.map((c) => {
                                                return { value: c, label: c };
                                            }) ?? []
                                        }
                                        innerRef={this.checkpointSelectRef}
                                        placeholder="Experiment Checkpoints"
                                    />
                                    <p className={setupstyle.grey_text}>Checkpoint for Model and Steps</p>
                                </div>
                                <div>
                                    <Select
                                        isDisabled={this.props.selected_experiment === undefined}
                                        options={this.getEnvironmentOptions()}
                                        onChange={this.selectEvalEnvs.bind(this, 'primary')}
                                        innerRef={this.envSelectRef}
                                        placeholder="Optional: Evaluation Environment"
                                    />
                                    <p className={setupstyle.grey_text}>Default: Env used for training if registered</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {this.props.selected_project?.project_datasets?.length > 0 ? (
                        <>
                            <Form.Label>Dataset</Form.Label>
                            <div>
                                <AllReactSelect
                                    options={this.getDatasetOptions()}
                                    innerRef={this.expSelectRef}
                                    placeholder="Select Dataset..."
                                    isMulti={true}
                                    allowSelectAll={true}
                                    onChange={this.selectDataset.bind(this)}
                                />
                                <p className={setupstyle.grey_text}>Select a dataset to investigate</p>
                            </div>
                            <div></div>
                        </>
                    ) : null}
                    <Button
                        variant="success"
                        style={{ marginLeft: '10px' }}
                        disabled={
                            this.props.selected_experiment === undefined && this.props.selected_dataset === undefined
                        }
                        onClick={() => this.props.addToScheduled(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Add to Scheduled Runs
                    </Button>
                    <Model_Tracker
                        benchmarkedModels={this.props.scheduled_benchmarks}
                        deleteModel={this.props.removeFromScheduled}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant="info"
                            style={{ display: 'inline-block', margin: '15 auto' }}
                            disabled={
                                this.props.selected_experiment === undefined &&
                                this.props.scheduled_benchmarks.length === 0
                            }
                            onClick={() => this.props.onBenchmarkButton(false)}
                        >
                            {' '}
                            Load Scheduled Runs
                        </Button>
                        <p className={setupstyle.grey_text}>
                            Collected data for selected runs. This step can take up to ~30 sec. Note that episode length
                            is capped at 10k steps
                        </p>
                    </div>
                    <hr className={setupstyle.filter_header_hr} style={{ marginTop: 0 }} />
                    <Form.Label>
                        Benchmark Agent
                        <FontAwesomeIcon icon={faCaretDown} onClick={this.toggleSection.bind(this, 'benchmark')} />
                    </Form.Label>
                    <Collapse in={this.state.show_section.benchmark}>
                        <div>
                            <Model_Tracker
                                benchmarkedModels={this.props.benchmarked_models}
                                deleteModel={this.props.deleteModel}
                            />
                            <FormControl
                                name="n_episodes"
                                placeholder="Episodes"
                                key="n_episodes"
                                onChange={(event) => this.props.setSidebarProp({ n_episodes: event.target.value })}
                            />
                            <p className={setupstyle.grey_text}>How many episodes to collect during benchmarking</p>
                            <Button
                                variant="info"
                                disabled={this.props.selected_experiment === undefined}
                                onClick={() => this.props.onBenchmarkButton(false)}
                            >
                                {' '}
                                New Benchmark
                            </Button>
                            <Button
                                variant="success"
                                style={{ marginLeft: '10px' }}
                                disabled={this.props.selected_experiment === undefined}
                                onClick={() => this.props.onBenchmarkButton(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Add to Current
                            </Button>
                            <p className={setupstyle.grey_text}>
                                Either start a new environment benchmark, or add to the existing runs (for common
                                embedding).
                            </p>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    checked={this.props.request_rendering}
                                    onChange={(event) =>
                                        this.props.setSidebarProp({ request_rendering: event.target.checked })
                                    }
                                />
                                <Form.Check.Label className="text-muted">Show Env. Rendering</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    checked={this.props.deterministic_evaluation}
                                    onChange={(event) =>
                                        this.props.setSidebarProp({ deterministic_evaluation: event.target.checked })
                                    }
                                />
                                <Form.Check.Label className="text-muted">Deterministic Evalution</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    defaultChecked={this.props.reset_state}
                                    onChange={(event) =>
                                        this.props.setSidebarProp({ reset_state: event.target.checked })
                                    }
                                />
                                <Form.Check.Label className="text-muted">
                                    Reset State after Episode End (for common trajectories)
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="checkbox">
                                <Form.Check.Input
                                    defaultChecked={this.props.request_explainer}
                                    onChange={(event) =>
                                        this.props.setSidebarProp({ request_explainer: event.target.checked })
                                    }
                                />
                                <Form.Check.Label className="text-muted">Run Attribution Method</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </Collapse>
                </Form>
            </Col>
        );
    }
}
