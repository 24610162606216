import React, { Component, useCallback, useState } from 'react';
import { Row, Card, Collapse, Button } from 'react-bootstrap';
import rankingstyle from '../css/Ranking.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function ranking_list(props) {
    // Hook for openCardDetails
    const [openCardDetails, setOpenCardDetails] = useState(false);
    const toggle = () => useCallback(() => setOpenCardDetails(!openCardDetails), [openCardDetails]);

    const getItems = (count) =>
        Array.from({ length: count }, (v, k) => k).map((k) => ({
            id: `item-${k}`,
            content: `item ${k}`,
        }));

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        console.log(result);

        if (!result.destination) {
            return;
        }

        /*const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items
        });*/
    };

    return (
        <>
            <Row>
                <h4>Ranking of Selected Episodes</h4>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" isCombineEnabled>
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {getItems(5).map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <Card
                                                style={{
                                                    marginBottom: '10px',
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card.Header as="h6">Episode #{index}</Card.Header>
                                                <Card.Body style={{ padding: '0 1rem' }}>
                                                    <p>Episode Glyph</p>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <Button
                                                        variant="light"
                                                        onClick={() => setOpenCardDetails(index)}
                                                        style={{ float: 'right' }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            title="left"
                                                            aria-hidden={true}
                                                        />
                                                    </Button>
                                                    <Collapse
                                                        key={'collapse' + index}
                                                        in={openCardDetails}
                                                        onEntered={toggle}
                                                    >
                                                        <div id="example-collapse-text">
                                                            <p>Details on Demand</p>
                                                        </div>
                                                    </Collapse>
                                                </Card.Footer>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Row>
        </>
    );
}

/*
<div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {item.content}
                                            </div>
*/
