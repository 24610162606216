import React, { useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Setup_Main from '../Setup/Setup_Main';
import Experiment_Tracking_Main from '../Experiment_Tracking/Experiment_Tracking_Main';
import Evaluation_Main from '../Evaluation/Evaluation_Main';
import Navigation from '../Navigation/Navbar';
import Help_Modal from '../Navigation/Help_Modal';
import Tensorboard_Iframe from '../Experiment_Tracking/Tensorboard_Iframe';
import Database_Browser from '../Misc/Database_Browser';
import Ranking_Main from '../Ranking/Ranking_Main';
import MultiFeedback from '../MultiFeedback/MultiFeedback';

function App() {
    const [showHelpModal, setShowHelpModal] = useState(true);
    const [demoScenario, setDemoScenario] = useState(undefined);

    return (
        <div id="app" className="App">
            <HashRouter>
                <Navigation toggleHelp={setShowHelpModal} />
                <div style={{ marginTop: '2.5vh' }}>
                    <Routes>
                        <Route path="/" element={<Evaluation_Main demoScenario={demoScenario} />} />
                        <Route path="/setup" element={<Setup_Main />} />
                        <Route path="/experiments" element={<Experiment_Tracking_Main />} />
                        <Route path="/MultiFeedback" element={<MultiFeedback />} />
                        <Route path="/ranking" element={<Ranking_Main />} />
                        <Route path="/evaluation" element={<Evaluation_Main demoScenario={demoScenario} />} />
                        <Route path="/tensorboard" element={<Tensorboard_Iframe />} />
                        <Route path="/databasebrowser" element={<Database_Browser />} />
                    </Routes>
                </div>
            </HashRouter>
            {showHelpModal && (
                <Help_Modal isOpen={showHelpModal} closeModal={setShowHelpModal} setDemoScenario={setDemoScenario} />
            )}
        </div>
    );
}

export default App;
