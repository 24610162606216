import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const LineChart = ({ Data, data_type }) => {
    // Element References
    const svgRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        // D3 Code

        // Data
        const data = Data;

        // Accessors
        const parseDate = d3.timeParse('%Y%m%d');
        let xAccessor;
        let yAccessor;
        // variable accessor depending on datatype
        switch (data_type) {
            case 'campaign_conversions':
                xAccessor = (d) => parseDate(d.date);
                yAccessor = (d) => d.Conversions;
                break;
            case 'campaign_impressions':
                xAccessor = (d) => parseDate(d.date);
                yAccessor = (d) => d.Impressions;
                break;
            default:
            // n/a
        }

        // Dimensions
        const dimensions = {
            width: svgRef.current.parentElement.clientWidth,
            height: svgRef.current.parentElement.clientHeight,
            margins: 50,
            marginBottom: 80,
        };

        // Selections
        const svg = d3
            .select(svgRef.current)
            .classed('line-chart-svg', true)
            .attr('width', dimensions.width)
            .attr('height', dimensions.height);

        // List of node names
        const allNodes = data.nodes.map((d) => d.position);

        // List of groups
        let allGroups = data.nodes.map((d) => d.group);
        allGroups = [...new Set(allGroups)];

        // A color scale for groups:
        const color = d3.scaleOrdinal().domain(allGroups).range(d3.schemeSet1);

        // A linear scale for node size
        const size = d3.scaleLinear().domain([1, 5]).range([0.5, 8]);

        // A linear scale to position the nodes on the X axis
        const x = d3.scalePoint().range([0, dimensions.width]).domain(allNodes);

        // In my input data, links are provided between nodes -id-, NOT between node names.
        // So I have to do a link between this id and the name
        const idToNode = {};
        data.nodes.forEach(function (n) {
            idToNode[n.id] = n;
        });

        // x-axis, do not draw ticks
        svg.append('g')
            .attr('transform', `translate(0,${dimensions.height - dimensions.marginBottom + 5})`)
            .call(d3.axisBottom(x).tickSize(0).tickFormat(''));

        // Add the links
        const links = svg
            .selectAll('mylinks')
            .data(data.links)
            .join('path')
            .attr('d', (d) => {
                const start = x(idToNode[d.source].position); // X position of start node on the X axis
                const end = x(idToNode[d.target].position); // X position of end node
                return [
                    'M',
                    start,
                    dimensions.height - dimensions.marginBottom, // the arc starts at the coordinate x=start, y=height-30 (where the starting node is)
                    'A', // This means we're gonna build an elliptical arc
                    (start - end) / 2,
                    ',', // Next 2 lines are the coordinates of the inflexion point. Height of this point is proportional with start - end distance
                    (start - end) / 10,
                    0,
                    0,
                    ',',
                    start < end ? 1 : 0,
                    end,
                    ',',
                    dimensions.height - dimensions.marginBottom,
                ] // We always want the arc on top. So if end is before start, putting 0 here turn the arc upside down.
                    .join(' ');
            })
            .style('fill', 'none')
            .attr('stroke', 'grey')
            .style('stroke-width', (d) => d.n + 0.2)
            .style('stroke-opacity', 0.0);

        // Add the rects for the nodes
        const nodes = svg
            .selectAll('mynodes')
            .data(
                data.nodes.sort((a, b) => {
                    +b.n - +a.n;
                })
            )
            .join('rect')
            .attr('x', (d) => x(d.position) - size(d.n) / 2)
            .attr('y', dimensions.height - dimensions.marginBottom)
            .attr('width', (d) => size(d.n))
            .attr('height', (d) => size(d.n))
            .style('fill', (d) => color(d.group))
            .attr('stroke', 'white');

        // And give them a label
        const labels = svg
            .selectAll('mylabels')
            .data(data.nodes)
            .join('text')
            .attr('x', 0)
            .attr('y', 0)
            .text((d) => d.position)
            .style('text-anchor', 'end')
            .attr(
                'transform',
                (d) => `translate(${x(d.position)},${dimensions.height - dimensions.marginBottom + 30}) rotate(-45)`
            )
            .style('font-size', 6);

        // Add the highlighting functionality
        nodes
            .on('mouseover', function (event, d) {
                // Highlight the nodes: every node is green except of him
                nodes.style('opacity', 0.2);
                d3.select(this).style('opacity', 1);

                // Highlight the connections
                links
                    .style('stroke', (a) => (a.source === d.id || a.target === d.id ? color(d.group) : '#b8b8b8'))
                    .style('stroke-opacity', (a) => (a.source === d.id || a.target === d.id ? 1 : 0.0));
                //.style('stroke-width', (a) => (a.source === d.id || a.target === d.id ? 4 : 1));
                labels
                    .style('font-size', (b) => (b.position === d.position ? 18.9 : 2))
                    .attr('y', (b) => (b.position === d.position ? 10 : 0));
            })
            .on('mouseout', (d) => {
                nodes.style('opacity', 1);
                links.style('stroke', 'grey').style('stroke-opacity', 0.0).style('stroke-width', '1');
                labels.style('font-size', 6);
            });
    }, [Data, data_type]);

    return (
        <div className="line-chart">
            <svg ref={svgRef} />
            <div ref={tooltipRef} className="lc-tooltip">
                <div className="data"></div>
                <div className="date"></div>
            </div>
        </div>
    );
};

export default LineChart;
