const pre_configured_scenarios = {
    breakout: {
        id: 'breakout',
        project: 2,
        experiments: [1],
        environment: 107,
        checkpoints: [1000000, 5000000, 8000000],
        n_episodes: 3,
        reset_state: true,
        deterministic_evaluation: false,
        embedding_method: 'UMAP',
        request_explainer: true,
        embedding_axis_option: '2d_embedding',
        embedding_settings: {
            densmap: false,
            n_neighbors: 100,
            min_dist: 0.05,
        },
        gym_registry_id: 'ALE/Breakout-v5',
    },
    seaquest: {
        id: 'seaquest',
        project: 2,
        experiments: [2],
        environment: 108,
        checkpoints: [1000000, 3000000, 5000000, 7000000, 9000000],
        n_episodes: 1,
        reset_state: false,
        deterministic_evaluation: true,
        request_reproject: true,
        embedding_method: 'UMAP',
        request_explainer: true,
        embedding_axis_option: '2d_embedding',
        embedding_settings: {
            densmap: true,
            n_neighbors: 100,
            min_dist: 0.05,
        },
        gym_registry_id: 'ALE/Seaquest-v5',
    },
    pacman: {
        id: 'pacman',
        project: 2,
        experiments: [3, 4],
        environment: 109,
        checkpoints: [10000000],
        n_episodes: 1,
        reset_state: false,
        request_explainer: true,
        request_reproject: true,
        deterministic_evaluation: true,
        embedding_method: 'UMAP',
        embedding_axis_option: '2d_embedding',
        embedding_settings: {
            densmap: true,
            n_neighbors: 100,
            min_dist: 0.05,
        },
        gym_registry_id: 'ALE/MsPacman-v5',
    },
};

export default pre_configured_scenarios;
