import React, { Component } from 'react';
import { Row, Card, Collapse, Button, Form } from 'react-bootstrap';
import Model_Glyph from '../Common/Model_Glyph';
import evalstyle from '../css/Evaluation.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Card_Small_Multiples from '../Common/Card_Small_Multiples';

export default function model_carousel(props) {
    // Not super efficient, but probably fine for a "smallish" amount of tracked runs
    // Checks max. values across all available objects, alter used for normalization
    const original_stat_list = Array.from(props.benchmarkedModels, ([k, v]) => v.stats);
    const model_stat_list = Array.from(structuredClone(props.benchmarkedModels), ([k, v]) => v.stats);
    const max_stats = {};
    Object.keys(model_stat_list[0] ?? {}).forEach(function (key, _) {
        max_stats[key] = Math.max(...Array.from(props.benchmarkedModels, ([k, v]) => v.stats).map((s) => s[key]));
    });
    model_stat_list.forEach((s) =>
        Object.keys(s).forEach(function (key, _) {
            s[key] = max_stats[key] != 0.0 ? s[key] / max_stats[key] : 1.0;
        })
    );

    console.log(props.episodeData);

    return (
        <>
            <Row
                style={{
                    textAlign: 'center',
                    paddingBottom: '5px',
                    paddingTop: '5px',
                    borderBottom: 'solid 2px #a5a5a5',
                }}
            >
                <h4>Model List</h4>
            </Row>
            <Row>
                <InfiniteScroll
                    dataLength={props.benchmarkedModels.size} //This is important field to render the next data
                    height={'90vh'}
                    next={function () {
                        return null;
                    }}
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                >
                    {Array.from(props.benchmarkedModels, ([k, v]) => v).map((item, index) => {
                        return (
                            <Card key={'card' + index} style={{ marginBottom: '10px' }}>
                                <Card.Header as="h6">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        defaultChecked={true}
                                        onChange={() => props.toggleShowModel(index)}
                                    />
                                    {item.model_name}
                                </Card.Header>
                                <Card.Body style={{ padding: '0 1rem' }}>
                                    <Card.Text style={{ marginBottom: '5px' }}>
                                        {item.model_description ?? ''}
                                    </Card.Text>
                                    <Model_Glyph
                                        data={model_stat_list[index] ?? {}}
                                        originalData={original_stat_list[index] ?? {}}
                                    />
                                </Card.Body>
                                <Card.Footer>
                                    <Button
                                        variant="light"
                                        onClick={() => props.setOpenCardDetails(index)}
                                        style={{ float: 'right' }}
                                    >
                                        <FontAwesomeIcon icon={faChevronDown} title="left" aria-hidden={true} />
                                    </Button>
                                    <Collapse
                                        key={'collapse' + index}
                                        in={props.openCardDetails[index]}
                                        onEntered={props.setHasExpandedCard}
                                    >
                                        <div id="example-collapse-text">
                                            <Card.Body>
                                                <Card.Text>
                                                    <b>Model Description:</b> {item.model_description ?? ''}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Model Type:</b> {item.model_type ?? ''}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Model Parameters:</b> {item.model_parameters ?? ''}
                                                </Card.Text>
                                                <Card.Text>
                                                    <b>Model Path:</b> {item.model_path ?? ''}
                                                </Card.Text>
                                                <Card_Small_Multiples
                                                    episodeData={
                                                        props.episodeData?.length > 0
                                                            ? props.episodeData.slice(
                                                                  index * item.episode_num,
                                                                  index * item.episode_num + item.episode_num - 1
                                                              )
                                                            : []
                                                    }
                                                    episodeInfo={
                                                        props.episodeInfos?.length > 0
                                                            ? props.episodeInfos.slice(
                                                                  index * item.episode_num,
                                                                  index * item.episode_num + item.episode_num - 1
                                                              )
                                                            : []
                                                    }
                                                    isOpen={props.hasExpandedCard}
                                                />
                                            </Card.Body>
                                        </div>
                                    </Collapse>
                                </Card.Footer>
                            </Card>
                        );
                    }) ?? null}
                </InfiniteScroll>
            </Row>
        </>
    );
}
