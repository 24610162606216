/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import { Row, Col, ListGroup, Button } from 'react-bootstrap';
// Components
import Buffer_Chart from './Buffer_Chart';
import Segment_View from './Segment_View';
import MultiFeedback_Sidebar from './MultiFeedback_Sidebar';
// import play, pause, stop, and refresh icons from fa-svg
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStop, faSync } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const buffer_infos = (props) => {
    return (
        <Row>
            <Col md={{ span: 2 }}>
                Buffer Size: <b>{props.buffer_size}</b>
            </Col>
            <Col md={{ span: 2 }}>
                Episodes: <b>{props.num_episodes}</b>
            </Col>
            <Col md={{ span: 2 }}>
                Labeled: <b>{props.already_labeled}</b>
            </Col>
            <Col md={{ span: 2 }}>
                Avg. Reward: <b>{props.current_avg_reward}</b>
            </Col>
            <Col md={{ span: 2 }}>
                Avg. Length: <b>{props.avg_episode_length}</b>
            </Col>
            <Col>
                Status: <b>{props.status}</b>
            </Col>
        </Row>
    );
};

const buffer_data = {
    // Random data for now. Nodes have a increasing position x, an assigned group (Start, Goal, Event, Normal), and a value n representing the numer of steps in the episode.
    nodes: Array.from({ length: 100 }, (_, i) => ({
        id: i,
        position: i,
        group: i === 0 ? 'Start' : i % 50 === 0 ? 'Goal' : i % 10 === 0 ? 'Event' : 'Normal',
        n: i === 0 ? 4 : i === 99 ? 3 : i % 10 === 0 ? 6 : Math.floor(Math.random() * 5) + 1,
    })),
    // Generate a random set of links (representing similar nodes). Eachl ink has a source and target node, and a value n representing the number of similar steps.
    links: Array.from({ length: 120 }, (_, i) => ({
        source: Math.floor(Math.random() * 99),
        target: Math.floor(Math.random() * 99),
        n: Math.floor(Math.random() * 5) + 1,
    })),
};

const mission_view = () => {
    // The mission text string togeher with a 150x150 image showing the mission screen (use a placeholder image for now), should be centered in the middle of the screen.
    return (
        <>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <h3>Mission</h3>
                    <p>Pick up the red key and put it next to the red box.</p>
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <img src="/files/thumbnail_project_4.png" alt="placeholder" width={150} height={150} />
                </Col>
            </Row>
        </>
    );
};

const Overview = () => {
    // Dummy Compoent: Box of size 40vh, 60vw
    return (
        <div style={{ height: '40vh', width: '60vw', border: '1px solid black' }}>
            <h1>Overview</h1>
        </div>
    );
};

const Uncertainty = () => {
    // Dummy Compoent: Box of size 40vh, 60vw
    return (
        <div style={{ height: '40vh', width: '60vw', border: '1px solid black' }}>
            <h1>Uncertainty</h1>
        </div>
    );
};

const Feedback = () => {
    // Dummy Compoent: Box of size 50vh, 30vw
    return (
        <div style={{ height: '50vh', width: '30vw', border: '1px solid black' }}>
            <h1>Feedback</h1>
        </div>
    );
};

const Layout = (props) => {
    return (
        <Col>
            <Row>
                <div>
                    <div>
                        <input type="checkbox" id="Gradient Norm" name="Gradient Norm" value="Gradient Norm" checked />
                        <label id="Gradient Norm">Gradient Norm</label>
                        <input type="checkbox" id="Uncertainty" name="Uncertainty" value="Uncertainty" checked />
                        <label id="Uncertainty">Uncertainty</label>
                        <input type="checkbox" id="HITL feedback" name="HITL feedback" value="HITL feedback" checked />
                        <label id="HITL feedback">HITL feedback</label>
                    </div>
                </div>
                <div>
                    <div>
                        <select id="env_dropdown" style={{ marginRight: '3px', width: '10vw' }}>
                            <option value="env1">env1</option>
                            <option value="env2">env2</option>
                            <option value="env3">env3</option>
                        </select>
                        <select id="model_dropdown" style={{ width: '15vw' }}>
                            <option value="new model">new model</option>
                            <option value="model1">model1</option>
                            <option value="model2">model2</option>
                            <option value="model3">model3</option>
                        </select>
                        <button
                            id="start_button"
                            style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw', marginLeft: '3px', height: '36px' }}
                        >
                            <FontAwesomeIcon icon={faPlay} />
                        </button>
                        <button
                            id="pause_button"
                            style={{
                                paddingLeft: '0.5vw',
                                paddingRight: '0.5vw',
                                marginLeft: '3px',
                                marginRight: '3px',
                                height: '36px',
                            }}
                        >
                            <FontAwesomeIcon icon={faPause} />
                        </button>
                        <button
                            id="stop_button"
                            style={{
                                paddingLeft: '0.5vw',
                                paddingRight: '0.5vw',
                                marginLeft: '3px',
                                marginRight: '3px',
                                height: '36px',
                            }}
                        >
                            <FontAwesomeIcon icon={faStop} />
                        </button>
                        <button
                            id="refresh_button"
                            style={{ paddingLeft: '0.5vw', paddingRight: '0.5vw', height: '36px' }}
                        >
                            <FontAwesomeIcon icon={faSync} />
                        </button>
                        <div
                            id="notification_dot"
                            style={{
                                height: '10px',
                                width: '10px',
                                borderRadius: '5px',
                                marginLeft: '-5px',
                                marginTop: '-3px',
                            }}
                        ></div>
                    </div>
                </div>
                <div>
                    <div>
                        <label
                            id="time_counter"
                            style={{
                                margin: 0,
                                position: 'relative',
                                top: '50%',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            Time elapsed: {0}
                        </label>
                    </div>
                </div>
                <div>
                    <div>
                        <label
                            style={{
                                margin: 0,
                                position: 'relative',
                                top: '50%',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            Training progress:{' '}
                        </label>
                    </div>
                </div>
                <div>
                    <div>
                        <progress
                            id="progress_bar"
                            value="0"
                            max="100"
                            style={{
                                fontSize: '1rem',
                                height: '0.8vw',
                                width: '20vw',
                                margin: 0,
                                position: 'relative',
                                top: '50%',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                            }}
                        ></progress>
                    </div>
                </div>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Overview
                            lossReward={props.lossReward}
                            style={{
                                paddingRight: '1vw',
                                paddingLeft: '1vw',
                                paddingTop: '1vh',
                                paddingBottom: '1vh',
                                backgroundColor: '#f4f4f4',
                            }}
                        />
                    </Row>
                    <Row>
                        <Uncertainty
                            uncertainty={props.uncertainty}
                            style={{
                                paddingRight: '1vw',
                                paddingLeft: '1vw',
                                paddingBottom: '1vh',
                                backgroundColor: '#f4f4f4',
                            }}
                        />
                    </Row>
                </Col>
                <Col>
                    <Feedback
                        feedback={props.feedback}
                        style={{
                            paddingRight: '1vw',
                            paddingLeft: '1vw',
                            paddingBottom: '1vh',
                            backgroundColor: '#f9f9f9',
                            width: '28vw',
                            height: '85vh',
                        }}
                    />
                </Col>
            </Row>
        </Col>
    );
};

class MultiFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buffer_size: 100,
            num_episodes: 10,
            already_labeled: 20,
            current_avg_reward: 0.5,
            avg_episode_length: 10,
            status: 'Training',
            sidebar_collapsed: false,
            projects: [],
            scheduled_benchmarks: [],
            selected_experiment: undefined,
            sidebar_collapsed: false,
            benchmarked_models: new Map(),
        };
    }

    setSidebarProp(prop, value) {
        this.setState({ [prop]: value });
    }

    addToScheduled() {        
        const active_checkpoints = this.state.scheduled_benchmarks.map((b) => b.checkpoint_step);

        const new_benchmarks = [];
        if (this.state.selected_dataset !== undefined) {
            new_benchmarks.push({
                env_id: -1,
                checkpoint_step: -1,
                force_overwrite: true,
                benchmark_type: 'dataset',
                benchmark_id: this.state.selected_dataset.id,
                dataset_name: this.state.selected_dataset.name,
            });
        }

        this.state.selected_checkpoints.filter((c) => !active_checkpoints.includes(c.value)).forEach((c) => {
            const env_id = this.state.primary_env?.id ?? -1;

            new_benchmarks.push({
                    env_id: env_id,
                    force_overwrite: this.state.force_overwrite,
                    benchmark_type: 'trained',
                    benchmark_id: this.state.selected_experiment.id,
                    checkpoint_step: c,
                    deterministic: this.state.deterministic_evaluation,
                    n_episodes: this.state.n_episodes,
                    render: this.state.request_rendering,
                    run_explainer: this.state.request_explainer,
                    reset_state: this.state.reset_state,
                    gym_registry_id: this.state.gym_registry_id,
            });
        });
        this.setState({ scheduled_benchmarks: [...this.state.scheduled_benchmarks, ...new_benchmarks] });
    }

    removeFromScheduled(benchmark_id, env_id, checkpoint_step) {
        const remove_item = this.state.scheduled_benchmarks.findIndex(
            (r) => r.benchmark_id == benchmark_id && r.env_id == env_id && r.checkpoint_step == checkpoint_step
        );
        if (remove_item > 0) {
            const new_scheduled = this.state.scheduled_benchmarks;
            new_scheduled.splice(remove_item, 1);
            this.setState({ scheduled_benchmarks: new_scheduled })
        } else if (remove_item == 0) {
            this.setState({ scheduled_benchmarks: [] })
        }
    }

    toggleSidebar() {
        this.setState({ sidebar_collapsed: !this.state.sidebar_collapsed });
    }

    benchmarkTrainedModel() {
        this.setState({ is_loading: true });
        axios
            .post(
                '/data/run_benchmark',
                this.state.scheduled_benchmarks
            )
            .then((res) => {
                const buffers = res.data;
                let benchmarked_models = new Map();
                this.state.scheduled_benchmarks.forEach((run, i) => {
                    const after_bm_model = run;
                    const model_exp = (run.benchmark_type === "dataset") ? this.state.datasets?.find((e) => e.id == run.benchmark_id) : this.state.experiments?.find((e) => e.id == run.benchmark_id);
                    const gym_name = (run.benchmark_type === "dataset") ? "" : this.state.environments.find((e) => e.id == run.env_id).env_name;
                    after_bm_model['stats'] = buffers?.models[i]?.additional_metrics;
                    after_bm_model['model_name'] = (run.benchmark_type === "dataset") ? model_exp.dataset_name : model_exp.exp_name + ' ' + gym_name + ' ' + run.checkpoint_step;
                    after_bm_model['model_description'] = (run.benchmark_type === "dataset") ? model_exp.dataset_description : model_exp.exp_comment;
                    after_bm_model['gym_registration_id'] = gym_name;
                    benchmarked_models = benchmarked_models.set(
                        "benchmark_run_" + i,
                        after_bm_model
                )});

                this.setState({
                    n_steps: buffers?.n_steps ?? 0,
                    env_space_info: buffers?.env_space_info,
                    benchmarked_models: benchmarked_models,
                    open_card_details: Array.from(benchmarked_models.keys()).map(() => false),
                    show_models: Array.from({ length: benchmarked_models.size }, () => true),
                });
                this.loadCollectedData();
            });
    }

    deleteBenchmarkedModel(model_id) {
        const new_benchmarked_models = new Map(this.state.benchmarked_models);
        new_benchmarked_models.delete(model_id);
        this.setState({ benchmarked_models: new_benchmarked_models });
    }

    render() {
        return (
            <Row>
                <MultiFeedback_Sidebar
                    mode="evaluation"
                    collapsed={this.state.sidebar_collapsed}
                    deleteModel={this.deleteBenchmarkedModel.bind(this)}
                    onBenchmarkButton={this.benchmarkTrainedModel.bind(this)}
                    addToScheduled={this.addToScheduled.bind(this)}
                    removeFromScheduled={this.removeFromScheduled.bind(this)}
                    setSidebarProp={this.setSidebarProp.bind(this)}
                    {...this.state}
                />
                <Col md={(this.state.sidebar_collapsed) ? 12 : 10}>
                    <Row>
                        <Col md={1}>
                                                <Button variant='secondary' onClick={this.toggleSidebar.bind(this)} style={{ zIndex: 1, position: 'absolute', top: '4vh', marginBottom: '1rem'}}>
                                                <FontAwesomeIcon icon={faBars} />
                    </Button>
                        </Col>
                        <Col md={10}>
                        <Row style={{ height: '4vh', marginTop: "2vh", textAlign: "center" }} className="justify-content-md-center">
                            {buffer_infos({
                                buffer_size: this.state.buffer_size,
                                num_episodes: this.state.num_episodes,
                                already_labeled: this.state.already_labeled,
                                current_avg_reward: this.state.current_avg_reward,
                                avg_episode_length: this.state.avg_episode_length,
                                status: this.state.status,
                            })}
                        </Row>
                        <Row style={{ height: '20vh' }}>
                            <Buffer_Chart Data={buffer_data} data_type="buffer_content" />
                        </Row>
                        <Row style={{ height: '20vh', marginTop: "2vh" }}>
                            <Col md={{ span: 6, offset: 3 }}>
                                {mission_view()}
                            </Col>
                        </Row>
                        <Row style={{ height: '25vh', marginTop: "2vh" }}>
                            <Segment_View Data={buffer_data} data_type="buffer_segments"/>
                        </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}


export default MultiFeedback;
