/* eslint-disable prettier/prettier */
import React, { PureComponent } from 'react';
import { Accordion, ListGroup, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { legend } from '../Common/Color_Legend';
import * as d3 from 'd3';
import axios from 'axios';
import evalstyle from '../css/Evaluation.module.css';
import { Color2D } from '../Common/2dcolormaps';
import { line } from '@visx/shape';

const circle_function = (cx, cy, r) => {
    return (
        'M ' +
        (cx - r) +
        ', ' +
        cy +
        ' a ' +
        r +
        ',' +
        r +
        ' 0 1,0 ' +
        r * 2 +
        ',0' +
        ' a ' +
        r +
        ',' +
        r +
        ' 0 1,0 ' +
        -(r * 2) +
        ',0'
    );
};

export default class Evaluation_Embedding extends PureComponent {
    lasso = null;

    constructor(props) {
        super(props);
        this.embeddingRef = React.createRef();
        this.backgroundColorLegendRef = React.createRef();
        this.objectColorLegendRef = React.createRef();

        this.state = {
            rawdat: [],
            labels: [],
            centroids: [],
            merged_points: [],
            connections: [],
            feature_embedding: [],
            transition_embedding: [],
            data_timestamp: 0,
            sequence_length: 1,
            background_layer_color_scale_mode: 'step_reward',
            object_layer_color_scale_mode: 'step_reward',
            draw_lasso: false,
            object_layer_colors: [],
            highlighted: [],
            selected: [],
            object_color_scale: undefined,
            background_color_scale: undefined,
            background_layer_colors: [],
            step_images: ['/files/base.jpeg', '/files/traj.jpeg', '/files/latent.jpeg', '/files/transitions.jpeg'],
            x: undefined,
            y: undefined,
            k: 1.0,
        };

        this._currentZoomLevel = 1;
    }

    componentDidMount() {
        this.drawChart('', []);
    }

    changeSequenceSlider(event) {
        const seq_length = event.target.value;
        this.setState({ sequence_length: seq_length });
    }

    resizeSVG() {
        this.drawChart(
            this.props.viewMode,
            this.state.rawdat,
            this.state.labels,
            this.state.merged_points,
            this.state.connections,
            this.state.feature_embedding,
            this.state.transition_embedding,
            this.props.actionData,
            this.props.currentDoneData,
            this.props.labelInfo,
            this.props.annotationMode
        );
    }

    loadData() {
        const new_color_scale = d3
            .scaleSequential((t) => d3.interpolateOrRd(t * 0.85 + 0.15))
            .domain(d3.extent(this.props.currentRewardData));
        const embedding_method = this.props.embeddingMethod;
        // If no 2d embedding is selected, only return a one-D embedding (i.e. one component)
        const use_one_d_embedding = this.props.embeddingAxisOption !== '2D embedding' ? 1 : 0;
        const reproject = this.props.reproject ? 1 : 0;
        const append_time = this.props.appendTimestamp ? 1 : 0;
        console.log("BM MODELS", this.props.benchmarkedModels);
        axios
            .post(
                '/embedding/current_obs_to_embedding?sequence_length=' +
                    this.state.sequence_length +
                    '&embedding_hash=' +
                    this.computeHashFromOptions(
                        this.props.benchmarkedModels,
                        embedding_method,
                        use_one_d_embedding,
                        reproject,
                        append_time,
                        this.props.embeddingSettings
                    ) +
                    '&embedding_method=' +
                    embedding_method +
                    '&reproject=' +
                    reproject +
                    '&use_one_d_embedding=' +
                    use_one_d_embedding +
                    '&reproject_checkpoint_step=' +
                    this.props.selectedCheckpoint +
                    '&append_time=' +
                    append_time,
                { benchmarks: this.props.benchmarkedModels, embedding_props: this.props.embeddingSettings }
            )
            .then((res) => {
                const data = res.data;
                const objColorData = this.getColorsForObjects();
                const bgColorData = this.getColorsForBackground();
                const selected_points = this.props.infos.map((i) => i['selected']);
                const highlighted_points = this.props.infos.map((i) => i['highlighted']);
                this.setState(
                    {
                        rawdat: data.embedding,
                        labels: data.labels,
                        centroids: data.centroids,
                        merged_points: data.merged_points,
                        connections: data.connections,
                        feature_embedding: data.feature_embedding,
                        transition_embedding: data.transition_embedding,
                        data_timestamp: this.props.timeStamp,
                        object_layer_colors: objColorData.colors,
                        selected: selected_points,
                        highlighted: highlighted_points,
                        background_layer_colors: bgColorData.colors,
                        color_scale: objColorData.scale,
                    },
                    this.drawChart(
                        this.props.viewMode,
                        data.embedding,
                        data.labels,
                        data.merged_points,
                        data.connections,
                        data.feature_embedding,
                        data.transition_embedding,
                        this.props.actionData,
                        this.props.currentDoneData,
                        this.props.labelInfo,
                        this.props.annotationMode
                    )
                );
            });
    }

    computeHashFromOptions(
        scheduled_benchmarks,
        embedding_method,
        use_one_d_embedding,
        reproject,
        append_time,
        embedding_settings
    ) {
        // Create unique non-secure hash from options without the crypto library
        // This is used to cache the embeddings
        const hash =
            JSON.stringify(scheduled_benchmarks) +
            embedding_method +
            use_one_d_embedding +
            reproject +
            append_time +
            JSON.stringify(embedding_settings);
        let hashValue = 0;
        for (let i = 0; i < hash.length; i++) {
            hashValue += hash.charCodeAt(i);
        }
        return hashValue;
    }

    getColorData(mode) {
        if (mode === 'none') {
            const color_scale = d3
                .scaleLinear()
                .domain(d3.extent(this.props.currentRewardData))
                .range(['white', 'white']);
            return {
                data: this.props.currentRewardData,
                colors: this.props.currentRewardData.map((c) => 'white'),
                scale: color_scale,
            };
        }

        if (mode === 'step_reward') {
            if (this.props.currentRewardData.length > 0) {
                const data = this.props.currentRewardData;
                const color_scale = d3
                    .scaleSequential((t) => d3.interpolateOrRd(t * 0.85 + 0.15))
                    .domain(d3.extent(data));
                return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
            } else {
                return { data: [], colors: ['ffffff'], scale: this.state.object_color_scale };
            }
        }
        const data = this.props.infos.map((i) => i[mode]);
        let interpolator = function (t) {
            return d3.interpolateOrRd(t * 0.85 + 0.15);
        };
        if (mode == 'action') {
            const color_scale = d3.scaleOrdinal(d3.schemeSet3).domain(d3.extent(data));
            return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
        } else if (mode == 'episode index') {
            interpolator = d3.interpolateCool;
        }

        const color_scale = d3.scaleSequential(interpolator).domain(d3.extent(data));
        return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
    }

    getColorsForObjects() {
        return this.getColorData(this.state.object_layer_color_scale_mode);
    }

    getColorsForBackground() {
        return this.getColorData(this.state.background_layer_color_scale_mode);
    }

    getBackgroundLayerListItems() {
        return this.getLayerListItems(this.setBackgroundLayerColorMode, this.state.background_layer_color_scale_mode);
    }

    getObjectLayerListItems() {
        return this.getLayerListItems(this.setObjectLayerColorMode, this.state.object_layer_color_scale_mode);
    }

    getLayerListItems(f, activeState) {
        return ['none', 'step_reward'].concat(this.props.infoTypes).map((type, i) => {
            return (
                <ListGroup.Item
                    variant="flush"
                    onClick={f.bind(this, type)}
                    key={type}
                    eventKey={type}
                    active={activeState === type}
                >
                    {type}
                </ListGroup.Item>
            );
        });
    }

    setBackgroundLayerColorMode(mode) {
        this.setState({ background_layer_color_scale_mode: mode });
    }

    setObjectLayerColorMode(mode) {
        this.setState({ object_layer_color_scale_mode: mode });
    }

    updateColorLegend() {
        if (this.state.object_color_scale === undefined) return;
        d3.select(this.objectColorLegendRef.current).select('*').remove();
        d3.select(this.objectColorLegendRef.current)
            .node()
            .appendChild(
                legend({
                    color: this.state.object_color_scale,
                    width: this.objectColorLegendRef.current.parentElement.clientWidth,
                })
            );

        if (this.state.background_color_scale === undefined) return;
        d3.select(this.backgroundColorLegendRef.current).select('*').remove();
        d3.select(this.backgroundColorLegendRef.current)
            .node()
            .appendChild(
                legend({
                    color: this.state.background_color_scale,
                    width: this.backgroundColorLegendRef.current.parentElement.clientWidth,
                })
            );
    }

    splitArray(arr, indices) {
        var result = [];
        var lastIndex = 0;
        for (var i = 0; i < indices.length; i++) {
            // Note that the last observations of an episode is already from the next episode (i.e. the one give the done flag, so omit drawing the path)
            result.push(arr.slice(lastIndex, indices[i] + 1));
            lastIndex = indices[i] + 1;
        }
        result.push(arr.slice(Math.min(lastIndex, arr.length - 1)));
        return result;
    }

    // Source: https://stackoverflow.com/a/1484514
    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    // Source: https://stackoverflow.com/a/18473154
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    }

    // Source: https://stackoverflow.com/a/18473154
    describeArc(x, y, radius, startAngle, endAngle) {
        if (endAngle >= 360) {
            return (
                'M ' +
                (x - radius) +
                ', ' +
                y +
                ' a ' +
                radius +
                ',' +
                radius +
                ' 0 1,0 ' +
                radius * 2 +
                ',0' +
                ' a ' +
                radius +
                ',' +
                radius +
                ' 0 1,0 ' +
                -(radius * 2) +
                ',0'
            );
        }

        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

        return d;
    }

    updateChartColors(highlightSteps, visibleEpisodes) {
        this.updateColorLegend();
        const currentStep = highlightSteps.new.value;
        const svg = d3.select(this.embeddingRef.current);
        const x = this.state.x;
        const y = this.state.y;
        const numSteps = 30; // Number of steps in past and future trajectories
        let d = undefined;
        const scale = Math.round((5.0 / this.state.k) * 100) / 100;
        if (this.props.viewMode === 'transition_embedding') {
            d = this.state.transition_embedding[currentStep];
        } else {
            d = this.state.rawdat[currentStep];
        }

        if (d == undefined) return;

        const line = d3
            .line()
            .x((d) => x(d[0]))
            .y((d) => y(d[1]));

        // Draw past trajectory
        const pastData = this.state.rawdat.slice(Math.max(currentStep - numSteps, 0), currentStep + 1);
        svg.select('#past_trajectory')
            .datum(pastData)
            .attr('d', line)
            .attr('stroke', 'rgba(0, 0, 255, 0.5)') // Blue color for past trajectory
            .attr('stroke-width', 2)
            .attr('fill', 'none');

        // Draw future trajectory
        const futureData = this.state.rawdat.slice(currentStep, currentStep + numSteps + 1);
        svg.select('#future_trajectory')
            .datum(futureData)
            .attr('d', line)
            .attr('stroke', 'rgba(0, 255, 0, 0.5)') // Green color for future trajectory
            .attr('stroke-width', 2)
            .attr('fill', 'none');

        // Draw the circular step marker
        svg.select('#step_marker')
            .attr('d', circle_function(x(d[0]), y(d[1]), 3))
            .attr('transform', function () {
                const cx = x(d[0]);
                const cy = y(d[1]);
                return (
                    'matrix(' + scale + ', 0, 0, ' + scale + ', ' + (cx - scale * cx) + ', ' + (cy - scale * cy) + ')'
                );
            })
            .attr('fill', 'rgba(255, 50, 0, 0.5)')
            .attr('stroke', 'rgba(255, 50, 0, 0.8)');
    }

    toggleLasso() {
        if (this.state.draw_lasso) {
            d3.select(this.embeddingRef.current).select('svg').remove('lasso');
        } else if (this.lasso !== null) {
            d3.select(this.embeddingRef.current).select('svg').call(this.lasso);
        }
        this.setState({ draw_lasso: !this.state.draw_lasso });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataTimestamp !== prevProps.dataTimestamp) {
            this.loadData();
        }
        if (this.props.showSidebar !== prevProps.showSidebar) {
            this.resizeSVG();
        }
        if (
            this.props.dataTimestamp !== prevProps.dataTimestamp ||
            this.state.object_layer_color_scale_mode !== prevState.object_layer_color_scale_mode ||
            this.state.background_layer_color_scale_mode !== prevState.background_layer_color_scale_mode ||
            this.props.selectionTimestamp !== prevProps.selectionTimestamp
        ) {
            const objColorData = this.getColorsForObjects();
            const bgColorData = this.getColorsForBackground();
            const selected_points = this.props.infos.map((i) => i['selected']);
            const highlighted_points = this.props.infos.map((i) => i['highlighted']);
            this.setState(
                {
                    object_layer_colors: objColorData.colors,
                    background_layer_colors: bgColorData.colors,
                    object_color_scale: objColorData.scale,
                    background_color_scale: bgColorData.scale,
                    object_color_d3_scale: objColorData.d3_scale,
                    background_color_d3_scale: bgColorData.d3_scale,
                    highlighted: highlighted_points,
                    selected: selected_points,
                },
                () => {
                    this.updateColorLegend();
                    this.resizeSVG();
                    this.updateChartColors(this.props.highlightSteps, this.props.visibleEpisodes);
                }
            );
        }
        this.updateChartColors(this.props.highlightSteps, this.props.visibleEpisodes);
    }

    drawChart(
        viewMode = 'state_space',
        data = [],
        labels = [],
        merged_points = [],
        connections = [],
        feature_embeddings = [],
        transition_embeddings = [],
        actionData = [],
        doneData = [],
        labelInfos = [],
        annotationMode = 'analyze'
    ) {
        if (this.props.showModels) {
            // Slice data to length of infos
            data = data.slice(0, this.props.infos.length);
            data = data.filter((k, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            doneData = doneData.filter((k, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            labels = labels.slice(0, this.props.infos.length);
            labels = labels.filter((k, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            labelInfos = labelInfos.slice(0, this.props.infos.length);
            labelInfos = labelInfos.filter((k, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            actionData = actionData.filter((k, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            transition_embeddings = transition_embeddings.slice(0, this.props.infos.length);
            transition_embeddings = transition_embeddings.filter(
                (k, i) => this.props.showModels[this.props.infos[i]['model_index']]
            );
            feature_embeddings = feature_embeddings.slice(0, this.props.infos.length);
            feature_embeddings = feature_embeddings.filter(
                (k, i) => this.props.showModels[this.props.infos[i]['model_index']]
            );
        }

        switch (viewMode) {
            case 'state_space':
                this.drawStateSpace(data, labels, doneData, labelInfos, annotationMode);
                break;
            case 'decision_points':
                this.drawDecisionPoints(data, doneData, labels, merged_points, connections);
                break;
            case 'activation_mapping':
                this.drawActivationMapping(data, labels, feature_embeddings, doneData);
                break;
            case 'transition_embedding':
                this.drawTransitionEmbedding(transition_embeddings, labels, actionData, data, annotationMode);
                break;
            default:
                this.drawStateSpace(data, labels, doneData, labelInfos, annotationMode);
        }
    }

    drawStateSpace(data = [], labels = [], doneData = [], labelInfos = [], annotationMode = 'analyze') {
        const _self = this;
        this.lastIndex = data.length - 1;
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const done_idx = doneData.reduce((a, elem, i) => (elem === true && a.push(i), a), []);
        d3.select(this.embeddingRef.current).select('*').remove();
        const svgHeight = this.embeddingRef.current.parentElement.clientHeight;
        const svgWidth = this.embeddingRef.current.parentElement.clientWidth;
        if (svgWidth < 0 || svgHeight < 0) return;

        const is_one_d = false;
        if (data.length > 0 && data[0].length === 1) {
            // If embedding is 2D, add time (a.k.a. steps as the x dimension), first x is an array from 1 to length of data
            data = data.map((k, i) => [this.props.infos.map((v) => v['episode step'])[i], ...k]);
        }

        // Apend step to data
        data = data.map((k, i) => [...k, i]);

        const quadTree = d3.quadtree(
            data.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );

        const container = d3.select(this.embeddingRef.current);

        // Select the previous view (svg group with class view) and get current transform
        const prevView = container.select('.view');
        const prevTransform = prevView.node() !== null ? prevView.attr('transform') : null;

        // Remove all children of the container
        container.selectAll('*').remove();

        container.style('position', 'relative');
        const canvas = container.append('canvas').node();
        // Select canvas in d3
        const context = canvas.getContext('2d');

        canvas.width = svgWidth;
        canvas.height = svgHeight;

        const svg = container
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .style('position', 'absolute')
            .style('top', '0px')
            .style('left', '0px')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const xDomain = [d3.min(data.map((d) => d[0])), d3.max(data.map((d) => d[0]))];
        const xScale = d3.scaleLinear().domain(xDomain).range([0, svgWidth]);

        const yDomain = [d3.min(data.map((d) => d[1])), d3.max(data.map((d) => d[1]))];
        const yScale = d3.scaleLinear().range([svgHeight, 0]).domain(yDomain);

        Color2D.ranges = { x: xDomain, y: yDomain };

        const zoom = d3
            .zoom()
            .scaleExtent([0.2, 15])
            .translateExtent([
                [-600, -600],
                [svgWidth + 600, svgHeight + 600],
            ])
            .on('zoom', zoomed)
            .on('end', function (event) {
                _self.setState({ k: event.transform.k });
            });

        const view = svg.append('g').attr('class', 'view');

        // Apply previous transform to the view
        if (prevTransform) {
            view.attr('transform', prevTransform);
        }

        const view_rect = view
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', svgWidth)
            .style('opacity', '0');

        view_rect.on('mousemove', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                //_self.props.setHoverStep(_self.props.infos[closest[2]]);
            } else {
                //_self.props.setHoverStep(-1);
            }
        });
        view_rect.on('click', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                _self.props.setHoverStep(_self.props.infos[closest[2]]);
                _self.props.selectDatapoint(closest[2]);
            }
        });

        const lineFunction = d3
            .line()
            .curve(d3.curveCatmullRom)
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            });

        const splitData = this.splitArray(data, done_idx);

        const step_images = view
            .selectAll('image')
            .data(data.filter((d, i) => i % Math.floor(data.length / 30) === 0))
            .enter()
            .append('svg:image')
            .attr('x', (d) => xScale(d[0]))
            .attr('y', (d) => yScale(d[1]))
            .attr('width', 40)
            .attr('height', 48)
            .attr('class', 'datapoint_images')
            .attr('id', (d) => {
                return (
                    'datapoint_image_run_' +
                    this.props.infos[d[2]]['model_index'] +
                    '_' +
                    this.props.infos[d[2]]['model_data_step']
                );
            })
            .attr('opacity', 0.8)
            .attr('display', 'none')
            .attr(
                'xlink:href',
                (d) =>
                    '/data/get_single_obs?step=' +
                    this.props.infos[d[2]]['episode step'] +
                    '&gym_registration_id=' +
                    this.props.benchmarkedModels[this.props.infos[d[2]]['model_index']].gym_registration_id +
                    '&benchmark_type=' +
                    this.props.benchmarkedModels[this.props.infos[d[2]]['model_index']].benchmark_type +
                    '&benchmark_id=' +
                    this.props.benchmarkedModels[this.props.infos[d[2]]['model_index']].benchmark_id +
                    '&checkpoint_step=' +
                    this.props.benchmarkedModels[this.props.infos[d[2]]['model_index']].checkpoint_step +
                    '&episode_id=' +
                    this.props.infos[d[2]]['model_episode'] +
                    '&type=render&rdn=' +
                    Math.random()
            );

        const label_data_map = new Map();
        const start_label_data = [0].concat(done_idx.slice(0, -1).map((d) => d + 1));

        for (let i = 0; i < start_label_data.length; i++) {
            label_data_map.set(start_label_data[i], ['Start']);
        }

        for (let i = 0; i < done_idx.length; i++) {
            if (!label_data_map.has(done_idx[i])) label_data_map.set(done_idx[i], ['Done']);
            else label_data_map.get(done_idx[i]).push('Done');
        }

        for (let i = 0; i < labelInfos.length; i++) {
            const label = labelInfos[i].label;
            const ids = labelInfos[i].ids;

            for (let j = 0; j < ids.length; j++) {
                if (!label_data_map.has(ids[j])) label_data_map.set(ids[j], [label]);
                else label_data_map.get(ids[j]).push(label);
            }
        }

        const text_labels = view
            .selectAll('label-g')
            .data(data.map((d, i) => [d[0], d[1], i]).filter((d) => label_data_map.has(d[2])))
            .enter()
            .append('g')
            .attr('class', 'label-g')
            .attr('id', (d) => 'label-g_' + d[2]);

        _self.updateColorLegend();

        text_labels
            .append('text')
            .attr('class', 'label')
            .attr('x', (d) => xScale(d[0]) + 10)
            .attr('y', (d) => yScale(d[1]) + 10)
            .attr('text-anchor', 'center')
            .text((d) => label_data_map.get(d[2]).join('/'));

        text_labels
            .append('line')
            .attr('class', 'label-line')
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('x1', (d) => xScale(d[0]))
            .attr('y1', (d) => yScale(d[1]))
            .attr('x2', (d) => xScale(d[0]) + 10)
            .attr('y2', (d) => yScale(d[1]) + 10)
            .attr('stroke', '#a1a1a1');

        // Prepare a color palette
        const color = d3.scaleSequential(d3.interpolateGreys).domain([0, 0.05]);

        const densityData = d3
            .contourDensity()
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            })
            .size([svgWidth, svgHeight])
            .bandwidth(30)(data.map((d, i) => [d[0], d[1], i]));

        /*if (this.state.background_layer_color_scale_mode !== "none") {
            const background_layer = view.insert('g', 'g')

            background_layer.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', svgWidth)
                .attr('height', svgHeight)
                .attr('fill', 'none')

            background_layer.selectAll('rect')
                .data(data)
                .enter().append("circle")
                .attr("class", evalstyle.blurr_circle + " bg_circle")
                .attr("cx", d => xScale(d[0]))
                .attr("cy", d => yScale(d[1]))
                .attr("fill", (_, i) => this.state.background_layer_colors[i])
                .attr("r", 8)
        }*/

        if (annotationMode === 'annotate') {
            const unique_labels = new Set(labels);
            // For each labeled cluster, draw a convex hull
            for (const label of unique_labels) {
                if (label === -1) continue;
                const label_g = view.append('g').attr('class', 'label-g');
                const cluster_indices = data
                    .map((element, index) => {
                        if (labels[index] === label) {
                            return index;
                        }
                    })
                    .filter((element) => element >= 0);
                const cluster_data = data.filter((_, i) => labels[i] === label);
                const hull = d3.polygonHull(cluster_data.map((d) => [xScale(d[0]), yScale(d[1])]));

                // Add label text in the center of the convex hull
                const center = d3.polygonCentroid(hull);
                label_g
                    .append('path')
                    .attr('d', 'M' + hull.join('L') + 'Z')
                    .attr('fill', Color2D.getColor(xScale.invert(center[0]), yScale.invert(center[1])))
                    .style('opacity', 0.4)
                    .on('mouseover', function (d) {
                        d3.select(this).style('opacity', 0.6);
                    })
                    .on('mouseout', function (d) {
                        d3.select(this).style('opacity', 0.4);
                    })
                    .on('click', function (d) {
                        d3.select(this).style('opacity', 0.7);
                        // Open text edit field to change label
                        const new_label = prompt('Please enter a new label', '');
                        if (new_label !== null) {
                            // Update label
                            this_label_text.text(new_label);
                        }
                        _self.props.annotateState(cluster_indices, new_label, label);
                    });

                // Check if label in props.annotationSets, if so, use the label in props.annotated_sets
                const label_text = label in _self.props.annotationSets ? _self.props.annotationSets[label] : label;

                const this_label_text = label_g
                    .append('text')
                    .attr('class', 'label')
                    .attr('font-size', '20px')
                    .attr('font-weight', 'bold')
                    .attr('x', center[0])
                    .attr('y', center[1])
                    .attr('text-anchor', 'center')
                    .text(label_text);
            }
        } else {
            const unique_labels = new Set(labels);
            // For each labeled cluster, draw a convex hull
            for (const label of unique_labels) {
                if (!(label in _self.props.annotationSets)) continue;
                const label_g = view.append('g').attr('class', 'label-g');
                const cluster_data = data.filter((_, i) => labels[i] === label);
                const hull = d3.polygonHull(cluster_data.map((d) => [xScale(d[0]), yScale(d[1])]));

                if (hull === undefined) continue;

                // Add label text in the center of the convex hull
                const center = d3.polygonCentroid(hull);

                const label_text = label in _self.props.annotationSets ? _self.props.annotationSets[label] : label;

                label_g
                    .append('text')
                    .attr('class', 'label')
                    .attr('font-size', '20px')
                    .attr('font-weight', 'bold')
                    .attr('x', center[0] + 35)
                    .attr('y', center[1] + 35)
                    .attr('text-anchor', 'center')
                    .text(label_text);
            }
        }

        const step_marker_group = view.append('g');

        step_marker_group
            .append('path')
            .datum(data[0])
            .attr('d', (d) => circle_function())
            .attr('fill-opacity', 0.5)
            .attr('fill', '#ff3737')
            .attr('stroke', '#ff3737')
            .attr('id', 'step_marker');

        step_marker_group.append('path').attr('id', 'past_trajectory');

        step_marker_group.append('path').attr('id', 'future_trajectory');

        const dataLength = splitData.length;

        function zoomed(event) {
            event = event.transform;

            view.attr('transform', event);

            const r = Math.round((5 / event.k) * 100) / 100;
            const width = Math.round((1 / event.k) * 100) / 100;

            context.save();
            context.clearRect(0, 0, svgWidth, svgHeight);
            context.translate(event.x, event.y);
            context.scale(event.k, event.k);

            densityData.forEach((d) => {
                context.beginPath();
                // path(contours.thresholds(d3.range(0, 60, 10))(data));
                d3.geoPath().context(context)(d);
                //context.stroke();
                context.fillStyle = color(d.value);
                context.fill();
            });

            // Draw the paths on canvas with the line function
            if (_self.state.object_layer_color_scale_mode !== 'none') {
                splitData.forEach((d, i) => {
                    if (d.length === 0) return;

                    context.strokeStyle = d3.interpolateCool(i / dataLength);
                    context.lineWidth = width;
                    context.beginPath();

                    context.moveTo(xScale(d[0][0]), yScale(d[0][1]));
                    for (let j = 1; j < d.length; j++) {
                        context.lineTo(xScale(d[j][0]), yScale(d[j][1]));
                    }
                    context.stroke();
                });
                for (const [x, y, i] of data.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                    if (!_self.state.selected[i]) continue;

                    context.beginPath();
                    context.moveTo(x + r, y);
                    // If highlighted, draw recangle
                    if (_self.state.highlighted[i]) {
                        context.rect(x - r, y - r, 2 * r, 2 * r);
                        context.lineWidth = 1 / event.k;
                        context.strokeStyle = '#000000';
                        context.stroke();
                    } else {
                        context.arc(x, y, r, 0, 2 * Math.PI);
                    }

                    context.fillStyle = _self.state.object_layer_colors[i];
                    // opacity
                    context.globalAlpha = 0.5;
                    context.fill();
                    context.closePath();
                }
            }
            context.restore();

            if (event.k > 3.0) {
                step_images.attr('display', 'inline').style('opacity', 0.5 + event.k / 10);
            } else if (event.k < 3.0) {
                step_images.attr('display', 'none');
            }
            // Check if zoomed in enough to show labels, also check if labels were previously shown
            if (event.k > 1.5 && text_labels.attr('display') === 'none') {
                text_labels.attr('display', 'inline');
            } else if (event.k < 1.5) {
                text_labels.attr('display', 'none');
            }
            text_labels.selectAll('text').attr('font-size', 16 / event.k);

            step_images
                .attr('opacity', Math.min(0.3 * event.k, 0.9))
                .attr('width', 100 / event.k)
                .attr('height', 100 / event.k);
        }

        svg.call(zoom);

        // Call the zoom function to update the view
        zoomed({ transform: d3.zoomIdentity });

        this.setState({ x: xScale, y: yScale });
    }

    drawDecisionPoints(data = [], doneData = [], labels = [], merged_points = [], connections = []) {
        const _self = this;
        this.lastIndex = data.length - 1;
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        d3.select(this.embeddingRef.current).select('*').remove();
        const done_idx = doneData.reduce((a, elem, i) => (elem === true && a.push(i), a), []);
        const svgHeight = this.embeddingRef.current.parentElement.clientHeight;
        const svgWidth = this.embeddingRef.current.parentElement.clientWidth;
        if (svgWidth < 0 || svgHeight < 0) return;

        const max_value = d3.max(merged_points, (d) => d[2]);

        let is_one_d = false;
        if (data.length > 0 && data[0].length === 1) {
            // Check if embedding data is 1D or 2D
            is_one_d = true;
            // If embedding is 2D, add time (a.k.a. steps as the x dimension), first x is an array from 1 to length of data
            data = data.map((k, i) => [this.props.infos.map((v) => v['episode step'])[i], ...k]);
        }

        const quadTree = d3.quadtree(
            data.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );

        const container = d3.select(this.embeddingRef.current);

        // Remove all children of the container
        container.selectAll('*').remove();

        container.style('position', 'relative');
        const canvas = container.append('canvas').node();
        // Select canvas in d3
        const context = canvas.getContext('2d');

        canvas.width = svgWidth;
        canvas.height = svgHeight;

        const svg = container
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .style('position', 'absolute')
            .style('top', '0px')
            .style('left', '0px')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const xDomain = [d3.min(merged_points.map((d) => d[0])), d3.max(merged_points.map((d) => d[0]))];
        const xScale = d3.scaleLinear().domain(xDomain).range([0, svgWidth]);

        const yDomain = [d3.min(merged_points.map((d) => d[1])), d3.max(merged_points.map((d) => d[1]))];
        const yScale = d3.scaleLinear().range([svgHeight, 0]).domain(yDomain);

        Color2D.ranges = { x: xDomain, y: yDomain };

        const zoom = d3
            .zoom()
            .scaleExtent([0.2, 15])
            .translateExtent([
                [-600, -600],
                [svgWidth + 600, svgHeight + 600],
            ])
            .on('zoom', zoomed)
            .on('end', function (event) {
                _self.setState({ k: event.transform.k });
            });

        const view = svg.append('g').attr('class', 'view');

        const view_rect = view
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', svgWidth)
            .style('opacity', '0');

        /*view_rect.on('mousemove', function (event) {

            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                //_self.props.setHoverStep(_self.props.infos[closest[2]]);
            } else {
                //_self.props.setHoverStep(-1);
            }

            //zoomed({ transform: d3.zoomIdentity.scale(1) });

        })*/
        view_rect.on('click', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                _self.props.setHoverStep(_self.props.infos[closest[2]]);
                _self.props.selectDatapoint(closest[2]);
            }
        });

        const lineFunction = d3
            .line()
            .curve(d3.curveCatmullRom)
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            });

        const step_marker_group = view.append('g');

        step_marker_group
            .append('path')
            .datum(data[0])
            .attr('d', (d) => circle_function())
            .attr('fill-opacity', 0.5)
            .attr('fill', '#ff3737')
            .attr('stroke', '#ff3737')
            .attr('id', 'step_marker');

        step_marker_group.append('path').attr('id', 'past_trajectory');

        step_marker_group.append('path').attr('id', 'future_trajectory');

        const radius_log = d3.scaleLog().domain([1, max_value]).range([1, 2]);

        const min_merged_points = d3.min(merged_points.map((d) => d[5]));
        const max_merged_points = d3.max(merged_points.map((d) => d[5]));
        // Step Function as color scale: If 0 then 0.25, if 1 then 0.5, if 2 then 0.75, if >2 then 1
        const merged_points_color = function (d) {
            const color_scale = d3.scaleSequential(d3.interpolateOrRd).domain([0, 1]);
            if (d < 1) return 'grey';
            else if (d == 1) return color_scale(0.5);
            else if (d == 2) return color_scale(0.75);
            else if (d == 3) return color_scale(1);
            else return '#gg0000';
        };

        // Prepare a color palette
        const color = d3.scaleSequential(d3.interpolateGreys).domain([0, 0.05]);

        const densityData = d3
            .contourDensity()
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            })
            .size([svgWidth, svgHeight])
            .bandwidth(30)(data.map((d, i) => [d[0], d[1], i]));

        const unique_labels = new Set(labels);
        // For each labeled cluster, draw a convex hull
        for (const label of unique_labels) {
            if (!(label in _self.props.annotationSets)) continue;
            const label_g = view.append('g').attr('class', 'label-g');
            const cluster_data = data.filter((_, i) => labels[i] === label);
            const hull = d3.polygonHull(cluster_data.map((d) => [xScale(d[0]), yScale(d[1])]));

            if (hull === undefined) continue;

            // Add label text in the center of the convex hull
            const center = d3.polygonCentroid(hull);

            const label_text = label in _self.props.annotationSets ? _self.props.annotationSets[label] : label;

            label_g
                .append('text')
                .attr('class', 'label')
                .attr('font-size', '20px')
                .attr('font-weight', 'bold')
                .attr('x', center[0] + 35)
                .attr('y', center[1] + 35)
                .attr('text-anchor', 'center')
                .text(label_text);
        }

        const splitData = this.splitArray(data, done_idx);
        const dataLength = splitData.length;

        const max_line_value = d3.max(connections.map((d) => d[2]));
        const line_width_log = d3.scaleLog().domain([1, max_line_value]).range([1, 4]);
        const line_color_scale = d3.scaleSequential(d3.interpolateViridis).domain([0, max_line_value]);

        /*
        for (const [start, end, value] of connections) {
                const start_point = merged_points[start];
                const end_point = merged_points[end];
                context.beginPath();
                context.moveTo(xScale(start_point[0]), yScale(start_point[1]));
                context.lineTo(xScale(end_point[0]), yScale(end_point[1]));
                context.lineWidth = line_width_log(value);
                context.strokeStyle = line_color_scale(value);
                context.stroke();
            }
        */

        const line_group = view.append('g').attr('class', 'line-group');
        line_group
            .selectAll('path')
            .data(connections)
            .enter()
            .append('path')
            .attr('class', 'line')
            .attr('d', (d) => {
                const start_point = merged_points[d[0]];
                const end_point = merged_points[d[1]];
                return lineFunction([start_point, end_point]);
            })
            .attr('stroke', (d) => line_color_scale(d[2]))
            .attr('stroke-width', (d) => line_width_log(d[2]))
            .attr('fill', 'none');

        function zoomed(event) {
            event = event.transform;

            view.attr('transform', event);

            const width = Math.round((1 / event.k) * 100) / 100;

            context.save();
            context.clearRect(0, 0, svgWidth, svgHeight);
            context.translate(event.x, event.y);
            context.scale(event.k, event.k);

            densityData.forEach((d) => {
                context.beginPath();
                // path(contours.thresholds(d3.range(0, 60, 10))(data));
                d3.geoPath().context(context)(d);
                //context.stroke();
                context.fillStyle = color(d.value);
                context.fill();
            });

            // Draw the merged points
            const base_radius = Math.round((5 / event.k) * 100) / 100;
            for (const [x, y, r, c, i] of merged_points.map((d, i) => [xScale(d[0]), yScale(d[1]), d[2], d[5], i])) {
                context.beginPath();
                context.moveTo(x + radius_log(r) * base_radius, y);
                context.arc(x, y, radius_log(r) * base_radius, 0, 2 * Math.PI);
                context.fillStyle = merged_points_color(c);
                // opacity
                context.globalAlpha = 0.95;
                context.fill();
                context.closePath();
            }

            splitData.forEach((d, i) => {
                    if (d.length === 0) return;

                    context.strokeStyle = d3.interpolateCool(i / dataLength);
                    context.lineWidth = width;
                    context.beginPath();

                    context.moveTo(xScale(d[0][0]), yScale(d[0][1]));
                    for (let j = 1; j < d.length; j++) {
                        context.lineTo(xScale(d[j][0]), yScale(d[j][1]));
                    }
                    context.stroke();
            });
            context.restore();
        }

        svg.call(zoom);

        // Call the zoom function to update the view
        zoomed({ transform: d3.zoomIdentity });

        this.setState({ x: xScale, y: yScale });
    }

    drawActivationMapping(data = [], labels = [], feature_embeddings = [], doneData = []) {
        const _self = this;
        this.lastIndex = data.length - 1;
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const done_idx = doneData.reduce((a, elem, i) => (elem === true && a.push(i), a), []);
        d3.select(this.embeddingRef.current).select('*').remove();
        const svgHeight = this.embeddingRef.current.parentElement.clientHeight;
        const svgWidth = this.embeddingRef.current.parentElement.clientWidth;
        if (svgWidth < 0 || svgHeight < 0) return;

        let is_one_d = false;
        if (data.length > 0 && data[0].length === 1) {
            // Check if embedding data is 1D or 2D
            is_one_d = true;
            // If embedding is 2D, add time (a.k.a. steps as the x dimension), first x is an array from 1 to length of data
            data = data.map((k, i) => [this.props.infos.map((v) => v['episode step'])[i], ...k]);
        }

        const quadTree = d3.quadtree(
            data.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );
        const featureQuadTree = d3.quadtree(
            feature_embeddings.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );

        feature_embeddings = feature_embeddings.map((e, i) => [e[0] + 1, e[1], i]);

        const container = d3.select(this.embeddingRef.current);

        // Remove all children of the container
        container.selectAll('*').remove();

        container.style('position', 'relative');
        const canvas = container.append('canvas').node();
        // Select canvas in d3
        const context = canvas.getContext('2d');

        canvas.width = svgWidth;
        canvas.height = svgHeight;

        const svg = container
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .style('position', 'absolute')
            .style('top', '0px')
            .style('left', '0px')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const xDomain = [0, 2];
        const xScale = d3.scaleLinear().domain(xDomain).range([0, svgWidth]);

        const yDomain = [0, 1];
        const yScale = d3.scaleLinear().range([svgHeight, 0]).domain(yDomain);

        Color2D.ranges = { x: xDomain, y: yDomain };

        const zoom = d3
            .zoom()
            .scaleExtent([0.2, 15])
            .translateExtent([
                [-600, -600],
                [svgWidth + 600, svgHeight + 600],
            ])
            .on('zoom', zoomed)
            .on('end', function (event) {
                _self.setState({ k: event.transform.k });
            })
            .filter(function (event) {
                return !event.ctrlKey && !event.metaKey;
            });

        // Add rectangluar brush to select states
        const brush = d3
            .brush()
            .extent([
                [0, 0],
                [svgWidth, svgHeight],
            ])
            .filter(function (event) {
                return (event.ctrlKey || event.metaKey) && event.type === 'mousedown';
            })
            .on('start', brushStart)
            .on('end', brushEnd);

        function brushStart() {
            // Clear the brush
            svg.selectAll('.brush').call(brush.move, null);
        }

        function brushEnd(event) {
            const selection = event.selection;

            const selected = [];
            if (selection) {
                let [[xmin, ymin], [xmax, ymax]] = selection;
                xmin = xScale.invert(xmin);
                xmax = xScale.invert(xmax);
                ymin = yScale.invert(ymin);
                ymax = yScale.invert(ymax);
                quadTree.visit((node, x1, y1, x2, y2) => {
                    if (!node.length) {
                        do {
                            const d = node.data;
                            if (d[0] >= xmin && d[0] < xmax && d[1] <= ymin && d[1] > ymax) {
                                selected.push(d);
                            }
                        } while ((node = node.next));
                    }
                    return;
                });
            }
            _self.props.highlightIndices(selected.map((d) => d[2]));

            // Clear the brush
            svg.selectAll('.brush').call(brush.move, null);
        }

        const view = svg.append('g').attr('class', 'view');

        const view_rect = view
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', svgWidth)
            .style('opacity', '0');

        // Draw a vertical line to separate the two canvases
        view.append('line')
            .attr('x1', svgWidth / 2)
            .attr('y1', 0)
            .attr('x2', svgWidth / 2)
            .attr('y2', svgHeight)
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        //In the middle of the left half, write "Observations" on top
        view.append('text')
            .attr('x', svgWidth / 4)
            .attr('y', 20)
            .attr('text-anchor', 'middle')
            .attr('font-size', '20px')
            .attr('font-weight', 'bold')
            .text('Observations');

        //In the middle of the right half, write "Latents" on top
        view.append('text')
            .attr('x', (svgWidth / 4) * 3)
            .attr('y', 20)
            .attr('text-anchor', 'middle')
            .attr('font-size', '20px')
            .attr('font-weight', 'bold')
            .text('Latents');

        view_rect.on('click', function (event) {
            if (event.ctrlKey || event.metaKey) {
                // If ctrl is pressed, clear the selection
                return;
            }

            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            let closest = null;
            if (xClicked < 1) closest = quadTree.find(xClicked, yClicked, 10);
            else closest = featureQuadTree.find(xClicked - 1.0, yClicked, 10);

            if (closest) {
                _self.props.setHoverStep(_self.props.infos[closest[2]]);
                _self.props.selectDatapoint(closest[2]);
            }
        });

        _self.updateColorLegend();

        const unique_labels = new Set(labels);
        // For each labeled cluster, draw a convex hull
        for (const label of unique_labels) {
            if (!(label in _self.props.annotationSets)) continue;
            const label_g = view.append('g').attr('class', 'label-g');
            const cluster_data = data.filter((_, i) => labels[i] === label);
            const hull = d3.polygonHull(cluster_data.map((d) => [xScale(d[0]), yScale(d[1])]));

            if (hull === undefined) continue;

            // Add label text in the center of the convex hull
            const center = d3.polygonCentroid(hull);

            const label_text = label in _self.props.annotationSets ? _self.props.annotationSets[label] : label;

            label_g
                .append('text')
                .attr('class', 'label')
                .attr('font-size', '20px')
                .attr('font-weight', 'bold')
                .attr('x', center[0] + 35)
                .attr('y', center[1] + 35)
                .attr('text-anchor', 'center')
                .text(label_text);
        }

        const step_marker_group = view.append('g');

        step_marker_group
            .append('path')
            .datum(data[0])
            .attr('d', (d) => circle_function())
            .attr('fill-opacity', 0.5)
            .attr('fill', '#ff3737')
            .attr('stroke', '#ff3737')
            .attr('id', 'step_marker');

        step_marker_group.append('path').attr('id', 'past_trajectory');

        step_marker_group.append('path').attr('id', 'future_trajectory');

        // Draw SVG lines that connext points from the left and right canvas, right points are shifted by svgWidth / 2
        const lines = view
            .selectAll('line')
            .data(data.map((d, i) => [d[0], d[1], i]))
            .enter()
            .append('line')
            .attr('x1', (d) => xScale(d[0]))
            .attr('y1', (d) => yScale(d[1]))
            .attr('x2', (d) => xScale(feature_embeddings[d[2]][0]))
            .attr('y2', (d) => yScale(feature_embeddings[d[2]][1]))
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .attr('stroke-opacity', 0.5)
            .attr('id', (d) => 'line_' + d[2])
            .style('visibility', 'hidden');

        function zoomed(event) {
            event = event.transform;

            view.attr('transform', event);

            const r = Math.round((5 / event.k) * 100) / 100;
            const width = Math.round((1 / event.k) * 100) / 100;

            context.save();
            context.clearRect(0, 0, svgWidth, svgHeight);
            context.translate(event.x, event.y);
            context.scale(event.k, event.k);

            for (const [x, y, i] of data.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                context.beginPath();
                context.moveTo(x + r, y);
                if (_self.state.highlighted[i]) {
                    context.rect(x - r, y - r, 2 * r, 2 * r);
                    context.lineWidth = 1 / event.k;
                    context.strokeStyle = '#000000';
                    context.stroke();
                } else {
                    context.arc(x, y, r, 0, 2 * Math.PI);
                }
                context.fillStyle = _self.state.object_layer_colors[i];
                // opacity
                context.globalAlpha = 0.5;
                context.fill();
                context.closePath();
            }

            for (const [x, y, i] of feature_embeddings.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                context.beginPath();
                context.moveTo(x + r, y);
                if (_self.state.highlighted[i]) {
                    context.rect(x - r, y - r, 2 * r, 2 * r);
                    context.lineWidth = 1 / event.k;
                    context.strokeStyle = '#000000';
                    context.stroke();
                } else {
                    context.arc(x, y, r, 0, 2 * Math.PI);
                }
                context.fillStyle = _self.state.highlighted[i] ? _self.state.object_layer_colors[i] : '#cccccc';
                // opacity
                context.globalAlpha = 0.5;
                context.fill();
                context.closePath();
            }
            context.restore();

            lines.style('visibility', (d) => (_self.state.highlighted[d[2]] ? 'visible' : 'hidden'));
            lines.attr('stroke', (d) =>
                _self.state.highlighted[d[2]] ? _self.state.object_layer_colors[d[2]] : '#cccccc'
            );

            //text_labels.selectAll('text').attr('font-size', 16 / event.k);
        }

        //svg.call(brush);
        svg.call(zoom);

        // Call the zoom function to update the view
        zoomed({ transform: d3.zoomIdentity });

        this.setState({ x: xScale, y: yScale });
    }

    drawTransitionEmbedding(transition_embeddings, labels = [], actions, data, annotationMode = 'analyze') {
        const _self = this;
        this.lastIndex = transition_embeddings.length - 1;
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        d3.select(this.embeddingRef.current).select('*').remove();
        const svgHeight = this.embeddingRef.current.parentElement.clientHeight;
        const svgWidth = this.embeddingRef.current.parentElement.clientWidth;
        if (svgWidth < 0 || svgHeight < 0) return;

        // Set object layer colors to action
        this.setObjectLayerColorMode('action');

        let is_one_d = false;
        if (transition_embeddings.length > 0 && transition_embeddings[0].length === 1) {
            // Check if embedding data is 1D or 2D
            is_one_d = true;
            // If embedding is 2D, add time (a.k.a. steps as the x dimension), first x is an array from 1 to length of data
            transition_embeddings = transition_embeddings.map((k, i) => [
                this.props.infos.map((v) => v['episode step'])[i],
                ...k,
            ]);
        }

        const quadTree = d3.quadtree(
            transition_embeddings.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );

        const container = d3.select(this.embeddingRef.current);

        // Remove all children of the container
        container.selectAll('*').remove();

        container.style('position', 'relative');
        const canvas = container.append('canvas').node();
        // Select canvas in d3
        const context = canvas.getContext('2d');

        canvas.width = svgWidth;
        canvas.height = svgHeight;

        const svg = container
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .style('position', 'absolute')
            .style('top', '0px')
            .style('left', '0px')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const xDomain = [
            d3.min(transition_embeddings.map((d) => d[0])),
            d3.max(transition_embeddings.map((d) => d[0])),
        ];
        const xScale = d3.scaleLinear().domain(xDomain).range([0, svgWidth]);

        const yDomain = [
            d3.min(transition_embeddings.map((d) => d[1])),
            d3.max(transition_embeddings.map((d) => d[1])),
        ];
        const yScale = d3.scaleLinear().range([svgHeight, 0]).domain(yDomain);

        Color2D.ranges = { x: xDomain, y: yDomain };

        const zoom = d3
            .zoom()
            .scaleExtent([0.2, 15])
            .translateExtent([
                [-600, -600],
                [svgWidth + 600, svgHeight + 600],
            ])
            .on('zoom', zoomed)
            .on('end', function (event) {
                _self.setState({ k: event.transform.k });
            });

        const view = svg.append('g').attr('class', 'view');
        const view_rect = view
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', svgWidth)
            .style('opacity', '0');

        view_rect.on('click', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                _self.props.setHoverStep(_self.props.infos[closest[2]]);
                _self.props.selectDatapoint(closest[2]);
            }
        });

        const lineFunction = d3
            .line()
            .curve(d3.curveCatmullRom)
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            });

        const step_marker_group = view.append('g');

        step_marker_group
            .append('path')
            .datum(data[0])
            .attr('d', (d) => circle_function())
            .attr('fill-opacity', 0.5)
            .attr('fill', '#ff3737')
            .attr('stroke', '#ff3737')
            .attr('id', 'step_marker');

        step_marker_group.append('path').attr('id', 'past_trajectory');

        step_marker_group.append('path').attr('id', 'future_trajectory');

        const action_color_scale = d3.scaleOrdinal(d3.schemeSet3).domain(d3.extent(actions));

        // Draw SVG lines that connext points from the left and right canvas, right points are shifted by svgWidth / 2
        const lines = view
            .selectAll('line')
            .data(data.map((d, i) => [d[0], d[1], i]))
            .enter()
            .append('line')
            .attr('x1', (d) => xScale(d[0]))
            .attr('y1', (d) => yScale(d[1]))
            .attr('x2', (d) => xScale(transition_embeddings[d[2]][0]))
            .attr('y2', (d) => yScale(transition_embeddings[d[2]][1]))
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .attr('stroke-opacity', 0.5)
            .attr('id', (d) => 'line_' + d[2])
            .style('visibility', 'hidden');

        function zoomed(event) {
            event = event.transform;

            view.attr('transform', event);

            context.save();
            context.clearRect(0, 0, svgWidth, svgHeight);
            context.translate(event.x, event.y);
            context.scale(event.k, event.k);

            // Draw the merged points
            const base_radius = Math.round((10 / event.k) * 100) / 100;

            // For each transition, draw a small diamond glyph, map the angle to the action
            for (const [x, y, i] of transition_embeddings.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                context.beginPath();
                context.moveTo(x, y - base_radius * 0.75);
                context.lineTo(x + base_radius * 0.75, y);
                context.lineTo(x, y + base_radius * 0.75);
                context.lineTo(x - base_radius * 0.75, y);
                context.globalAlpha = 0.75;
                context.closePath();
                context.lineWidth = 1 / event.k;
                // If highlighted, draw recangle
                if (_self.state.highlighted[i]) {
                    context.lineWidth = 3 / event.k;
                } else {
                    context.lineWidth = 1 / event.k;
                }
                context.strokeStyle = '#000000';
                context.stroke();
                context.fillStyle = action_color_scale(actions[i]);
                context.fill();
            }

            // Draw original data points as smaller oqaue circles
            const base_radius2 = Math.round((3 / event.k) * 100) / 100;
            context.globalAlpha = 0.5;
            for (const [x, y, i] of data.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                context.beginPath();
                // If highlighted, draw recangle
                if (_self.state.highlighted[i]) {
                    context.rect(x - base_radius2, y - base_radius2, 2 * base_radius2, 2 * base_radius2);
                    context.lineWidth = 1 / event.k;
                    context.strokeStyle = '#000000';
                    context.stroke();
                } else {
                    context.arc(x, y, base_radius2, 0, 2 * Math.PI);
                }
                context.fillStyle = '#cccccc';
                context.fill();
                context.closePath();
            }
            context.restore();

            lines.style('visibility', (d) => (_self.state.highlighted[d[2]] ? 'visible' : 'hidden'));
            lines.attr('stroke', (d) =>
                _self.state.highlighted[d[2]] ? _self.state.object_layer_colors[d[2]] : '#cccccc'
            );
        }

        svg.call(zoom);

        // Call the zoom function to update the view
        zoomed({ transform: d3.zoomIdentity });

        this.setState({ x: xScale, y: yScale });
    }

    render() {
        const modes = [
            { name: 'Analyze', value: 'analzye' },
            { name: 'Annotate', value: 'annotate' },
        ];

        const views = [
            { name: 'State Space', value: 'state_space' },
            { name: 'Decision Points', value: 'decision_points' },
            { name: 'Activation Mapping', value: 'activation_mapping' },
            { name: 'Transition Embedding', value: 'transition_embedding' },
        ];

        return (
            <div className={evalstyle.embedding_wrapper_div}>
                <div ref={this.embeddingRef}></div>
                <div className={evalstyle.embedding_control_wrapper_div} style={{ width: 'max(30%, 850px)' }}>
                    <div className={`${evalstyle.control_overlay_div} ${evalstyle.box_container}`}>
                        <div>
                            {views.map((view, i) => (
                                <div
                                    className={evalstyle.box}
                                    key={i}
                                    style={{
                                        backgroundImage: 'url(' + this.state.step_images[i] + ')',
                                        borderWidth: this.props.viewMode === view.value ? '5px' : '1px',
                                    }}
                                    onClick={() => this.props.setViewMode(view.value, this.resizeSVG.bind(this))}
                                >
                                    {view.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={evalstyle.control_overlay_div} style={{ marginTop: 10 }}>
                        <ButtonGroup>
                            {modes.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-mode-${idx}`}
                                    type="radio"
                                    variant={
                                        this.props.annotationMode === radio.value ? 'secondary' : 'outline-secondary'
                                    }
                                    name="radio"
                                    value={radio.value}
                                    checked={this.props.annotationMode === radio.value}
                                    onChange={(e) =>
                                        this.props.setAnnotationMode(e.currentTarget.value, this.resizeSVG.bind(this))
                                    }
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Accordion alwaysOpen={true} className={evalstyle.control_overlay_div}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Object Layer</Accordion.Header>
                                <Accordion.Body style={{ padding: '0' }}>
                                    <ListGroup
                                        variant="flush"
                                        style={{ maxHeight: '250px', cursor: 'pointer', overflowY: 'scroll' }}
                                    >
                                        {this.getObjectLayerListItems()}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Background Layer</Accordion.Header>
                                <Accordion.Body style={{ padding: '0' }}>
                                    <ListGroup
                                        variant="flush"
                                        style={{ maxHeight: '250px', cursor: 'pointer', overflowY: 'scroll' }}
                                    >
                                        {this.getBackgroundLayerListItems()}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div
                        className={evalstyle.control_overlay_div}
                        style={{ position: 'absolute', left: 15, top: '57vh' }}
                    >
                        <div>
                            <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '0' }}>
                                Background Color Scale: {this.state.background_layer_color_scale_mode}
                            </p>
                            <div ref={this.backgroundColorLegendRef}></div>
                        </div>
                    </div>
                    <div
                        className={evalstyle.control_overlay_div}
                        style={{ position: 'absolute', right: 0, top: '57vh' }}
                    >
                        <div>
                            <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '0' }}>
                                Object Color Scale: {this.state.object_layer_color_scale_mode}
                            </p>
                            <div ref={this.objectColorLegendRef}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
