import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Ranking_Embedding from './Ranking_Embedding';
import Ranking_List from './Ranking_List';
import Ranking_Sidebar from './Ranking_Sidebar';
import rankingstyle from '../css/Ranking.module.css';

/**
 * Main component of the setup page. No shared state to other parts of the application.
 * Contains references to all submodules such as the task selection view, environment benchmark view,
 * datset explorer etc.
 */
export default class ranking_main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: new Map(),
            datasets: [],
            current_benchmark_results: {
                obs: [],
                rews: [],
                dones: [],
                actions: [],
                infos: [{ dummy: 0 }],
                done_indices: [],
                label_infos: [],
                attr: [],
                probabilities: [],
                episode_lengths: [],
                episode_rewards: [],
                split_rewards: [],
                n_episodes: 1,
                data_sampled_all_checkpoints: false,
            },
            data_timestamp: 0,
            current_highlight_steps: {
                previous: { bottom: 0, top: 1023, value: 0 },
                new: { bottom: 0, top: 1023, value: 0 },
            },
            current_hover: { step: 0, model_index: 0 },
            scheduled_benchmarks: [],
            selected_experiment: undefined,
            sidebar_collapsed: false,
            benchmarked_models: [],
        };
    }

    componentDidMount() {
        axios.get('/get_all?model_name=project').then((res) => {
            this.setState({ projects: new Map(res.data.map((item) => [item.id, item])) });
        });

        axios.get('/get_all?model_name=dataset').then((res) => {
            const datasets = res.data;
            this.setState({ datasets: datasets });
        });
    }

    selectDatapoint(new_steps) {
        const old_steps = this.state.current_highlight_steps;
        const updated_steps = { bottom: old_steps.new.bottom, top: old_steps.new.top, value: new_steps };
        this.setState({ current_highlight_steps: { previous: old_steps.new, new: updated_steps } });
    }

    setHoverStep(info) {
        this.setState({ current_hover: { step: info['model_data_step'], model_index: info['model_index'] } });
    }

    highlightIndices(indices) {
        const infos = this.state.current_benchmark_results?.infos;
        // Set infos[highlighted] to true for all indices, else false
        for (let i = 0; i < infos.length; i++) {
            infos[i].highlighted = indices.includes(i);
        }
        const split_infos = this.chunkIt(infos, this.state.current_benchmark_results?.dones);
        this.setState({
            current_benchmark_results: {
                ...this.state.current_benchmark_results,
                infos: infos,
                split_infos: split_infos,
            },
            selection_timestamp: Date.now(),
        });
    }

    /**
     * Called from the Sidebar component to update relevant state values,
     * especially for the benchmarking, env or algorithm name
     * @param {{paramter_name:paramenter_value}} updated_props
     */
    setSidebarProp(updated_props) {
        this.setState(updated_props);
    }

    toggleSidebar() {
        this.setState({ sidebar_collapsed: !this.state.sidebar_collapsed });
    }

    addToScheduled() {
        console.log('Adding to scheduled', this.state.selected_dataset);

        const active_checkpoints = this.state.scheduled_benchmarks.map((b) => b.checkpoint_step);

        let new_benchmarks = [];
        if (this.state.selected_dataset !== undefined) {
            new_benchmarks.push({
                env_id: -1,
                checkpoint_step: -1,
                exp_id: -1,
                force_overwrite: true,
                benchmark_type: 'dataset',
                benchmark_id: this.state.selected_dataset.id,
                split_by_episode: true,
            });
        }

        this.state.selected_checkpoints
            .filter((c) => !active_checkpoints.includes(c.value))
            .forEach((c) => {
                const env_id = this.state.primary_env?.id ?? -1;

                new_benchmarks.push({
                    env_id: env_id,
                    force_overwrite: this.state.force_overwrite,
                    benchmark_type: 'trained',
                    benchmark_id: this.state.selected_experiment.id,
                    checkpoint_step: c,
                    deterministic: this.state.deterministic_evaluation,
                    n_episodes: this.state.n_episodes,
                    render: this.state.request_rendering,
                    run_explainer: this.state.request_explainer,
                    reset_state: this.state.reset_state,
                    gym_registry_id: this.state.gym_registry_id,
                    split_by_episode: true,
                });
            });
        this.setState({ scheduled_benchmarks: [...this.state.scheduled_benchmarks, ...new_benchmarks] });
    }

    removeFromScheduled(benchmark_id, env_id, checkpoint_step) {
        const remove_item = this.state.scheduled_benchmarks.findIndex(
            (r) => r.benchmark_id == benchmark_id && r.env_id == env_id && r.checkpoint_step == checkpoint_step
        );
        if (remove_item > 0) {
            let new_scheduled = this.state.scheduled_benchmarks;
            new_scheduled.splice(remove_item, 1);
            this.setState({ scheduled_benchmarks: new_scheduled });
        } else if (remove_item == 0) {
            this.setState({ scheduled_benchmarks: [] });
        }
    }

    benchmarkTrainedModel() {
        this.setState({ is_loading: true });
        axios.post('/data/run_benchmark', this.state.scheduled_benchmarks).then((res) => {
            const buffers = res.data;
            let benchmarked_models = new Map();
            this.state.scheduled_benchmarks.forEach((run, i) => {
                let after_bm_model = run;
                let model_exp =
                    run.benchmark_type === 'dataset'
                        ? this.state.datasets?.find((e) => e.id == run.benchmark_id)
                        : this.state.experiments?.find((e) => e.id == run.benchmark_id);
                let gym_name =
                    run.benchmark_type === 'dataset'
                        ? ''
                        : this.state.environments.find((e) => e.id == run.env_id).env_name;
                after_bm_model['stats'] = buffers?.models[i]?.additional_metrics;
                after_bm_model['model_name'] = model_exp.exp_name + ' ' + gym_name + ' ' + run.checkpoint_step;
                after_bm_model['model_description'] =
                    run.benchmark_type === 'dataset' ? model_exp.dataset_description : model_exp.exp_comment;
                after_bm_model['gym_registration_id'] = gym_name;
                benchmarked_models = benchmarked_models.set('benchmark_run_' + i, after_bm_model);
            });

            this.setState({
                n_steps: buffers?.n_steps ?? 0,
                env_space_info: buffers?.env_space_info,
                benchmarked_models: benchmarked_models,
                open_card_details: Array.from(benchmarked_models.keys()).map(() => false),
            });
            this.loadCollectedData();
        });
    }

    loadCollectedData() {
        axios.post('/data/load_benchmark_data', Array.from(this.state.benchmarked_models.values())).then((res) => {
            const buffers = res.data;
            const n_steps = this.state.n_steps;

            function chunkIt(arr, dones) {
                const done_indices = dones.reduce((a, elem, i) => (elem === true && a.push(i), a), []);

                if (arr.length == 0 || done_indices.length == 0) {
                    return [];
                }

                const ret = [];
                let last = 0;

                done_indices.forEach((i) => {
                    ret.push(arr.slice(last, i + 1));
                    last = i + 1;
                });
                if (last < arr.length) {
                    ret.push(arr.slice(last));
                }
                return ret;
            }

            this.setState({
                current_benchmark_results: {
                    obs: buffers.map((bm) => bm.obs)?.flat(),
                    rews: buffers.map((bm) => bm.rewards)?.flat(),
                    dones: buffers.map((bm) => bm.dones)?.flat(),
                    actions: buffers.map((bm) => bm.actions)?.flat(),
                    infos: buffers.map((bm) => bm.infos)?.flat(),
                    label_infos: this.getLabelsFromInfos(buffers[0]?.infos),
                    done_indices: buffers
                        .map((bm) => bm.dones)
                        ?.flat()
                        .reduce((a, elem, i) => (elem === true && a.push(i), a), []),
                    renders: buffers.map((bm) => bm.renders)?.flat(),
                    attr: buffers.map((bm) => bm.attr)?.flat(),
                    probabilities: buffers.map((bm) => bm.probs)?.flat(),
                    episode_rewards: buffers.map((bm) => bm.episode_rewards)?.flat(),
                    episode_lengths: {
                        lengths: buffers.map((bm) => bm.lengths)?.flat(),
                        cummulative: buffers
                            .map((bm) => bm.lengths)
                            ?.flat()
                            .reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []),
                    },
                    episodes_per_model: buffers
                        .map((bm) => bm.episode_rewards)
                        ?.flat()
                        .reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []),
                    split_rewards: buffers
                        .map((bm) => ({ rews: bm.rewards, dones: bm.dones }))
                        .map((e) => chunkIt(e.rews, e.dones)),
                    split_infos: buffers
                        .map((bm) => ({ infos: bm.infos, dones: bm.dones }))
                        .map((e) => chunkIt(e.infos, e.dones)),
                },
                data_timestamp: Date.now(),
                excluded_episodes: [],
                benchmark_time: { nr_of_steps: n_steps, benchmark_time: buffers.benchmark_time },
                current_highlight_steps: {
                    previous: { bottom: 0, top: n_steps - 1, value: 0 },
                    new: { bottom: 0, top: n_steps - 1, value: 0 },
                },
                show_render_image: this.state.request_rendering ? true : false,
                highlightHiddenMap: new Array(n_steps).fill(0),
                benchmark_steps: n_steps,
                benchmark_n_episodes: this.state.n_episodes * this.state.benchmarked_models.size,
                benchmark_total_episodes: buffers.map((bm) => bm.dones).filter(Boolean).length,
                is_loading: false,
            });
            this.computeAdditionalInfoValues();
        });
    }

    deleteBenchmarkedModel(model_id) {
        const new_benchmarked_models = new Map(this.state.benchmarked_models);
        new_benchmarked_models.delete(model_id);
        this.setState({ benchmarked_models: new_benchmarked_models });
    }

    render() {
        // Two-Colum Layout, in the left column, there is a summary card on topand an embedding card below. On the right side there is a card div.

        return (
            <Container fluid="true">
                <Row style={{ height: '95vh' }}>
                    <Ranking_Sidebar
                        mode="evaluation"
                        collapsed={this.state.sidebar_collapsed}
                        deleteModel={this.deleteBenchmarkedModel.bind(this)}
                        onBenchmarkButton={this.benchmarkTrainedModel.bind(this)}
                        addToScheduled={this.addToScheduled.bind(this)}
                        removeFromScheduled={this.removeFromScheduled.bind(this)}
                        setSidebarProp={this.setSidebarProp.bind(this)}
                        embeddingMethods={['UMAP']}
                        {...this.state}
                    />
                    <Col md={this.state.sidebar_collapsed ? 12 : 0}>
                        <Row>
                            <Col md={6} className={`g-0`}>
                                <Card className={rankingstyle.summary_card}>
                                    <Card.Body>
                                        <Card.Title>Summary</Card.Title>
                                        <Card.Text>
                                            <p>Summary</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Row className={rankingstyle.embedding_card}>
                                    <Ranking_Embedding
                                        selectDatapoint={this.selectDatapoint.bind(this)}
                                        currentRewardData={this.state.current_benchmark_results.rews}
                                        infoTypes={Object.keys(this.state.current_benchmark_results.infos[0]).filter(
                                            (info) => !['dummy', 'label', 'id'].includes(info)
                                        )}
                                        currentDoneData={this.state.current_benchmark_results.dones}
                                        currentInfoData={this.state.current_benchmark_results.infos}
                                        actionData={this.state.current_benchmark_results.actions}
                                        reproject={this.state.request_reproject}
                                        appendTimestamp={this.state.request_append_timestamp}
                                        scheduledBenchmarks={this.state.scheduled_benchmarks}
                                        selectedEnvRegistrationId={this.state.primary_env?.registration_id ?? ''}
                                        selectedCheckpoint={(this.state.selected_checkpoints ?? [])[0]}
                                        dataTimestamp={this.state.data_timestamp}
                                        selectionTimestamp={this.state.selection_timestamp}
                                        sampledAllCheckpoints={false}
                                        visibleEpisodes={[]}
                                        selectedEpisode={[]}
                                        highlightSteps={this.state.current_highlight_steps}
                                        labelInfo={this.state.current_benchmark_results.label_infos}
                                        experimentName={this.state.selected_experiment?.exp_name}
                                        viewMode={this.state.view_mode}
                                        annotationMode={this.state.annotation_mode}
                                        setHoverStep={this.setHoverStep.bind(this)}
                                        highlightIndices={this.highlightIndices.bind(this)}
                                    />
                                </Row>
                            </Col>
                            <Col md={6} className={`g-0`}>
                                <Row className={rankingstyle.ranking_card}>
                                    <Ranking_List />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}
