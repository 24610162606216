import React from 'react';
import widgetstyle from '../css/Widgets.module.css';
import { Badge, Button } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import Detail_Window from '../Common/Detail_Window';
import { config } from '../app_config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faMinus } from '@fortawesome/free-solid-svg-icons';

export default function render_widget(props) {
    const tagFormater = (list) => {
        const return_list = list.map((tag) => {
            return (
                <Badge key={tag} variant="secondary">
                    {tag}
                </Badge>
            );
        });
        return <div>{return_list}</div>;
    };

    // If probs.viewMode is "transition_embedding", the also draw the next state (image) next to the original image

    return (
        <div style={props.style} className={widgetstyle.render_view}>
            {props.benchmarkInfo?.gym_registration_id !== undefined ? (
                <>
                    <div>
                        <p>
                            <b>
                                Exp. {props.benchmarkInfo.exp_id} Step. {props.step}
                            </b>
                        </p>
                        <div
                            style={
                                props.viewMode === 'transition_embedding'
                                    ? { display: 'flex', flexDirection: 'row' }
                                    : {}
                            }
                        >
                            <img
                                src={
                                    '/data/get_single_obs?step=' +
                                    props.step +
                                    '&gym_registration_id=' +
                                    props.benchmarkInfo.gym_registration_id +
                                    '&benchmark_type=' +
                                    props.benchmarkInfo.benchmark_type +
                                    '&benchmark_id=' +
                                    props.benchmarkInfo.benchmark_id +
                                    '&checkpoint_step=' +
                                    props.benchmarkInfo.checkpoint_step +
                                    '&episode_id=' +
                                    props.episode +
                                    '&type=render&rdn=' +
                                    Math.random()
                                }
                            ></img>
                            {props.viewMode === 'transition_embedding' ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        style={{ margin: 'auto', fontSize: '2em' }}
                                    />
                                    <img
                                        src={
                                            '/data/get_single_obs?step=' +
                                            (props.step + 1) +
                                            '&gym_registration_id=' +
                                            props.benchmarkInfo.gym_registration_id +
                                            '&benchmark_type=' +
                                            props.benchmarkInfo.benchmark_type +
                                            '&benchmark_id=' +
                                            props.benchmarkInfo.benchmark_id +
                                            '&checkpoint_step=' +
                                            props.benchmarkInfo.checkpoint_step +
                                            '&episode_id=' +
                                            props.episode +
                                            '&type=render&rdn=' +
                                            Math.random() +
                                            '&next_state=true'
                                        }
                                    ></img>
                                </>
                            ) : null}
                            {props.viewMode === 'activation_mapping' ? (
                                <>
                                    <FontAwesomeIcon icon={faMinus} style={{ margin: 'auto', fontSize: '2em' }} />
                                    <img
                                        src={
                                            '/data/get_single_attribution?step=' +
                                            (props.step + 1) +
                                            '&gym_registration_id=' +
                                            props.benchmarkInfo.gym_registration_id +
                                            '&benchmark_type=' +
                                            props.benchmarkInfo.benchmark_type +
                                            '&benchmark_id=' +
                                            props.benchmarkInfo.benchmark_id +
                                            '&target=' +
                                            props.currentEvalResults.actions[props.step] +
                                            '&checkpoint_step=' +
                                            props.benchmarkInfo.checkpoint_step +
                                            '&dataset_path=' +
                                            props.benchmarkInfo.dataset_path +
                                            '&episode_id=' +
                                            props.episode +
                                            '&type=render&rdn=' +
                                            Math.random()
                                        }
                                        width="200"
                                    ></img>
                                </>
                            ) : null}
                        </div>
                        <p>
                            <b>Labels:</b>
                        </p>{' '}
                        {tagFormater(props.tags)}
                    </div>
                    <Button
                        variant="light"
                        onClick={() => props.setOpenStateDetails(!props.openStateDetails)}
                        style={{ float: 'right' }}
                    >
                        <FontAwesomeIcon icon={faChevronDown} title="left" aria-hidden={true} />
                    </Button>
                    <Collapse
                        key={'collapse_render_widget'}
                        in={props.openStateDetails}
                        onEntered={props.setOpenStateDetails}
                    >
                        <div>
                            <h5>Action Distribution</h5>
                            <p>Probability Distribution of Actions (Network Output)</p>
                            <Detail_Window
                                data={props.currentEvalResults.actions}
                                probabilities={props.currentEvalResults.probabilities}
                                spaceInfo={props.envSpaceInfo?.action_space ?? {}}
                                dataSteps={props.currentSteps}
                                showProbs={true}
                                lineColor={config.action_space_color}
                            />
                        </div>
                    </Collapse>
                </>
            ) : (
                <p> </p>
            )}
        </div>
    );
}
