/* eslint-disable prettier/prettier */
import React, { cloneElement, PureComponent } from 'react';
import { Button, Accordion, ListGroup, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { legend } from '../Common/Color_Legend';
import * as d3 from 'd3';
import axios from 'axios';
import rankingstyle from '../css/Ranking.module.css';
import { Color2D } from '../Common/2dcolormaps';

const circle_function = (cx, cy, r) => {
    return (
        'M ' +
        (cx - r) +
        ', ' +
        cy +
        ' a ' +
        r +
        ',' +
        r +
        ' 0 1,0 ' +
        r * 2 +
        ',0' +
        ' a ' +
        r +
        ',' +
        r +
        ' 0 1,0 ' +
        -(r * 2) +
        ',0'
    );
};

export default class Evaluation_Embedding extends PureComponent {
    lasso = null;

    constructor(props) {
        super(props);
        this.embeddingRef = React.createRef();
        this.backgroundColorLegendRef = React.createRef();
        this.objectColorLegendRef = React.createRef();

        this.state = {
            rawdat: [],
            labels: [],
            centroids: [],
            merged_points: [],
            connections: [],
            feature_embedding: [],
            transition_embedding: [],
            data_timestamp: 0,
            sequence_length: 1,
            background_layer_color_scale_mode: 'none',
            object_layer_color_scale_mode: 'step_reward',
            draw_lasso: false,
            object_layer_colors: [],
            highlighted: [],
            selected: [],
            object_color_scale: undefined,
            background_color_scale: undefined,
            background_layer_colors: [],
            step_images: ['/files/base.jpeg', '/files/traj.jpeg', '/files/latent.jpeg', '/files/transitions.jpeg'],
            x: undefined,
            y: undefined,
            k: 1.0,
        };

        this._currentZoomLevel = 1;
    }

    componentDidMount() {
        this.drawChart('', []);
    }

    changeSequenceSlider(event) {
        const seq_length = event.target.value;
        this.setState({ sequence_length: seq_length });
    }

    resizeSVG() {
        this.drawChart(
            this.props.viewMode,
            this.state.rawdat,
            this.state.labels,
            this.state.merged_points,
            this.state.connections,
            this.state.feature_embedding,
            this.state.transition_embedding,
            this.props.actionData,
            this.props.currentDoneData,
            this.props.labelInfo,
            this.props.annotationMode
        );
    }

    loadData() {
        const new_color_scale = d3
            .scaleSequential((t) => d3.interpolateOrRd(t * 0.85 + 0.15))
            .domain(d3.extent(this.props.currentRewardData));
        const reproject = this.props.reproject ? 1 : 0;
        const append_time = this.props.appendTimestamp ? 1 : 0;
        axios
            .post(
                '/embedding/current_obs_to_embedding?sequence_length=' +
                    this.state.sequence_length +
                    '&embedding_hash=' +
                    this.computeHashFromOptions(
                        this.props.scheduledBenchmarks,
                        embedding_method,
                        use_one_d_embedding,
                        reproject,
                        append_time,
                        this.props.embeddingSettings
                    ) +
                    '&reproject=' +
                    reproject +
                    '&reproject_checkpoint_step=' +
                    this.props.selectedCheckpoint +
                    '&append_time=' +
                    append_time,
                { benchmarks: this.props.scheduledBenchmarks }
            )
            .then((res) => {
                const data = res.data;
                const objColorData = this.getColorsForObjects();
                const bgColorData = this.getColorsForBackground();
                const selected_points = this.props.infos.map((i) => i['selected']);
                const highlighted_points = this.props.infos.map((i) => i['highlighted']);
                this.setState(
                    {
                        rawdat: data.embedding,
                        labels: data.labels,
                        centroids: data.centroids,
                        merged_points: data.merged_points,
                        connections: data.connections,
                        feature_embedding: data.feature_embedding,
                        transition_embedding: data.transition_embedding,
                        data_timestamp: this.props.timeStamp,
                        object_layer_colors: objColorData.colors,
                        selected: selected_points,
                        highlighted: highlighted_points,
                        background_layer_colors: bgColorData.colors,
                        color_scale: objColorData.scale,
                    },
                    this.drawChart(
                        this.props.viewMode,
                        data.embedding,
                        data.labels,
                        data.merged_points,
                        data.connections,
                        data.feature_embedding,
                        data.transition_embedding,
                        this.props.actionData,
                        this.props.currentDoneData,
                        this.props.labelInfo,
                        this.props.annotationMode
                    )
                );
            });
    }

    computeHashFromOptions(
        scheduled_benchmarks,
        embedding_method,
        use_one_d_embedding,
        reproject,
        append_time,
        embedding_settings
    ) {
        // Create unique non-secure hash from options without the crypto library
        // This is used to cache the embeddings
        const hash =
            JSON.stringify(scheduled_benchmarks) +
            embedding_method +
            use_one_d_embedding +
            reproject +
            append_time +
            JSON.stringify(embedding_settings);
        let hashValue = 0;
        for (let i = 0; i < hash.length; i++) {
            hashValue += hash.charCodeAt(i);
        }
        return hashValue;
    }

    getColorData(mode) {
        if (mode === 'none') {
            const color_scale = d3
                .scaleLinear()
                .domain(d3.extent(this.props.currentRewardData))
                .range(['white', 'white']);
            return {
                data: this.props.currentRewardData,
                colors: this.props.currentRewardData.map((c) => 'white'),
                scale: color_scale,
            };
        }

        if (mode === 'step_reward') {
            if (this.props.currentRewardData.length > 0) {
                const data = this.props.currentRewardData;
                const color_scale = d3
                    .scaleSequential((t) => d3.interpolateOrRd(t * 0.85 + 0.15))
                    .domain(d3.extent(data));
                return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
            } else {
                return { data: [], colors: ['ffffff'], scale: this.state.object_color_scale };
            }
        }
        const data = this.props.infos.map((i) => i[mode]);
        let interpolator = function (t) {
            return d3.interpolateOrRd(t * 0.85 + 0.15);
        };
        if (mode == 'action') {
            const color_scale = d3.scaleOrdinal(d3.schemeSet3).domain(d3.extent(data));
            return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
        } else if (mode == 'episode index') {
            interpolator = d3.interpolateCool;
        }

        const color_scale = d3.scaleSequential(interpolator).domain(d3.extent(data));
        return { data: data, colors: data.map((c) => color_scale(c)), scale: color_scale };
    }

    getColorsForObjects() {
        return this.getColorData(this.state.object_layer_color_scale_mode);
    }

    getColorsForBackground() {
        return this.getColorData(this.state.background_layer_color_scale_mode);
    }

    getBackgroundLayerListItems() {
        return this.getLayerListItems(this.setBackgroundLayerColorMode, this.state.background_layer_color_scale_mode);
    }

    getObjectLayerListItems() {
        return this.getLayerListItems(this.setObjectLayerColorMode, this.state.object_layer_color_scale_mode);
    }

    getLayerListItems(f, activeState) {
        return ['none', 'step_reward'].concat(this.props.infoTypes).map((type, i) => {
            return (
                <ListGroup.Item
                    variant="flush"
                    onClick={f.bind(this, type)}
                    key={type}
                    eventKey={type}
                    active={activeState === type}
                >
                    {type}
                </ListGroup.Item>
            );
        });
    }

    setBackgroundLayerColorMode(mode) {
        this.setState({ background_layer_color_scale_mode: mode });
    }

    setObjectLayerColorMode(mode) {
        this.setState({ object_layer_color_scale_mode: mode });
    }

    updateColorLegend() {
        if (this.state.object_color_scale === undefined) return;
        d3.select(this.objectColorLegendRef.current).select('*').remove();
        d3.select(this.objectColorLegendRef.current)
            .node()
            .appendChild(
                legend({
                    color: this.state.object_color_scale,
                    width: this.objectColorLegendRef.current.parentElement.clientWidth,
                })
            );

        if (this.state.background_color_scale === undefined) return;
        d3.select(this.backgroundColorLegendRef.current).select('*').remove();
        d3.select(this.backgroundColorLegendRef.current)
            .node()
            .appendChild(
                legend({
                    color: this.state.background_color_scale,
                    width: this.backgroundColorLegendRef.current.parentElement.clientWidth,
                })
            );
    }

    splitArray(arr, indices) {
        var result = [];
        var lastIndex = 0;
        for (var i = 0; i < indices.length; i++) {
            // Note that the last observations of an episode is already from the next episode (i.e. the one give the done flag, so omit drawing the path)
            result.push(arr.slice(lastIndex, indices[i] + 1));
            lastIndex = indices[i] + 1;
        }
        result.push(arr.slice(Math.min(lastIndex, arr.length - 1)));
        return result;
    }

    // Source: https://stackoverflow.com/a/1484514
    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    // Source: https://stackoverflow.com/a/18473154
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    }

    // Source: https://stackoverflow.com/a/18473154
    describeArc(x, y, radius, startAngle, endAngle) {
        if (endAngle >= 360) {
            return (
                'M ' +
                (x - radius) +
                ', ' +
                y +
                ' a ' +
                radius +
                ',' +
                radius +
                ' 0 1,0 ' +
                radius * 2 +
                ',0' +
                ' a ' +
                radius +
                ',' +
                radius +
                ' 0 1,0 ' +
                -(radius * 2) +
                ',0'
            );
        }

        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

        return d;
    }

    updateChartColors(highlightSteps, visibleEpisodes) {
        this.updateColorLegend();
        const currentStep = highlightSteps.new.value;
        const svg = d3.select(this.embeddingRef.current);

        const x = this.state.x;
        const y = this.state.y;
        let d = undefined;
        const scale = Math.round((5.0 / this.state.k) * 100) / 100;
        if (this.props.viewMode === 'transition_embedding') {
            d = this.state.transition_embedding[currentStep];
        } else {
            d = this.state.rawdat[currentStep];
        }

        if (d == undefined) return;

        svg.select('#step_marker')
            .attr('d', circle_function(x(d[0]), y(d[1]), 3))
            .attr('transform', function () {
                const cx = x(d[0]);
                const cy = y(d[1]);
                return (
                    'matrix(' + scale + ', 0, 0, ' + scale + ', ' + (cx - scale * cx) + ', ' + (cy - scale * cy) + ')'
                );
            })
            .attr('fill', 'rgba(255, 50, 0, 0.5)')
            .attr('stroke', 'rgba(255, 50, 0, 0.8)');
    }

    toggleLasso() {
        if (this.state.draw_lasso) {
            d3.select(this.embeddingRef.current).select('svg').remove('lasso');
        } else if (this.lasso !== null) {
            d3.select(this.embeddingRef.current).select('svg').call(this.lasso);
        }
        this.setState({ draw_lasso: !this.state.draw_lasso });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataTimestamp !== prevProps.dataTimestamp) {
            this.loadData();
        }
        if (this.props.showSidebar !== prevProps.showSidebar) {
            this.resizeSVG();
        }
        if (
            this.props.dataTimestamp !== prevProps.dataTimestamp ||
            this.state.object_layer_color_scale_mode !== prevState.object_layer_color_scale_mode ||
            this.state.background_layer_color_scale_mode !== prevState.background_layer_color_scale_mode ||
            this.props.selectionTimestamp !== prevProps.selectionTimestamp
        ) {
            const objColorData = this.getColorsForObjects();
            const bgColorData = this.getColorsForBackground();
            const selected_points = this.props.infos.map((i) => i['selected']);
            const highlighted_points = this.props.infos.map((i) => i['highlighted']);
            this.setState(
                {
                    object_layer_colors: objColorData.colors,
                    background_layer_colors: bgColorData,
                    object_color_scale: objColorData.scale,
                    background_color_scale: bgColorData.scale,
                    highlighted: highlighted_points,
                    selected: selected_points,
                },
                this.updateChartColors(this.props.highlightSteps, this.props.visibleEpisodes)
            );
        }
        this.updateChartColors(this.props.highlightSteps, this.props.visibleEpisodes);
    }

    drawChart(
        viewMode = 'state_space',
        data = [],
        labels = [],
        merged_points = [],
        connections = [],
        feature_embeddings = [],
        transition_embeddings = [],
        actionData = [],
        doneData = [],
        labelInfos = [],
        annotationMode = 'analyze'
    ) {
        switch (viewMode) {
            case 'state_space':
                this.drawStateSpace(data, labels, doneData, labelInfos, annotationMode);
                break;
            case 'decision_points':
                this.drawDecisionPoints(data, merged_points, connections);
                break;
            case 'activation_mapping':
                this.drawActivationMapping(data, feature_embeddings, doneData);
                break;
            case 'transition_embedding':
                this.drawTransitionEmbedding(transition_embeddings, actionData, data, annotationMode);
                break;
            default:
                this.drawStateSpace(data, labels, doneData, labelInfos, annotationMode);
        }
    }

    drawStateSpace(data = [], labels = [], doneData = [], labelInfos = [], annotationMode = 'analyze') {
        const _self = this;
        this.lastIndex = data.length - 1;
        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const done_idx = doneData.reduce((a, elem, i) => (elem === true && a.push(i), a), []);
        d3.select(this.embeddingRef.current).select('*').remove();
        const svgHeight = this.embeddingRef.current.parentElement.clientHeight;
        const svgWidth = this.embeddingRef.current.parentElement.clientWidth;
        if (svgWidth < 0 || svgHeight < 0) return;

        let is_one_d = false;
        if (data.length > 0 && data[0].length === 1) {
            // If embedding is 2D, add time (a.k.a. steps as the x dimension), first x is an array from 1 to length of data
            data = data.map((k, i) => [this.props.infos.map((v) => v['episode step'])[i], ...k]);
        }

        const quadTree = d3.quadtree(
            data.map((d, i) => [d[0], d[1], i]),
            (d) => d[0],
            (d) => d[1]
        );

        const container = d3.select(this.embeddingRef.current);

        // Remove all children of the container
        container.selectAll('*').remove();

        container.style('position', 'relative');
        const canvas = container.append('canvas').node();
        // Select canvas in d3
        const context = canvas.getContext('2d');

        canvas.width = svgWidth;
        canvas.height = svgHeight;

        const svg = container
            .append('svg')
            .attr('width', svgWidth)
            .attr('height', svgHeight)
            .style('position', 'absolute')
            .style('top', '0px')
            .style('left', '0px')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const xDomain = [d3.min(data.map((d) => d[0])), d3.max(data.map((d) => d[0]))];
        const xScale = d3.scaleLinear().domain(xDomain).range([0, svgWidth]);

        const yDomain = [d3.min(data.map((d) => d[1])), d3.max(data.map((d) => d[1]))];
        const yScale = d3.scaleLinear().range([svgHeight, 0]).domain(yDomain);

        Color2D.ranges = { x: xDomain, y: yDomain };

        const zoom = d3
            .zoom()
            .scaleExtent([0.2, 15])
            .translateExtent([
                [-600, -600],
                [svgWidth + 600, svgHeight + 600],
            ])
            .on('zoom', zoomed)
            .on('end', function (event) {
                _self.setState({ k: event.transform.k });
            });

        const view = svg.append('g');

        const view_rect = view
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', svgWidth)
            .style('opacity', '0');

        view_rect.on('mousemove', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                //_self.props.setHoverStep(_self.props.infos[closest[2]]);
            } else {
                //_self.props.setHoverStep(-1);
            }
        });
        view_rect.on('click', function (event) {
            const mouse = d3.pointer(event);

            // map the clicked point to the data space
            const xClicked = xScale.invert(mouse[0]);
            const yClicked = yScale.invert(mouse[1]);

            // find the closest point in the dataset to the clicked point
            const closest = quadTree.find(xClicked, yClicked, 10);

            if (closest) {
                _self.props.setHoverStep(_self.props.infos[closest[2]]);
                _self.props.selectDatapoint(closest[2]);
            }
        });

        const lineFunction = d3
            .line()
            .curve(d3.curveCatmullRom)
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            });

        const splitData = this.splitArray(data, done_idx);

        const label_data_map = new Map();
        const start_label_data = [0].concat(done_idx.slice(0, -1).map((d) => d + 1));

        for (let i = 0; i < start_label_data.length; i++) {
            label_data_map.set(start_label_data[i], ['Start']);
        }

        for (let i = 0; i < done_idx.length; i++) {
            if (!label_data_map.has(done_idx[i])) label_data_map.set(done_idx[i], ['Done']);
            else label_data_map.get(done_idx[i]).push('Done');
        }

        for (let i = 0; i < labelInfos.length; i++) {
            const label = labelInfos[i].label;
            const ids = labelInfos[i].ids;

            for (let j = 0; j < ids.length; j++) {
                if (!label_data_map.has(ids[j])) label_data_map.set(ids[j], [label]);
                else label_data_map.get(ids[j]).push(label);
            }
        }

        const text_labels = view
            .selectAll('label-g')
            .data(data.map((d, i) => [d[0], d[1], i]).filter((d) => label_data_map.has(d[2])))
            .enter()
            .append('g')
            .attr('class', 'label-g')
            .attr('id', (d) => 'label-g_' + d[2]);

        _self.updateColorLegend();

        text_labels
            .append('text')
            .attr('class', 'label')
            .attr('x', (d) => xScale(d[0]) + 10)
            .attr('y', (d) => yScale(d[1]) + 10)
            .attr('text-anchor', 'center')
            .text((d) => label_data_map.get(d[2]).join('/'));

        text_labels
            .append('line')
            .attr('class', 'label-line')
            .attr('vector-effect', 'non-scaling-stroke')
            .attr('x1', (d) => xScale(d[0]))
            .attr('y1', (d) => yScale(d[1]))
            .attr('x2', (d) => xScale(d[0]) + 10)
            .attr('y2', (d) => yScale(d[1]) + 10)
            .attr('stroke', '#a1a1a1');

        // Prepare a color palette
        const color = d3.scaleSequential(d3.interpolateOrRd).domain([0, 0.05]);

        // Old contour plot
        let densityData = [];
        densityData = d3
            .contourDensity()
            .x(function (d) {
                return xScale(d[0]);
            })
            .y(function (d) {
                return yScale(d[1]);
            })
            .size([svgWidth, svgHeight])
            .bandwidth(30)(data.map((d, i) => [d[0], d[1], i]));

        if (annotationMode === 'annotate') {
            const unique_labels = new Set(labels);
            // For each labeled cluster, draw a convex hull
            for (const label of unique_labels) {
                if (label === -1) continue;
                const label_g = view.append('g').attr('class', 'label-g');
                const cluster_indices = data
                    .map((element, index) => {
                        if (labels[index] === label) {
                            return index;
                        }
                    })
                    .filter((element) => element >= 0);
                const cluster_data = data.filter((_, i) => labels[i] === label);
                const hull = d3.polygonHull(cluster_data.map((d) => [xScale(d[0]), yScale(d[1])]));

                // Add label text in the center of the convex hull
                const center = d3.polygonCentroid(hull);
                label_g
                    .append('path')
                    .attr('d', 'M' + hull.join('L') + 'Z')
                    .attr('fill', Color2D.getColor(xScale.invert(center[0]), yScale.invert(center[1])))
                    .style('opacity', 0.4)
                    .on('mouseover', function (d) {
                        d3.select(this).style('opacity', 0.6);
                    })
                    .on('mouseout', function (d) {
                        d3.select(this).style('opacity', 0.4);
                    })
                    .on('click', function (d) {
                        d3.select(this).style('opacity', 0.7);
                        // Open text edit field to change label
                        const new_label = prompt('Please enter a new label', '');
                        if (new_label !== null) {
                            // Update label
                            this_label_text.text(new_label);
                        }
                        _self.props.annotateState(cluster_indices, new_label, label);
                    });

                // Check if label in props.annotationSets, if so, use the label in props.annotated_sets
                console.log(label);
                const label_text = label in _self.props.annotationSets ? _self.props.annotationSets[label] : label;

                const this_label_text = label_g
                    .append('text')
                    .attr('class', 'label')
                    .attr('x', center[0])
                    .attr('y', center[1])
                    .attr('text-anchor', 'center')
                    .text(label_text);
            }
        }

        view.append('g')
            .append('path')
            .datum(data[0])
            .attr('d', (d) => circle_function())
            .attr('fill-opacity', 0.5)
            .attr('fill', '#ff3737')
            .attr('stroke', '#ff3737')
            .attr('id', 'step_marker');

        const dataLength = splitData.length;

        function zoomed(event) {
            event = event.transform;

            view.attr('transform', event);

            const r = Math.round((5 / event.k) * 100) / 100;
            const width = Math.round((1 / event.k) * 100) / 100;

            context.save();
            context.clearRect(0, 0, svgWidth, svgHeight);
            context.translate(event.x, event.y);
            context.scale(event.k, event.k);

            if (_self.state.background_layer_color_scale_mode !== 'none') {
                densityData.forEach((d) => {
                    context.beginPath();
                    // path(contours.thresholds(d3.range(0, 60, 10))(data));
                    d3.geoPath().context(context)(d);
                    //context.stroke();
                    context.fillStyle = color(d.value);
                    context.fill();
                });
            }

            // Draw the paths on canvas with the line function
            splitData.forEach((d, i) => {
                if (d.length === 0) return;

                context.strokeStyle = d3.interpolateCool(i / dataLength);
                context.lineWidth = width;
                context.beginPath();

                context.moveTo(xScale(d[0][0]), yScale(d[0][1]));
                for (let j = 1; j < d.length; j++) {
                    context.lineTo(xScale(d[j][0]), yScale(d[j][1]));
                }
                context.stroke();
            });
            for (const [x, y, i] of data.map((d, i) => [xScale(d[0]), yScale(d[1]), i])) {
                if (!_self.state.selected[i]) continue;

                context.beginPath();
                context.moveTo(x + r, y);
                // If highlighted, draw recangle
                if (_self.state.highlighted[i]) {
                    context.rect(x - r, y - r, 2 * r, 2 * r);
                    context.lineWidth = 1 / event.k;
                    context.strokeStyle = '#000000';
                    context.stroke();
                } else {
                    context.arc(x, y, r, 0, 2 * Math.PI);
                }

                context.fillStyle = _self.state.object_layer_colors[i];
                // opacity
                context.globalAlpha = 0.5;
                context.fill();
                context.closePath();
            }
            context.restore();

            if (event.k > 2.0) {
                //step_images.attr('display', 'inline').style('opacity', 0.5 + event.k / 10);
            } else {
                //step_images.attr('display', 'none');
            }
            text_labels.selectAll('text').attr('font-size', 16 / event.k);
            //step_images.attr('opacity', Math.min(0.3 * event.k, 0.9));
        }

        svg.call(zoom);

        // Call the zoom function to update the view
        zoomed({ transform: d3.zoomIdentity });

        this.setState({ x: xScale, y: yScale });
    }

    render() {
        return (
            <div className={rankingstyle.embedding_wrapper_div}>
                <div ref={this.embeddingRef}></div>
                <div className={rankingstyle.embedding_control_wrapper_div} style={{ width: 'max(30%, 850px)' }}>
                    <div style={{ marginTop: 10 }}>
                        <Accordion alwaysOpen={true} className={rankingstyle.control_overlay_div}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Object Layer</Accordion.Header>
                                <Accordion.Body style={{ padding: '0' }}>
                                    <ListGroup
                                        variant="flush"
                                        style={{ maxHeight: '250px', cursor: 'pointer', overflowY: 'scroll' }}
                                    >
                                        {this.getObjectLayerListItems()}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div
                        className={rankingstyle.control_overlay_div}
                        style={{ position: 'absolute', left: 15, top: '58vh' }}
                    >
                        <div>
                            <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '0' }}>
                                Background Color Scale: {this.state.background_layer_color_scale_mode}
                            </p>
                            <div ref={this.backgroundColorLegendRef}></div>
                        </div>
                    </div>
                    <div
                        className={rankingstyle.control_overlay_div}
                        style={{ position: 'absolute', right: 0, top: '58vh' }}
                    >
                        <div>
                            <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '0' }}>
                                Object Color Scale: {this.state.object_layer_color_scale_mode}
                            </p>
                            <div ref={this.objectColorLegendRef}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
