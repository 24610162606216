import React from 'react';
import { BarChart, Bar, Cell, XAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeSet2 } from 'd3-scale-chromatic';

const colors = scaleOrdinal(schemeSet2).range();

export default function Model_Glyph(props) {
    console.log(props.originalData);
    return (
        <ResponsiveContainer width="100%" height={100}>
            <BarChart
                data={Object.entries(props.data).map(([k, v]) => {
                    return { name: k, value: v };
                })}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="name" tickFormatter={(label) => label.replace(/(^avg_)/gi, '')} />
                <Tooltip
                    formatter={(value, name, innerProps) => props.originalData[innerProps?.payload.name]}
                    labelFormatter={(label) => label.replace(/(^avg_)/gi, '')}
                />
                <Bar dataKey="value" fill="#8884d8">
                    {Object.values(props.data)?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                    <LabelList dataKey="value" position="insideBottom" formatter={(value) => value.toFixed(2)} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
