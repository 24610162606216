import React from 'react';
import '../css/Widgets.module.css';
import Spaces_Parallel_Histogram from './Spaces_Parallel_Histogram';
import Action_Probability_Diagram from './Action_Probability_Diagram';

const discrete_types = ['Discrete', 'MultiDiscrete'];

export default function detail_window(props) {
    if (!props.data) {
        return <div></div>;
    }

    // get step relative to bottom
    const post_filter_index = props.dataSteps.value - props.dataSteps.bottom;
    const data_point = props.data[Math.min(props.data.length, post_filter_index + 1)];

    let attr_data_point;
    if (props.attrData !== undefined && props.attrData.length > 0)
        attr_data_point = props.attrData[Math.min(props.attrData.length, post_filter_index + 1)];

    let probability_data_point;
    if (props.probabilities !== undefined && props.probabilities.length > 0)
        probability_data_point = props.probabilities[Math.min(props.probabilities.length, post_filter_index + 1)];

    let return_type = 'obs';
    if (props.returnType === 'dataset_obs') return_type = 'dataset_obs';

    return (
        <div>
            {data_point && Array.isArray(data_point) && props.spaceInfo ? (
                <div>
                    <Spaces_Parallel_Histogram
                        data={data_point}
                        attrData={attr_data_point}
                        isAttribution={props.isAttribution}
                        selectedDimension={props.selectedDimension}
                        spaceInfo={props.spaceInfo}
                        width={props.width}
                        lineColor={props.lineColor}
                    />
                </div>
            ) : (
                <div>
                    {data_point === undefined ? (
                        <>
                            <img
                                style={{ marginLeft: '2px', marginRight: '2px' }}
                                width={140}
                                src={
                                    '/data/get_single_obs?step=' +
                                    props.dataSteps.value +
                                    '&type=obs&rdn=' +
                                    Math.random()
                                }
                            ></img>
                            <div>
                                {props.showExplanation && (
                                    <img
                                        width={250}
                                        src={
                                            '/data/get_single_obs?step=' +
                                            props.dataSteps.value +
                                            '&type=attr&rdn=' +
                                            Math.random()
                                        }
                                    ></img>
                                )}
                            </div>
                        </>
                    ) : (
                        <div>
                            {probability_data_point !== undefined ? (
                                <Action_Probability_Diagram
                                    data={data_point}
                                    probabilities={probability_data_point}
                                    spaceInfo={props.spaceInfo}
                                    chooseAction={props.chooseAction}
                                />
                            ) : (
                                <p>
                                    <b>
                                        {data_point}{' '}
                                        {props.spaceInfo &&
                                            Number.isInteger(data_point) &&
                                            '(' + props.spaceInfo['tag_' + data_point] + ')'}
                                    </b>
                                </p>
                            )}
                        </div>
                    )}
                </div>
            )}
            {props.spaceInfo && props.spaceInfo.shape && (
                <div>
                    Space: <b>{props.spaceInfo.label}</b>
                </div>
            )}
        </div>
    );
}
