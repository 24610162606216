import React, { Component, PureComponent } from 'react';
import * as d3 from 'd3';

/***
 * Renders a linechart based on 1D-array data. Similar to the histogram visualization, it takes
 * up to two data rows. The secondary data row should have equal length to primary data.
 */
export default class card_small_multiples extends PureComponent {
    constructor(props) {
        super(props);
        this.small_multiples_ref = React.createRef();
    }

    componentDidMount() {
        if (this.props.episodeData?.length > 0) this.drawChart(this.props.episodeData);
    }

    componentDidUpdate() {
        if (this.props.episodeData?.length > 0) this.drawChart(this.props.episodeData);
    }

    drawChart(data) {
        if (data.length == 0) return;

        // data is an array of arrays, each array represents the data for one episode
        const margin = { top: 10, right: 10, bottom: 10, left: 10 };
        const svgWidth = this.small_multiples_ref.current.parentElement.clientWidth - margin.left - margin.right;
        const svgHeight = 40 - margin.top - margin.bottom;

        d3.select(this.small_multiples_ref.current).selectAll('*').remove();

        const plot_data = data.map((d) => d.reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []));

        const svg = d3
            .select(this.small_multiples_ref.current)
            .selectAll('subchart')
            .data(plot_data)
            .join('svg')
            .attr('width', svgWidth + margin.left + margin.right)
            .attr('height', svgHeight + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const x = d3
            .scaleLinear()
            .domain([0, d3.max(plot_data.map((d) => d.length))])
            .range([0, svgWidth]);

        const y = d3
            .scaleLinear()
            .domain([0, d3.max(plot_data.map((d) => d3.max(d)))])
            .range([svgHeight, 0]);

        const color = d3.interpolateCool;

        const highlightedStepRanges = [];
        let highlightedActiveStart = -1;

        // each step i has an info value infos[i], can be either selected or highlighted
        // Find Consequective ranges of selected and highlighted steps
        const highlighted = this.props.episodeInfo?.map((info) => info.highlighted) || [];
        for (let i = 0; i < highlighted.length; i++) {
            if (highlighted[i] === true) {
                if (highlightedActiveStart === -1) {
                    highlightedActiveStart = i;
                }
            }
            if (highlighted[i] === false) {
                if (highlightedActiveStart !== -1) {
                    highlightedStepRanges.push([highlightedActiveStart, i - 1]);
                    highlightedActiveStart = -1;
                }
            }
        }
        if (highlightedActiveStart !== -1) {
            highlightedStepRanges.push([highlightedActiveStart, highlighted.length - 1]);
        }

        // x-axis without ticks, just keep the line
        svg.append('g')
            .attr('transform', 'translate(0,' + svgHeight + ')')
            .call(d3.axisBottom(x).tickSize(0));

        //svg.append('g').call(d3.axisLeft(y));

        svg.append('path')
            .attr('fill', (d, i) => color(i / data.length))
            .attr('stroke', (d, i) => color(i / data.length))
            .attr('d', (d) =>
                d3
                    .area()
                    .x((d, i) => x(i))
                    .y0(y(0))
                    .y1((d) => y(d))(d)
            );

        // draw highlighted step ranges
        svg.append('g')
            .selectAll('rect')
            .data(highlightedStepRanges)
            .join('rect')
            .attr('x', (d) => x(d[0]))
            .attr('y', 0)
            .attr('width', (d) => x(d[1] - d[0] + 1))
            .attr('height', svgHeight)
            .attr('fill', 'rgba(255, 255, 0, 0.2)');
    }

    render() {
        return <div ref={this.small_multiples_ref}></div>;
    }
}
