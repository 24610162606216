/* eslint-disable prefer-const */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import evalstyle from '../css/Evaluation.module.css';
import { config } from '../app_config';
import Evaluation_Sidebar from './Evaluation_Sidebar';
import Evaluation_Reward_Graph from './Evaluation_Reward_Graph';
import Evaluation_Embedding from './Evaluation_Embedding';
import Evaluation_Embedding_webgl from './Evaluation_Embedding_webgl';
import Step_Player from '../Common/Step_Player';
import Render_Widget from '../Common/Render_Widget';
import Evaluation_Scroll_List from './Evaluation_Scroll_List';
import pre_configured_scenarios from '../pre_configured_scenarios';

/**
 * Main Component of the Evaluation Page
 */
export default class evaluation_main extends Component {
    user_study_mode = true;

    constructor(props) {
        super(props);

        this.state = {
            projects: new Map(),
            selected_project: undefined,
            experiments: [],
            datasets: [],
            dataset_ids: [],
            environments: [],
            primary_env: undefined,
            secondary_env: undefined,
            selected_experiments: [],
            selected_dataset: undefined,
            sidebar_collapsed: false,
            selected_checkpoints: [],
            checkpoint_options: [{ value: 0, label: 0 }],
            benchmark_steps: 1024,
            embedding_render_mode: 'canvas',

            // Default values for benchmarking
            n_episodes: 1,
            benchmark_n_episodes: 1,
            benchmark_total_episodes: 1,
            reset_state: false,
            request_rendering: true,
            deterministic_evaluation: true,
            request_reproject: false,
            request_append_timestamp: false,
            request_explainer: false,
            force_overwrite: false,

            // Benchmarks configs are saved here
            scheduled_benchmarks: [],

            show_compare_envs: false,
            tracking_items: new Map(),

            embedding_method: 'UMAP',
            embedding_methods: [],
            embedding_axis_option: '2D embedding',
            embedding_settings: {},
            clusteringMode: false,
            benchmarked_models: new Map(),
            open_card_details: [],
            open_state_details: false,
            card_has_expanded: false,
            show_policy_view: false,
            annotation_mode: 'analyze',
            view_mode: 'state_space',
            show_models: [],

            annotation_sets: [],
            annotated_segments: [],

            // Benchmark Results
            n_steps: 0,
            current_benchmark_results: {
                obs: [],
                rews: [],
                dones: [],
                actions: [],
                infos: [{ dummy: 0 }],
                done_indices: [],
                label_infos: [],
                attr: [],
                probabilities: [],
                episode_lengths: [],
                episode_rewards: [],
                split_rewards: [],
                n_episodes: 1,
                data_sampled_all_checkpoints: false,
            },
            reward_comparison_curve: [],
            data_timestamp: 0,
            selection_timestamp: 0,
            benchmark_time: { nr_of_steps: 0, benchmark_time: 0 },
            current_highlight_steps: {
                previous: { bottom: 0, top: 1023, value: 0 },
                new: { bottom: 0, top: 1023, value: 0 },
            },
            current_hover: { step: 0, model_index: 0, episode: 0 },
            highlightHiddenMap: [],
            hidden_step_ranges: [],
            highlighted_step_ranges: [],
            active_episodes: [],
            is_loading: false,
        };
    }

    componentDidMount() {
        axios.get('/get_all?model_name=project').then((res) => {
            this.setState({ projects: res.data ? new Map(res.data.map((item) => [item.id, item])) : new Map() });
        });

        // Load pre-configured scenarios
        if (this.props.demoScenario !== undefined)
            this.loadPreConfiguredScenario(this.props.demoScenario);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.demoScenario !== this.props.demoScenario) {
            this.loadPreConfiguredScenario(this.props.demoScenario);
        }
    }

    /**
     * Called from the Sidebar component to update relevant state values,
     * especially for the benchmarking, env or algorithm name
     * @param {{paramter_name:paramenter_value}} updated_props
     */
    setSidebarProp(updated_props) {
        this.setState(updated_props);
    }
    
    loadPreConfiguredScenario(scenario) {
        console.log("LOADING SCENARIO", pre_configured_scenarios);
            const config = pre_configured_scenarios[scenario];
            console.log("SCENARIO CONFIG", scenario, config);

            if (config === undefined)
                return;

            // Clear all previous benchmarks
            this.setState({ scheduled_benchmarks: [] });
            
            this.setState({
                selected_project: this.state.projects.get(config.project),
                selected_experiments: this.state.experiments.filter((e) => config.experiments.includes(e.id)),
                primary_env: this.state.environments.find((e) => e.id === config.environment),
                selected_checkpoints: config.checkpoints,
                deterministic: config.deterministic_evaluation,
                n_episodes: config.n_episodes,
                render: true,
                reset_state: config.reset_state,
                gym_registry_id: config.gym_registry_id,
            }, () => {this.addToScheduled(this.benchmarkTrainedModel)});
        }
    

    setHighlightSteps(new_steps) {
        const old_steps = this.state.current_highlight_steps;
        this.setState({ current_highlight_steps: { previous: old_steps.new, new: new_steps } });
    }

    setShowPolicyView(show_policy_view, callback) {
        if (show_policy_view) {
            this.setState({ show_policy_view: true }, callback);
        }
    }

    setViewMode(view_mode, callback) {
        this.setState({ view_mode: view_mode }, callback);
    }

    setAnnotationMode(annotation_mode, callback) {
        this.setState({ annotation_mode: annotation_mode }, callback);
    }

    toggleSidebar() {
        this.setState({ sidebar_collapsed: !this.state.sidebar_collapsed });
    }

    setOpenStateDetails(open_state_details) {
        this.setState({ open_state_details: open_state_details });
    }

    setHoverStep(info) {
        this.setState({ current_hover: { step: info['episode step'], model_index: info['model_index'], episode: info['model_episode'] } });
    }

    setOpenCardDetails(open_card_index) {
        let open_card_details = this.state.open_card_details;
        open_card_details[open_card_index] = !open_card_details[open_card_index];
        this.setState({ open_card_details: open_card_details });
    }

    setHasExpandedCard(card_has_expanded) {
        this.setState({ card_has_expanded: card_has_expanded });
    }

    chunkIt(arr, dones) {
        const done_indices = dones.reduce((a, elem, i) => (elem === true && a.push(i), a), []);

        if (arr.length == 0 || done_indices.length == 0) {
            return [];
        }

        const ret = [];
        let last = 0;

        done_indices.forEach((i) => {
            ret.push(arr.slice(last, i + 1));
            last = i + 1;
        });
        if (last < arr.length) {
            ret.push(arr.slice(last));
        }
        return ret;
    }

    hideStepRange(range) {
        const infos = this.state.current_benchmark_results?.infos;
        // Set infos[selected] to false for all steps in range
        for (let i = range[0]; i <= range[1]; i++) {
            infos[i].selected = false;
        }
        const split_infos = this.chunkIt(infos, this.state.current_benchmark_results?.dones);
        this.setState({
            current_benchmark_results: {
                ...this.state.current_benchmark_results,
                infos: infos,
                split_infos: split_infos,
            },
            selection_timestamp: Date.now(),
        });
    }

    highlightStepRange(range) {
        const infos = this.state.current_benchmark_results?.infos;
        // Set infos[highlighted] to true for all steps in range
        for (let i = range[0]; i <= range[1]; i++) {
            infos[i].highlighted = true;
        }
        const split_infos = this.chunkIt(infos, this.state.current_benchmark_results?.dones);
        this.setState({
            current_benchmark_results: {
                ...this.state.current_benchmark_results,
                infos: infos,
                split_infos: split_infos,
            },
            selection_timestamp: Date.now(),
        });
    }

    highlightIndices(indices) {
        const infos = this.state.current_benchmark_results?.infos;
        // Set infos[highlighted] to true for all indices, else false
        for (let i = 0; i < infos.length; i++) {
            infos[i].highlighted = indices.includes(i);
        }
        const split_infos = this.chunkIt(infos, this.state.current_benchmark_results?.dones);
        this.setState({
            current_benchmark_results: {
                ...this.state.current_benchmark_results,
                infos: infos,
                split_infos: split_infos,
            },
            selection_timestamp: Date.now(),
        });
    }

    resetHighlightingFilter() {
        const infos = this.state.current_benchmark_results?.infos;
        // Set infos[highlighted] to false for all steps in range, infos[selected] to true
        for (let i = 0; i < infos.length; i++) {
            infos[i].highlighted = false;
            infos[i].selected = true;
        }
        const split_infos = this.chunkIt(infos, this.state.current_benchmark_results?.dones);
        this.setState({
            current_benchmark_results: {
                ...this.state.current_benchmark_results,
                infos: infos,
                split_infos: split_infos,
            },
            selection_timestamp: Date.now(),
        });
    }

    updateActiveEpisodes(episode) {
        const active_episodes = this.state.active_episodes;
        // Find episode by model and episode number
        const episode_index = active_episodes.findIndex((e) => e.model_id === episode.model_id && e.episode === episode.episode);
        // Toggle active state
        active_episodes[episode_index] = !active_episodes[episode_index];
        this.setState({ active_episodes: active_episodes });
    }

    deleteBenchmarkedModel(model_id) {
        const new_benchmarked_models = new Map(this.state.benchmarked_models);
        new_benchmarked_models.delete(model_id);
        this.setState({ benchmarked_models: new_benchmarked_models });
    }

    selectDatapoint(new_steps) {
        const old_steps = this.state.current_highlight_steps;
        let updated_steps = { bottom: old_steps.new.bottom, top: old_steps.new.top, value: new_steps };
        console.log(updated_steps);
        this.setState({ current_highlight_steps: { previous: old_steps.new, new: updated_steps } });
    }

    getEpisodeIndexFromStepValue(step_value) {
        const done_idx = this.state.current_benchmark_results.done_indices;
        return done_idx.findIndex((idx) => idx > step_value);
    }

    /*
     * The Infos can contain labels for particular states which we collect here.
     * Return a list of all labels with the corresponding ids.
     */
    getLabelsFromInfos(infos) {
        const labels = [];
        for (const info of infos) {
            if (info.label) {
                if (!labels.includes(info.label)) {
                    labels.push({ label: info.label, ids: [info.id] });
                } else {
                    labels[labels.map((l) => l.label).indexOf(info.label)].ids.push(info.id);
                }
            }
        }
        return labels;
    }

    getEpisodeModelMap() {
        const map = new Map();
        // rewrite above function
        this.state.benchmarked_models.forEach((model, model_id) => {
            const episode_indices = [];
            for (let i = 0; i < model.n_episodes; i++) {
                episode_indices.push(i + model_id * model.n_episodes);
            }
            map.set(model_id, episode_indices);
        });
        return map;
    }


    /**
     *
     * @param {*} add_to_current
     */
    computeAdditionalInfoValues() {
        // Based on total number of steps, compute the number of steps until next done index
        const info_values = this.state.current_benchmark_results?.infos;

        if (!info_values || this.state.current_benchmark_results?.actions.length === 0) return;

        const episodes_per_model =
            this.state.current_benchmark_results.done_indices.length / this.state.benchmarked_models.size;

        let current_done_idx = 0;
        let current_model_idx = 0;
        let current_model_episode = 0;
        let current_model_step = 0;
        for (let i = 0; i < this.state.current_benchmark_results.infos.length; i++) {
            info_values[i]['tags'] = [];
            if (i == this.state.current_benchmark_results.done_indices[current_done_idx]) {
                info_values[i]['steps until done'] = Math.floor(
                    this.state.current_benchmark_results?.done_indices[current_done_idx + 1] - i
                );
                current_done_idx++;
                current_model_episode++;
                if (current_done_idx % episodes_per_model === 0) {
                    current_model_idx++;
                    current_model_episode = 0;
                    current_model_step = 0;
                }
                info_values[i]['tags'] = ['End of an Episode'];
                info_values[i]['annotated'] = true;
            } else {
                info_values[i]['steps until done'] = Math.floor(
                    this.state.current_benchmark_results?.done_indices[current_done_idx] - i
                );
                info_values[i]['annotated'] = false;
            }
            info_values[i]['episode index'] = current_done_idx;
            info_values[i]['model_index'] = current_model_idx;
            info_values[i]['model_episode'] = current_model_episode;
            info_values[i]['model_data_step'] = current_model_step;
            info_values[i]['action'] = this.state.current_benchmark_results?.actions[i];
            info_values[i]['action probability'] =
                this.state.current_benchmark_results?.probabilities[i][
                    this.state.current_benchmark_results?.actions[i]
                ];
            info_values[i]['selected'] = true;
            info_values[i]['highlighted'] = false;
            current_model_step++;
        }
        console.log(this.state.current_benchmark_results, info_values);
        this.setState({
            current_benchmark_results: { ...this.state.current_benchmark_results, infos: info_values },
            label_infos: this.getLabelsFromInfos(info_values),
        });
    }

    annotateState(state_ids, label, original_label) {
        const current_info = this.state.current_benchmark_results.infos;
        state_ids.forEach((id) => {
            current_info[id].tags.push(label);
            current_info[id].annotated = true;
        });
        let new_annotation_set = this.state.annotation_sets;
        if (original_label) {
            new_annotation_set[original_label] = label;
        }
        this.setState({
            current_benchmark_results: { ...this.state.current_benchmark_results, infos: current_info },
            annotation_sets: new_annotation_set,
        });
    }

    toggleShowModel(model_id) {
        const new_show_models = this.state.show_models;
        new_show_models[model_id] = !new_show_models[model_id];
        this.setState({ show_models: new_show_models, selection_timestamp: Date.now() });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addToScheduled(callback = () => {}) {
        let active_checkpoints = this.state.scheduled_benchmarks.map((b) => b.checkpoint_step);

        let new_benchmarks = [];
        if (this.state.selected_dataset !== undefined) {
            new_benchmarks.push({
                env_id: -1,
                checkpoint_step: -1,
                force_overwrite: true,
                benchmark_type: 'dataset',
                benchmark_id: this.state.selected_dataset.id,
                dataset_name: this.state.selected_dataset.name,
            });
        }

        this.state.selected_experiments.forEach((e) => {
            this.state.selected_checkpoints
                .filter((c) => !active_checkpoints.includes(c.value))
                .forEach((c) => {
                    const env_id = this.state.primary_env?.id ?? -1;

                    new_benchmarks.push({
                        env_id: env_id,
                        force_overwrite: this.state.force_overwrite,
                        benchmark_type: 'trained',
                        benchmark_id: e.id,
                        checkpoint_step: c,
                        deterministic: this.state.deterministic_evaluation,
                        n_episodes: this.state.n_episodes,
                        render: this.state.request_rendering,
                        run_explainer: this.state.request_explainer,
                        reset_state: this.state.reset_state,
                        gym_registry_id: this.state.gym_registry_id,
                    });
                });
        });
        this.setState({ scheduled_benchmarks: [...this.state.scheduled_benchmarks, ...new_benchmarks] }, callback);
    }

    removeFromScheduled(benchmark_id, env_id, checkpoint_step) {
        const remove_item = this.state.scheduled_benchmarks.findIndex(
            (r) => r.benchmark_id == benchmark_id && r.env_id == env_id && r.checkpoint_step == checkpoint_step
        );
        if (remove_item > 0) {
            let new_scheduled = this.state.scheduled_benchmarks;
            new_scheduled.splice(remove_item, 1);
            this.setState({ scheduled_benchmarks: new_scheduled });
        } else if (remove_item == 0) {
            this.setState({ scheduled_benchmarks: [] });
        }
    }

    benchmarkTrainedModel() {
        this.setState({ is_loading: true });
        axios.post('/data/run_benchmark', this.state.scheduled_benchmarks).then((res) => {
            const buffers = res.data;
            console.log("BUFFERS", buffers);
            let benchmarked_models = new Map();
            this.state.scheduled_benchmarks.forEach((run, i) => {
                let after_bm_model = run;
                let model_exp =
                    run.benchmark_type === 'dataset'
                        ? this.state.datasets?.find((e) => e.id == run.benchmark_id)
                        : this.state.experiments?.find((e) => e.id == run.benchmark_id);
                let gym_name =
                    run.benchmark_type === 'dataset'
                        ? ''
                        : this.state.environments.find((e) => e.id == run.env_id).env_name;
                after_bm_model['stats'] = buffers?.models[i]?.additional_metrics;
                after_bm_model['model_name'] =
                    run.benchmark_type === 'dataset'
                        ? model_exp.dataset_name
                        : model_exp.exp_name + ' ' + gym_name + ' ' + run.checkpoint_step;
                after_bm_model['model_description'] =
                    run.benchmark_type === 'dataset' ? model_exp.dataset_description : model_exp.exp_comment;
                after_bm_model['gym_registration_id'] = gym_name;
                after_bm_model['episode_id'] = 0;
                after_bm_model['episode_num'] = buffers?.models[i]?.episode_num;
                benchmarked_models = benchmarked_models.set('benchmark_run_' + i, after_bm_model);
            });

            this.setState({
                n_steps: buffers?.n_steps ?? 0,
                env_space_info: buffers?.env_space_info,
                benchmarked_models: benchmarked_models,
                open_card_details: Array.from(benchmarked_models.keys()).map(() => false),
                show_models: Array.from({ length: benchmarked_models.size }, () => true),
            });
            this.loadCollectedData();
        });
    }

    loadCollectedData() {
        // Expand benchmarked runs to full runs (with all episode ids)
        let full_benchmarked_runs = [];
        this.state.benchmarked_models.forEach((run) => {
            for (let i = 0; i < run.episode_num; i++) {
                // Change episode_id to epoch_id
                let full_run = { ...run, episode_id: i };
                full_benchmarked_runs.push(full_run);
            }
        });
        axios.post('/data/load_benchmark_data', full_benchmarked_runs).then((res) => {
            const buffers = res.data;
            const n_steps = this.state.n_steps;

            function chunkIt(arr, dones) {
                const done_indices = dones.reduce((a, elem, i) => (elem === true && a.push(i), a), []);

                if (arr.length == 0 || done_indices.length == 0) {
                    return [];
                }

                const ret = [];
                let last = 0;

                done_indices.forEach((i) => {
                    ret.push(arr.slice(last, i + 1));
                    last = i + 1;
                });
                if (last < arr.length) {
                    ret.push(arr.slice(last));
                }
                return ret;
            }

            this.setState(
                {
                    current_benchmark_results: {
                        obs: buffers.map((bm) => bm.obs)?.flat(),
                        rews: buffers.map((bm) => bm.rewards)?.flat(),
                        dones: buffers.map((bm) => bm.dones)?.flat(),
                        actions: buffers.map((bm) => bm.actions)?.flat(),
                        infos: buffers.map((bm) => bm.infos)?.flat(),
                        label_infos: this.getLabelsFromInfos(buffers[0]?.infos),
                        done_indices: buffers.map((bm) => bm.dones)?.flat().reduce((a, elem, i) => (elem === true && a.push(i), a), []),
                        renders: buffers.map((bm) => bm.renders)?.flat(),
                        attr: buffers.map((bm) => bm.attr)?.flat(),
                        probabilities: buffers.map((bm) => bm.probs)?.flat(),
                        episode_rewards: buffers.map((bm) => bm.episode_rewards)?.flat(),
                        episode_lengths: {
                            lengths: buffers.map((bm) => bm.lengths)?.flat(),
                            cummulative: buffers.map((bm) => bm.lengths)?.flat().reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []),
                        },
                        episodes_per_model: buffers.map((bm) => bm.episode_rewards)?.flat().reduce((a, x, i) => [...a, x + (a[i - 1] || 0)], []),
                        split_rewards: buffers.map((bm) => bm.rewards),
                        split_infos: buffers.map((bm) => bm.infos),
                },
                data_timestamp: Date.now(),
                active_episodes: [],
                benchmark_time: { nr_of_steps: n_steps, benchmark_time: buffers.benchmark_time },
                current_highlight_steps: {
                    previous: { bottom: 0, top: n_steps - 1, value: 0 },
                    new: { bottom: 0, top: n_steps - 1, value: 0 },
                },
                show_render_image: this.state.request_rendering ? true : false,
                highlightHiddenMap: new Array(n_steps).fill(0),
                benchmark_steps: n_steps,
                benchmark_n_episodes: this.state.n_episodes * this.state.benchmarked_models.size,
                benchmark_total_episodes: buffers.map((bm) => bm.dones).filter(Boolean).length,
                is_loading: false,
            });
            this.computeAdditionalInfoValues();
        });
    }

    saveAnnotationsToFile() {
        console.log('[INFO] Save Annotations To File');
        this.state.scheduled_benchmarks.forEach((bm) => {
            axios.post(
                '/routing/save_current_benchmark_results_annotations_tp_file?exp_id=' +
                    bm?.benchmark_id +
                    '&checkpoint_timestep=' +
                    bm?.checkpoint_timestep +
                this.state.current_benchmark_results.infos
            );
        });
    }

    render() {
        return (
            <div>
                <Container fluid="true">
                    <Row>
                        <Evaluation_Sidebar
                            mode="evaluation"
                            collapsed={this.state.sidebar_collapsed}
                            deleteModel={this.deleteBenchmarkedModel.bind(this)}
                            onBenchmarkButton={this.benchmarkTrainedModel.bind(this)}
                            addToScheduled={this.addToScheduled.bind(this)}
                            removeFromScheduled={this.removeFromScheduled.bind(this)}
                            setSidebarProp={this.setSidebarProp.bind(this)}
                            embeddingMethods={this.state.embedding_methods}
                            {...this.state}
                        />
                        <Col md={this.state.sidebar_collapsed ? 12 : 0}>
                            <Row>
                                {this.state.is_loading ? (
                                    <div
                                        style={{
                                            height: '95vh',
                                            width: '83.5%',
                                            zIndex: 100,
                                            backgroundColor: 'rgba(0.75, 0.75, 0.75, 0.5)',
                                            position: 'absolute',
                                            paddingTop: '40vh',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Spinner animation="border" role="status" style={{ padding: '25px' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : null}
                                <Col md={9}>
                                    <Row style={{ height: '67vh' }}>
                                        <Col>
                                            <Button
                                                variant="secondary"
                                                onClick={this.toggleSidebar.bind(this)}
                                                style={{
                                                    zIndex: 1,
                                                    position: 'absolute',
                                                    top: '4vh',
                                                    marginBottom: '1rem',
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faBars} />
                                            </Button>
                                            <Row style={{ height: '67vh' }}>
                                                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    {this.state.embedding_render_mode === 'canvas' ? (
                                                        <Evaluation_Embedding
                                                            showSidebar={this.state.sidebar_collapsed}
                                                            fullWidth={!this.state.show_policy_view}
                                                            embeddingAxisOption={this.state.embedding_axis_option}
                                                            embeddingMethod={this.state.embedding_method}
                                                            embeddingSettings={this.state.embedding_settings}
                                                            selectDatapoint={this.selectDatapoint.bind(this)}
                                                            currentRewardData={
                                                                this.state.current_benchmark_results.rews
                                                            }
                                                            infoTypes={Object.keys(
                                                                this.state.current_benchmark_results.infos[0]
                                                            ).filter(
                                                                (info) => !['dummy', 'label', 'id'].includes(info)
                                                            )}
                                                            currentDoneData={this.state.current_benchmark_results.dones}
                                                            actionData={this.state.current_benchmark_results.actions}
                                                            infos={this.state.current_benchmark_results.infos}
                                                            reproject={this.state.request_reproject}
                                                            appendTimestamp={this.state.request_append_timestamp}
                                                            benchmarkedModels={Array.from(this.state.benchmarked_models.values())}
                                                            selectedEnvRegistrationId={
                                                                this.state.primary_env?.registration_id ?? ''
                                                            }
                                                            selectedCheckpoint={
                                                                (this.state.selected_checkpoints ?? [])[0]
                                                            }
                                                            dataTimestamp={this.state.data_timestamp}
                                                            selectionTimestamp={this.state.selection_timestamp}
                                                            sampledAllCheckpoints={false}
                                                            activeEpisodes={this.state.active_episodes}
                                                            selectedEpisode={this.getEpisodeIndexFromStepValue(
                                                                this.state.current_highlight_steps.new.value
                                                            )}
                                                            showModels={this.state.show_models}
                                                            highlightSteps={this.state.current_highlight_steps}
                                                            labelInfo={this.state.current_benchmark_results.label_infos}
                                                            viewMode={this.state.view_mode}
                                                            annotationMode={this.state.annotation_mode}
                                                            setViewMode={this.setViewMode.bind(this)}
                                                            setAnnotationMode={this.setAnnotationMode.bind(this)}
                                                            setHoverStep={this.setHoverStep.bind(this)}
                                                            annotateState={this.annotateState.bind(this)}
                                                            annotationSets={this.state.annotation_sets}
                                                            annotationSegments={this.state.annotation_segments}
                                                            highlightIndices={this.highlightIndices.bind(this)}
                                                        />
                                                    ) : (
                                                        <Evaluation_Embedding_webgl
                                                            showSidebar={this.state.sidebar_collapsed}
                                                            fullWidth={!this.state.show_policy_view}
                                                            embeddingAxisOption={this.state.embedding_axis_option}
                                                            embeddingMethod={this.state.embedding_method}
                                                            embeddingSettings={this.state.embedding_settings}
                                                            selectDatapoint={this.selectDatapoint.bind(this)}
                                                            currentRewardData={
                                                                this.state.current_benchmark_results.rews
                                                            }
                                                            infoTypes={Object.keys(
                                                                this.state.current_benchmark_results.infos[0]
                                                            ).filter(
                                                                (info) => !['dummy', 'label', 'id'].includes(info)
                                                            )}
                                                            currentDoneData={this.state.current_benchmark_results.dones}
                                                            actionData={this.state.current_benchmark_results.actions}
                                                            infos={this.state.current_benchmark_results.infos}
                                                            reproject={this.state.request_reproject}
                                                            appendTimestamp={this.state.request_append_timestamp}
                                                            benchmarkedModels={Array.from(this.state.benchmarked_models.values())}
                                                            selectedEnvRegistrationId={
                                                                this.state.primary_env?.registration_id ?? ''
                                                            }
                                                            selectedCheckpoint={
                                                                (this.state.selected_checkpoints ?? [])[0]
                                                            }
                                                            dataTimestamp={this.state.data_timestamp}
                                                            selectionTimestamp={this.state.selection_timestamp}
                                                            sampledAllCheckpoints={false}
                                                            visibleEpisodes={this.getEpisodeIndices(
                                                                this.state.current_benchmark_results.dones
                                                            )}
                                                            selectedEpisode={this.getEpisodeIndexFromStepValue(
                                                                this.state.current_highlight_steps.new.value
                                                            )}
                                                            showModels={this.state.show_models}
                                                            highlightSteps={this.state.current_highlight_steps}
                                                            labelInfo={this.state.current_benchmark_results.label_infos}
                                                            viewMode={this.state.view_mode}
                                                            annotationMode={this.state.annotation_mode}
                                                            setViewMode={this.setViewMode.bind(this)}
                                                            setAnnotationMode={this.setAnnotationMode.bind(this)}
                                                            setHoverStep={this.setHoverStep.bind(this)}
                                                            annotateState={this.annotateState.bind(this)}
                                                            annotationSets={this.state.annotation_sets}
                                                            annotationSegments={this.state.annotation_segments}
                                                            highlightIndices={this.highlightIndices.bind(this)}
                                                        />
                                                    )}
                                                    {
                                                        /* Show floating absolutely positioned render view, showing the render for the currently hovered state */
                                                        this.state.current_hover?.step >= 0 && (
                                                            <Render_Widget
                                                                benchmarkInfo={
                                                                    this.state.benchmarked_models.get(
                                                                        'benchmark_run_' +
                                                                            this.state.current_hover.model_index
                                                                    ) ?? undefined
                                                                }
                                                                episode={this.state.current_hover?.episode ?? 0}
                                                                step={this.state.current_hover?.step ?? 0}
                                                                width={this.state.render_view_width}
                                                                height={this.state.render_view_height}
                                                                tags={
                                                                    this.state.current_benchmark_results?.infos[
                                                                        this.state.current_hover?.step
                                                                    ]['tags'] ?? []
                                                                }
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '4vh',
                                                                    right: this.state.sidebar_collapsed
                                                                        ? '25vw'
                                                                        : '21vw',
                                                                    zIndex: 100,
                                                                }}
                                                                currentEvalResults={
                                                                    this.state.current_benchmark_results
                                                                }
                                                                envSpaceInfo={this.state.env_space_info}
                                                                currentSteps={this.state.current_highlight_steps.new}
                                                                openStateDetails={this.state.open_state_details}
                                                                setOpenStateDetails={this.setOpenStateDetails.bind(
                                                                    this
                                                                )}
                                                                viewMode={this.state.view_mode}
                                                            />
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            height: '25vh',
                                            borderTop: '1px solid #999999',
                                            marginTop: '8px',
                                            paddingTop: '5px',
                                        }}
                                    >
                                        <Col>
                                            <Evaluation_Reward_Graph
                                                data={[
                                                    this.state.current_benchmark_results.rews,
                                                    this.state.current_benchmark_results.dones,
                                                ]}
                                                infoTypes={Object.keys(
                                                    this.state.current_benchmark_results.infos[0]
                                                ).filter((info) => !['dummy', 'label', 'id'].includes(info))}
                                                infos={this.state.current_benchmark_results.infos}
                                                comparisonRewardCurve={this.state.reward_comparison_curve}
                                                lineColor={config.reward_color}
                                                changeSteps={this.setHighlightSteps.bind(this)}
                                                setHoverStep={this.setHoverStep.bind(this)}
                                                selectedSteps={this.state.current_highlight_steps}
                                                episodeRewards={this.state.current_benchmark_results.episode_rewards}
                                                episodeLengths={this.state.current_benchmark_results.episode_lengths}
                                                showModels={this.state.show_models}
                                                selectionTimestamp={this.state.selection_timestamp}
                                                hiddenSteps={this.state.hidden_step_ranges}
                                                dataAggregationMode={this.state.rew_aggr_mode}
                                                dataTimestamp={this.state.data_timestamp}
                                                hideNewStepRange={this.hideStepRange.bind(this)}
                                                activeEpisodes={this.state.active_episodes}
                                                updateActiveEpisodes={this.updateActiveEpisodes.bind(this)}
                                                episodeModelMap={this.getEpisodeModelMap()}
                                                // hiddenStepRanges={this.state.hidden_step_ranges}
                                                highlightStepRange={this.highlightStepRange.bind(this)}
                                                totalNumEpisodes={this.state.benchmark_total_episodes}
                                                // highlightedStepRanges={this.state.highlighted_step_ranges}
                                                resetHighlightingFilter={this.resetHighlightingFilter.bind(this)}
                                                clusteringMode={this.state.clusteringMode}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{ borderTop: '1px solid #999999', paddingTop: '5px' }}
                                        className="justify-content-center"
                                    >
                                        <Col md={6}>
                                            <Step_Player
                                                stepState={this.state.current_highlight_steps}
                                                setSteps={this.setHighlightSteps.bind(this)}
                                                setHoverStep={this.setHoverStep.bind(this)}
                                                infos={this.state.current_benchmark_results?.infos}
                                                colapsed={false}
                                                extraMargin={true}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className={evalstyle.widget_column}>
                                    <Evaluation_Scroll_List
                                        benchmarkedModels={this.state.benchmarked_models}
                                        openCardDetails={this.state.open_card_details}
                                        setOpenCardDetails={this.setOpenCardDetails.bind(this)}
                                        setHasExpandedCard={this.setHasExpandedCard.bind(this)}
                                        hasExpandedCard={this.state.card_has_expanded}
                                        episodeData={this.state.current_benchmark_results?.split_rewards}
                                        episodeInfos={this.state.current_benchmark_results?.split_infos}
                                        toggleShowModel={this.toggleShowModel.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
