import Modal from 'react-modal';
import React from 'react';
import { Figure, Stack, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../css/Help_Modal.css';
Modal.setAppElement('#root');

/**
 * Overlay Compoment that is displayed to analyse a single image.
 */
export default function HelpModal(props) {
    return (
        <Modal
            isOpen={props.isOpen}
            contentLabel="onRequestClose Example"
            onRequestClose={() => props.closeModal(false)}
            style={{
                overlay: {
                    zIndex: 100,
                },
                content: { marginTop: '20px', width: '80%', left: '10%' },
            }}
        >
            <div style={{ textAlign: 'center' }}>
                <h2>
                    Welcome to the VISITOR web demo
                    <FontAwesomeIcon
                        style={{ float: 'right' }}
                        icon={faTimes}
                        onClick={() => props.closeModal(false)}
                    />
                </h2>
                <hr style={{ marginTop: '4px' }}></hr>
            </div>
            <div>
                <p>
                    The following tool is designed to assist experiments in Reinforcement and Imitation Learning. VA for
                    Deep RL currently supports the <b>Stable Baselines</b> library.
                </p>
                <p>It supports the user during the eveluation process of trained agents: </p>
                <ol>
                    <li> Analyze single policies</li>
                    <li> Compare policies</li>
                    <li> Diagnose policies in detail</li>
                </ol>
                <hr></hr>
                <h3 style={{ textAlign: 'center' }}>Get Started With Demo Scenarios</h3>
                <Stack direction="horizontal" gap={3} className="col-md-8 mx-auto">
                    <Card
                        onClick={() => {
                            props.setDemoScenario('breakout');
                            props.closeModal(false);
                        }}
                    >
                        <Card.Img variant="top" src="images/Screenshot_Breakout.png" />
                        <Card.Body>
                            <Card.Title>Analyze the State Space of a single agent</Card.Title>
                            <Card.Text>
                                <p>Analyze the behavior of an RL agent in Atari Breakout.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card
                        onClick={() => {
                            props.setDemoScenario('seaquest');
                            props.closeModal(false);
                        }}
                    >
                        <Card.Img variant="top" src="images/Screenshot_Seaquest.png" />
                        <Card.Body>
                            <Card.Title>Analyze the training progress of an agent</Card.Title>
                            <Card.Text>
                                <p>Analyze the behavior of an RL agent in Atari Seaquest.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card
                        onClick={() => {
                            props.setDemoScenario('pacman');
                            props.closeModal(false);
                        }}
                    >
                        <Card.Img variant="top" src="images/Screenshot_Pacman.png" />
                        <Card.Body>
                            <Card.Title>Compare two agents</Card.Title>
                            <Card.Text>
                                <p>Analyze two RL agent in Atari MsPacman.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Stack>
                <hr></hr>
                <h3>Tool Workflow</h3>
                <p>
                    The basic concepts and terms of Reinforcement Learning. This tool guides users through all relevant
                    steps:
                </p>
                <Figure>
                    <Figure.Image width={'70%'} src="/files/VISITOR_Workflow.png" />
                    <Figure.Caption>The three phases of the RL/IL workflow.</Figure.Caption>
                </Figure>
                <hr></hr>
                <h3>0. Basic concepts</h3>
                <p>The basic concepts and terms of Reinforcement Learning:</p>
                <Figure>
                    <Figure.Image width={'70%'} src="/files/RL_Explainer_Graphic.jpg" />
                    <Figure.Caption>
                        The basic &apos;control loop&apos; of reinforcement and imitation learning.
                    </Figure.Caption>
                </Figure>
                <ul>
                    <li>
                        <b>Agent/Algorithm:</b> The agent acts autonomously in an environment and makes decisions. Which
                        decision to make is decided by an algorithm or trained model (neural network).
                    </li>
                    <li>
                        <b>Environment:</b>Often a simulation, the environment contains the problem setting, and is
                        explored by the agent.
                    </li>
                    <li>
                        <b>Observation:</b>The agent can (partly) observe the state of the environment. The observations
                        are passed in a specific format, called the observation space, to the agent.
                    </li>
                    <li>
                        <b>Action:</b>The agent decides to perform an action in the environment. The Similarly to the
                        observation, actions are passed to the simulation in a specified format, the action space.
                    </li>
                    <li>
                        <b>Reward:</b>In each step, the agent receives a reward signal. This reward signal codifies the
                        goal the agent has to achieve in the environment.
                    </li>
                    <li>
                        <b>Reinforcement Learning:</b>In reinforcement learning, the agent tries to maximize the total
                        reward it receives. It does this by trial-and-error, i.e. the algorithm learns a model which
                        outputs actions that maximize the expected return.
                    </li>
                    <li>
                        <b>Imitation Learning:</b>In imitation learning, the actions an agent should take in different
                        states of the environment are given by a supplied dataset.
                    </li>
                </ul>
                <hr></hr>
                <h3>Workflow</h3>
                <p>
                    First, you may select an experiment and model checkpoint to analyze. You do this by adding the
                    selected configuration to the scheduled runs via the green button, and then pressing the &#39;Load
                    Scheduled Runs&#39; button.
                </p>
                <Figure>
                    <Figure.Image width={'70%'} src="/files/model_selection.png" />
                    <Figure.Caption>Settings to load a model and create trajectory data for sampling.</Figure.Caption>
                </Figure>
            </div>
        </Modal>
    );
}
