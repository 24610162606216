import React, { PureComponent } from 'react';
import { DropdownButton, ButtonGroup, Dropdown, Button } from 'react-bootstrap';
import evalstyle from '../css/Evaluation.module.css';
import * as d3 from 'd3';

export default class Embedding_rewardgrapg extends PureComponent {
    constructor(props) {
        super(props);
        this.linegraphRef = React.createRef();

        this.state = {
            processed_data: { rews: [], dones: [] },
            processed_sec_data: [],
            rew_aggr_mode: 'acc_reward',
            sec_data_type: 'random',
            xbrush_bounds: [],
        };
    }

    rewAggrModeToLabel = {
        step_reward: 'Step Reward',
        acc_reward: 'Accumulated Reward',
        real_value: 'Real Value',
    };

    componentDidMount() {
        this.drawChart(
            this.prepareData(this.props.data),
            this.getSecData(),
            this.props.lineColor,
            this.props.changeSteps,
            this.props.selectedSteps,
            this.props.hideNewStepRange,
            this.props.highlightStepRange,
            this.setXBounds.bind(this),
            this.props.activeEpisodes,
            this.props.updateActiveEpisodes,
            this.props.episodeRewards,
            this.props.episodeLengths,
            this.props.episodeModelMap,
            this.state.sec_data_type,
            this.props.setHoverStep,
            this.props.infos
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.length > 0 && this.state.xbrush_bounds.length <= 0) {
            //    if (this.state.xbrush_bounds.length <= 0)
            let data = this.state.processed_data;
            let sec_data = this.state.processed_sec_data;
            if (
                this.props.dataTimestamp !== prevProps.dataTimestamp ||
                this.state.rew_aggr_mode !== prevState.rew_aggr_mode ||
                this.state.sec_data_type !== prevState.sec_data_type ||
                this.props.selectionTimestamp !== prevProps.selectionTimestamp
            ) {
                data = this.prepareData(this.props.data);
                sec_data = this.getSecData();
                this.setState({ processed_data: data, processed_sec_data: sec_data });
            }
            this.drawChart(
                data,
                sec_data,
                this.props.lineColor,
                this.props.changeSteps,
                this.props.selectedSteps,
                this.props.hideNewStepRange,
                this.props.highlightStepRange,
                this.setXBounds.bind(this),
                this.props.activeEpisodes,
                this.props.updateActiveEpisodes,
                this.props.episodeRewards,
                this.props.episodeLengths,
                this.props.episodeModelMap,
                this.state.sec_data_type,
                this.props.setHoverStep,
                this.props.infos
            );
        }
    }

    getSecData() {
        if (this.state.sec_data_type === 'random') {
            if (this.props.comparisonRewardCurve.length > 0) return this.props.comparisonRewardCurve;
            else return [0];
        }
        return this.props.infos.map((i) => i[this.state.sec_data_type]);
    }

    prepareData(data) {
        const rew_data = data[0];
        const done_data = data[1];
        let out_data = new Array(rew_data.length);
        if (this.state.rew_aggr_mode === 'step_reward') {
            out_data = rew_data;
        } else if (this.state.rew_aggr_mode === 'acc_reward') {
            let current_sum = 0.0;
            for (let i = 0; i < rew_data.length; i++) {
                current_sum += rew_data[i];
                out_data[i] = current_sum;
                if (done_data[i] === true) current_sum = 0.0;
            }
        } else if (this.state.rew_aggr_mode === 'real_value') {
            let current_sum = 0.0;
            for (let i = rew_data.length - 1; i >= 0; i--) {
                current_sum += rew_data[i];
                out_data[i] = current_sum;
                if (done_data[i] === true) current_sum = 0.0;
            }
        }

        const done_idx = done_data.reduce((a, elem, i) => (elem === true && a.push(i), a), []);

        return { rews: out_data, dones: done_idx };
    }

    changeRewardAggrMode(new_mode) {
        const mode = new_mode;
        this.setState({ rew_aggr_mode: mode });
    }

    changeSecondaryDataType(new_type) {
        const type = new_type;
        this.setState({ sec_data_type: type });
    }

    getSecDataTypes() {
        return this.props.infoTypes.map((type, i) => {
            return (
                <Dropdown.Item key={type} eventKey={type} active={this.state.sec_data_type === type}>
                    {type}
                </Dropdown.Item>
            );
        });
    }

    setXBounds(bounds) {
        this.setState({ xbrush_bounds: bounds });
    }

    hideBrushSelection() {
        this.props.hideNewStepRange(this.state.xbrush_bounds);
        this.setState({ xbrush_bounds: [] });
    }

    highlightBrushSelection() {
        this.props.highlightStepRange(this.state.xbrush_bounds);
        this.setState({ xbrush_bounds: [] });
    }

    resetRewardFilterHighlighting() {
        this.setState({ xbrush_bounds: [] });
        this.props.resetHighlightingFilter();
    }

    drawChart(
        data,
        sec_data,
        lineColor,
        changeSteps,
        selectedSteps,
        hideNewStepRange,
        highlightNewStepRange,
        setXBounds,
        activeEpisodes,
        updateActiveEpisodes,
        episodeRewards,
        episodeLengths,
        episodeModelMap,
        secDataName,
        setHoverStep,
        infos
    ) {
        let { rews, dones } = data;

        const episodes_per_run = dones.length / episodeRewards.length;

        if (this.props.showModels) {
            rews = rews.filter((_, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            dones = dones.filter((_, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            infos = infos.filter((_, i) => this.props.showModels[this.props.infos[i]['model_index']]);
            sec_data = sec_data.filter((_, i) => this.props.showModels[this.props.infos[i]['model_index']]);
        }

        const margin = { top: 10, right: 30, bottom: 20, left: 10 };
        const svgWidth = this.linegraphRef.current.parentElement.clientWidth - margin.left - margin.right;
        const svgHeight = 240;
        const lowerOverlayHeight = 110;
        const reward_graph_height = svgHeight - lowerOverlayHeight;
        const x_domain = rews.length > 0 ? rews.length - 1 : 1024;

        d3.select(this.linegraphRef.current).select('*').remove();
        d3.select('#rewardAggregationFunctionHighlightButton').attr('disabled', true);
        d3.select('#rewardAggregationFunctionHideButton').attr('disabled', true);

        const annotation_array = infos.map((info) => info.tags);
        const events = [];
        for (let i = 0; i < annotation_array.length; i++) {
            if (annotation_array[i] !== undefined && annotation_array[i].length > 0) {
                const lastEvent = annotation_array[i][annotation_array[i].length - 1];
                if (i === 0 || annotation_array[i - 1][annotation_array[i - 1].length - 1] !== lastEvent) {
                    events.push({ event: lastEvent, start: i, end: i });
                } else {
                    events[events.length - 1].end = i;
                }
            }
        }

        const svg = d3
            .select(this.linegraphRef.current)
            .append('svg')
            .attr('width', svgWidth + margin.left + margin.right)
            .attr('height', svgHeight + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const x = d3.scaleLinear().domain([0, x_domain]).range([0, svgWidth]);
        const xAxis = svg
            .append('g')
            .attr('transform', 'translate(0,' + reward_graph_height + ')')
            .call(d3.axisBottom(x));

        const min_val = Math.min(d3.min(rews), d3.min(sec_data));
        const max_val = Math.max(d3.max(rews), d3.max(sec_data));
        const y_domain =
            rews.length > 0 ? [min_val - 0.15 * Math.abs(min_val), max_val + 0.15 * Math.abs(max_val)] : [-1, 1];
        const y = d3
            .scaleLinear()
            .range([svgHeight - lowerOverlayHeight, 0])
            .domain(y_domain);
        // Gradient Color Scale for Line, min-max normalization
        const gradientColor = (d) => {
            return d3.interpolateOrRd((d - y_domain[0]) / (y_domain[1] - y_domain[0]));
        };

        svg.append('g')
            .call(d3.axisRight(y).ticks(5).tickSize(svgWidth))
            .call((g) => g.select('.domain').remove())
            .call((g) => g.selectAll('.tick:not(:first-of-type) line').attr('stroke-opacity', 0.3))
            .call((g) => g.selectAll('.tick text').attr('x', 4).attr('dy', -4));

        const brush = d3
            .brushX()
            .extent([
                [0, 0],
                [svgWidth, reward_graph_height],
            ])
            .on('end', brushSelection);

        svg.append('line')
            .attr('x1', svgWidth - margin.right - 40)
            .attr('y1', 16)
            .attr('x2', svgWidth - margin.right - 35)
            .attr('y2', 16)
            .attr('stroke-width', 3)
            .attr('stroke', '#75aaff');

        svg.append('line')
            .attr('x1', svgWidth - margin.right - 110)
            .attr('y1', 16)
            .attr('x2', svgWidth - margin.right - 105)
            .attr('y2', 16)
            .attr('stroke-width', 3)
            .attr('stroke', lineColor);

        svg.append('text')
            .attr('x', svgWidth - margin.right - 30)
            .attr('y', 20)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .text(secDataName);

        svg.append('text')
            .attr('x', svgWidth - margin.right - 100)
            .attr('y', 20)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .text('Reward');

        const done_lines = svg
            .append('g')
            .selectAll('line')
            .data(dones)
            .enter()
            .append('line')
            .attr('x1', (d) => x(d))
            .attr('x2', (d) => x(d))
            .attr('y1', 0)
            .attr('y2', svgHeight)
            .attr('stroke', '#a0a0a0');

        const annotation_offset = 25;

        svg.append('text')
            .attr('x', x(2))
            .attr('y', reward_graph_height + annotation_offset)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .text('Annotations');

        const bubbleWidth = svgWidth / annotation_array.length;
        const bubbleHeight = 20;
        const yOffset = reward_graph_height + annotation_offset + 10;

        const pill_tooltip = d3
            .select('body')
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('border-radius', '5px')
            .style('padding', '5px')
            .style('pointer-events', 'none');

        svg.selectAll('rect')
            .data(events)
            .enter()
            .append('rect')
            .attr('x', (d) => x(d.start) - bubbleWidth / 2)
            .attr('y', yOffset)
            .attr('width', (d) => bubbleWidth * (d.end - d.start + 1))
            .attr('height', bubbleHeight)
            .attr('rx', bubbleHeight / 2)
            .attr('ry', bubbleHeight / 2)
            .attr('fill', (d) =>
                d.event === 'End of an Episode' ? 'black' : d3.schemeCategory10[events.indexOf(d) % 10]
            )
            .style('opacity', 0.5)
            .on('mouseover', (event, d) => {
                // Show tooltip and change opacity on mouseover
                pill_tooltip.transition().duration(200).style('opacity', 0.9);
                pill_tooltip
                    .html(d.event)
                    .style('left', event.pageX + 'px')
                    .style('top', event.pageY - 20 + 'px');

                d3.select(event.currentTarget).style('opacity', 0.9);
            })
            .on('mouseout', (event, d) => {
                // Hide tooltip and reset opacity on mouseout
                pill_tooltip.transition().duration(500).style('opacity', 0);

                d3.select(event.currentTarget).style('opacity', 0.5);
            });
        const episode_offset = 65;

        svg.append('text')
            .attr('x', x(2))
            .attr('y', reward_graph_height + episode_offset)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .text('Aggregated Values by Episode');

        // Aggregate the reward data by episode (split by done indices), rews gives a reward for each step
        const done_boundaries = [0].concat(dones).slice(0, -1);
        const aggregated_episode_data = done_boundaries.map((d, i) => {
            const episode_data = rews.slice(d, dones[i]);
            return {
                episode: i,
                sum: d3.sum(episode_data),
                mean: d3.mean(episode_data),
                std: d3.deviation(episode_data),
            };
        });
        const color_scale = d3
            .scaleSequential()
            .interpolator(d3.interpolateOrRd)
            .domain([d3.min(aggregated_episode_data, (d) => d.sum), d3.max(aggregated_episode_data, (d) => d.sum)]);

        svg.append('g')
            .selectAll('rect')
            .data(done_boundaries)
            .enter()
            .append('rect')
            .attr('x', (d) => x(d) + 2)
            .attr('y', reward_graph_height + episode_offset + 5)
            .attr('height', 20)
            .attr('width', (d, i) => x(dones[i]) - x(d) - 3)
            .attr('fill', (_, i) => color_scale(aggregated_episode_data[i].sum))
            .attr('opacity', (_, i) => (activeEpisodes.includes(i) ? 0.5 : 0.95))
            .on('click', (d, i) => updateActiveEpisodes(i));

        const padded_dones = [0].concat(dones);
        svg.append('g')
            .selectAll('text')
            .data(episodeRewards)
            .enter()
            .append('text')
            //.attr("x", (d, i) => (x(padded_dones[i*episodes_per_run]) + (x(padded_dones[i*episodes_per_run+1]) - x(padded_dones[i*episodes_per_run])) / 2) + 10)
            .attr('x', (d, i) => x(padded_dones[i * episodes_per_run + episodes_per_run]) - 5)
            .attr('y', reward_graph_height + episode_offset + 20)
            .style('font-size', '13px')
            .attr('text-anchor', 'end')
            .text((d) => ' <-' + Number.parseFloat(d).toFixed(2));

        const model_offset = 100;

        svg.append('text')
            .attr('x', x(2))
            .attr('y', reward_graph_height + model_offset)
            .attr('text-anchor', 'start')
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .text('Models');

        const episodes_per_model = dones.length / episodeModelMap.size;
        const checkpoint_rects = svg
            .append('g')
            .selectAll('rect')
            .data(Array.from(episodeModelMap.keys()))
            .enter()
            .append('rect')
            .attr('x', (d, i) => x(padded_dones[i * episodes_per_model]) + 2)
            .attr('y', reward_graph_height + model_offset + 5)
            .attr('height', 20)
            .attr(
                'width',
                (d, i) =>
                    x(padded_dones[i * episodes_per_model + episodes_per_model]) -
                    x(padded_dones[i * episodes_per_model]) -
                    3
            )
            .attr('fill', '#AAAAAA');

        svg.append('g')
            .selectAll('text')
            .data(Array.from(episodeModelMap.keys()))
            .enter()
            .append('text')
            //.attr("x", (d, i) => (x(padded_dones[i*episodes_per_run]) + (x(padded_dones[i*episodes_per_run+1]) - x(padded_dones[i*episodes_per_run])) / 2) + 10)
            .attr('x', (d, i) => x(padded_dones[i * episodes_per_model + episodes_per_model]) - 5)
            .attr('y', reward_graph_height + model_offset + 20)
            .style('font-size', '13px')
            .attr('text-anchor', 'end')
            .text((d) => d);

        // Draw red lines to differentiate between checkpoints with a very dark grey thick line
        svg.append('g')
            .selectAll('line')
            .data(episodeRewards)
            .enter()
            .append('line')
            .attr('x1', (d, i) => x(padded_dones[i * episodes_per_run + episodes_per_run]))
            .attr('x2', (d, i) => x(padded_dones[i * episodes_per_run + episodes_per_run]))
            .attr('y1', 0)
            .attr('y2', svgHeight)
            .attr('stroke', '#444444')
            .attr('stroke-width', 3.5);

        svg.append('path')
            .datum(rews)
            .attr('fill', 'none')
            .attr('stroke', lineColor)
            .attr('stroke-width', 1.5)
            .attr(
                'd',
                d3
                    .line()
                    .x(function (d, i) {
                        return x(i);
                    })
                    .y(function (d) {
                        return y(d);
                    })
            );

        svg.append('g').attr('class', 'brush').call(brush);

        const step_line_g = svg.append('g');

        const step_line = step_line_g
            .append('rect')
            .attr('x', x(selectedSteps.new.value) - 2)
            .attr('y', 0)
            .attr('height', svgHeight)
            .attr('width', 4)
            .attr('fill', '#ff3737')
            .attr('opacity', 0.6);

        const step_line_point = step_line_g
            .append('circle')
            .attr('cx', x(selectedSteps.new.value))
            .attr('cy', y(rews[selectedSteps.new.value]))
            .attr('r', 8)
            .attr('opacity', 0.6)
            .attr('fill', '#ff3737')
            .call(d3.drag().on('drag', dragPoint).on('end', dragEnd));

        const comp_line = svg
            .append('path')
            .datum(sec_data)
            .attr('fill', 'none')
            .attr('stroke', '#75aaff')
            .attr('stroke-width', 1.5)
            .attr(
                'd',
                d3
                    .line()
                    .x(function (d, i) {
                        return x(i);
                    })
                    .y(function (d) {
                        return y(d);
                    })
            );

        const hiddenStepRanges = [];
        const highlightedStepRanges = [];

        let hiddenActiveStart = -1;
        let highlightedActiveStart = -1;

        // each step i has an info value infos[i], can be either selected or highlighted
        // Find Consequective ranges of selected and highlighted steps

        for (let i = 0; i < infos.length; i++) {
            if (infos[i].selected === false) {
                if (hiddenActiveStart === -1) {
                    hiddenActiveStart = i;
                }
            } else {
                if (hiddenActiveStart !== -1) {
                    hiddenStepRanges.push([hiddenActiveStart, i - 1]);
                    hiddenActiveStart = -1;
                }
            }
            if (infos[i].highlighted === true) {
                if (highlightedActiveStart === -1) {
                    highlightedActiveStart = i;
                }
            }
            if (infos[i].highlighted === false) {
                if (highlightedActiveStart !== -1) {
                    highlightedStepRanges.push([highlightedActiveStart, i - 1]);
                    highlightedActiveStart = -1;
                }
            }
        }

        svg.append('g')
            .selectAll('rect')
            .data(hiddenStepRanges)
            .enter()
            .append('rect')
            .attr('x', (d) => x(d[0]))
            .attr('y', 0)
            .attr('width', (d) => x(d[1] - d[0]))
            .attr('height', svgHeight - lowerOverlayHeight)
            .attr('fill', 'red')
            .attr('opacity', 0.2);

        svg.append('g')
            .selectAll('rect')
            .data(highlightedStepRanges)
            .enter()
            .append('rect')
            .attr('x', (d) => x(d[0]))
            .attr('y', 0)
            .attr('width', (d) => x(d[1] - d[0]))
            .attr('height', svgHeight - lowerOverlayHeight)
            .attr('fill', 'yellow')
            .attr('opacity', 0.2);

        if (
            selectedSteps.new &&
            (selectedSteps.new.bottom !== selectedSteps.previous.bottom ||
                selectedSteps.new.top !== selectedSteps.previous.top)
        ) {
            x.domain([selectedSteps.new.bottom, selectedSteps.new.top]);
            svg.select('.brush').call(brush.move, null);

            // Update axis and circle position
            xAxis.transition().duration(750).call(d3.axisBottom(x));

            data_path
                .transition()
                .duration(750)
                .attr(
                    'd',
                    d3
                        .line()
                        .x(function (d, i) {
                            return x(i);
                        })
                        .y(function (d) {
                            return y(d);
                        })
                );

            done_lines
                .transition()
                .duration(750)
                .attr('x1', (d) => x(d))
                .attr('x2', (d) => x(d));

            comp_line
                .transition()
                .duration(750)
                .attr(
                    'd',
                    d3
                        .line()
                        .x(function (d, i) {
                            return x(i);
                        })
                        .y(function (d) {
                            return y(d);
                        })
                );

            step_line.transition().duration(750).attr('x', x(selectedSteps.new.value));
            step_line_point.transition().duration(750).attr('cx', x(selectedSteps.new.value));
        }

        function dragPoint(event, d) {
            step_line.attr('x', event.x);
            step_line_point.attr('cx', event.x).attr('cy', event.y);
        }

        function dragEnd(event, d) {
            const x0 = Math.round(x.invert(event.x));
            changeSteps({ bottom: selectedSteps.new.bottom, top: selectedSteps.new.top, value: x0 });
            setHoverStep(infos[x0]);
        }

        function brushSelection(event) {
            const extent = event.selection;

            let idleTimeout;
            function idled() {
                idleTimeout = null;
            }

            // If no selection, back to initial coordinate. Otherwise, update X axis domain
            if (!extent) {
                if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
                setXBounds([]);
            } else {
                setXBounds([Math.round(x.invert(extent[0])), Math.round(x.invert(extent[1]))]);
            }
        }
    }

    render() {
        return (
            <div>
                <DropdownButton
                    as={ButtonGroup}
                    key="reward_aggregation_dropdown"
                    id="reward_aggregation_dropdown_"
                    onSelect={this.changeRewardAggrMode.bind(this)}
                    className={evalstyle.rew_agg_dropdown_button}
                    variant="link"
                    title={'Reward Aggregation Mode: ' + this.rewAggrModeToLabel[this.state.rew_aggr_mode]}
                >
                    <Dropdown.Item eventKey="step_reward" active={this.state.rew_aggr_mode === 'step_reward'}>
                        Step Reward
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="acc_reward" active={this.state.rew_aggr_mode === 'acc_reward'}>
                        Accumulative Reward
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="real_value" active={this.state.rew_aggr_mode === 'real_value'}>
                        Future Acc. Reward (Value)
                    </Dropdown.Item>
                </DropdownButton>
                <Button
                    variant="secondary"
                    id="rewardAggregationFunctionHighlightButton"
                    disabled={this.state.xbrush_bounds.length === 0}
                    onClick={this.highlightBrushSelection.bind(this)}
                >
                    Highlight
                </Button>
                &nbsp;
                <Button
                    variant="secondary"
                    id="rewardAggregationFunctionHideButton"
                    disabled={this.state.xbrush_bounds.length === 0}
                    onClick={this.hideBrushSelection.bind(this)}
                >
                    Hide
                </Button>
                &nbsp;
                <Button
                    variant="secondary"
                    id="rewardAggregationFunctionResetButton"
                    onClick={this.resetRewardFilterHighlighting.bind(this)}
                >
                    Reset
                </Button>
                &nbsp;
                <DropdownButton
                    as={ButtonGroup}
                    key="sec_dropdown"
                    id="reward_sec_dropdown"
                    onSelect={this.changeSecondaryDataType.bind(this)}
                    className={evalstyle.rew_agg_dropdown_button}
                    style={{ float: 'right' }}
                    variant="link"
                    title={
                        'Secondary Curve: ' +
                        (this.state.sec_data_type === 'random'
                            ? 'Reward with Random Actions'
                            : this.state.sec_data_type)
                    }
                >
                    <Dropdown.Item eventKey="random" active={this.state.sec_data_type === 'random'}>
                        Reward with Random Actions
                    </Dropdown.Item>
                    {this.getSecDataTypes()}
                </DropdownButton>
                <div ref={this.linegraphRef}></div>
            </div>
        );
    }
}
